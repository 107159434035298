import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Box, Button, Grid, Paper, TextField, Typography } from '@material-ui/core';
import ReactPlayer from 'react-player'
import { Link, useHistory, useLocation } from 'react-router-dom';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import { API, selectInput } from 'aws-amplify';
import isEmpty from './isEmpty';

const useStyles = makeStyles((theme) => ({
  main: {
    backgroundColor: '#ff8a00',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
    },
    [theme.breakpoints.up('md')]: {
      textAlign: 'center',
    },
    [theme.breakpoints.up('lg')]: {
      textAlign: 'center',
    },
    padding: '20px 10px'
  },
  main2: {
    backgroundColor: '#fff',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
    },
    [theme.breakpoints.up('md')]: {
      textAlign: 'center',
    },
    [theme.breakpoints.up('lg')]: {
      textAlign: 'center',
    },
  },
  paperContainer: {
    width: '100%',
    color: '#ff8a00',
  },
  section: {
    width: "100%",
    backgroundColor: '#fff6d2',
    height: "100%",
    borderRadius: "30px",
    paddingTop: '20px',
    paddingBottom: '20px',
    marginTop: '20px',
    marginBottom: '20px'
  },
  section2: {
    width: "100%",
    backgroundColor: '#fff',
    height: "100%",
    borderRadius: "30px",
    paddingTop: '20px',
    paddingBottom: '20px',
    marginTop: '20px',
    marginBottom: '20px'
  },
  coronaImage: {
    width: "100%",
    display: "center",
  },
  widthFull: {
    width: "100%",
  },
  sectionTitle: {
    color: '#ff8a00',
    fontSize: 'min(30px,4vw)',
    display: "center",
    textAlign: "center"
  },
  textSubtitle: {
    fontSize: 16,
    color: '#4C1D00',
    display: "center",
    textAlign: "left",
    margin: "5px 0",
  },
  textSubtitle2: {
    fontSize: 18,
    color: '#ff8a00',
    display: "center",
    textAlign: "center"
  },
  text: {
    color: '#4C1D00',
    fontSize: 14,
    textAlign: 'center'
  },
  aboutText: {
    color: '#4C1D00',
    fontSize: 'min(16px,3.5vw)',
    // display: "center",
    textAlign: 'left',
    margin: '10px',
  },
  pos: {
    color: '#4C1D00',
    fontSize: 'min(16px,3.5vw)',
    fontWeight: 'bold',
    // display: "center",
    textAlign: 'left',
  },
  pos2: {
    color: '#4C1D00',
    fontSize: 'min(16px,3.5vw)',
    // display: "center",
    textAlign: 'left',
  },
  pos3: {
    color: '#4C1D00',
    fontSize: 'min(16px,3.5vw)',
    // display: "center",
    textAlign: 'left',
    marginTop: 30,
    marginBottom: 30
  },
  pos4: {
    color: '#ff8a00',
    fontSize: 'min(16px,3.5vw)',
    // display: "center",
    textAlign: 'left',
    // textDecoration: 'underline'
  },
  pos4_url: {
    color: '#ff8a00',
    fontSize: 'min(16px,3.5vw)',
    // display: "center",
    textAlign: 'left',
    textDecoration: 'underline'
  },
  pos5: {
    color: '#ff8a00',
    fontSize: 'min(16px,3.5vw)',
    marginTop: 10,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  pos6: {
    color: '#4C1D00',
    fontSize: 'min(16px,3.5vw)',
    // display: "center",
    textAlign: 'left',
  },
  pos7: {
    color: '#4C1D00',
    fontSize: 'min(16px,3.5vw)',
    fontWeight: 'bold',
    // display: "center",
    textAlign: 'left',
    marginBottom: '30px'
  },
  markerText: {
    color: '#4C1D00',
    fontSize: 'min(16px,3.5vw)',
    fontWeight: 'bold',
    display: 'inline-block',
    // display: "center",
    textAlign: 'left',
    background: 'linear-gradient(transparent 60%, #FF8A0099 60%)'
  },
  kyosanText: {
    color: '#4C1D00',
    fontSize: 14,
    marginTop: 10,
    fontWeight: 'bold',
    textAlign: 'left',
  },
  kyosanText2: {
    color: '#4C1D00',
    fontSize: 'min(16px,3.5vw)',
    marginTop: 10,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 20
  },
  white: {
    backgroundColor: '#fff',
    overflowY: 'scroll',
    height: 200
  },
  linkColor: {
    color: '#ff8a00',
  },
  support: {
    color: '#4C1D00',
    fontSize: 'min(16px,3.5vw)',
    fontWeight: 'bold',
    display: 'block',
    paddingTop: '10px',
    paddingLeft: '10px',
    paddingRight: '10px',
    textAlign: 'center'
  },
  imgsizeKenketsu: {
    // height: '120px',
    width: '100%',
    backgroundColor: '#fff',
    paddingBottom: '20px',
    marginTop: 4,
  },
  imgsizeKenketsuMap: {
    // height: '220px',
    width: '100%',
    backgroundColor: '#fff',
    paddingBottom: '20px',
    display: 'block',
    textAlign: 'center'
  },
  bodyButton: {
    minHeight: 80,
    backgroundColor: '#fff',
    color: '#4C1D00',
    paddingBottom: '20px',
    textAlign: 'center',
  },
  dummy: {
    width: 'clamp(340px,35vw,640px)',
    height: 'clamp(200px,20vw,360px)'
  },
  padding5: {
    padding: 10
  },
  img: {
    width: "100%",
  },
  img2: {
    width: "20px",
    verticalAlign: "sub"
    // height: "30px"
  },
  center: {
    textAlign: 'center'
  }


}));


const kyosans = [
  '荒川区絆プロジェクト実行委員会',
  '日本赤十字社',
  'あらかわもんじゃ学研究会',
  'カラーテクノ株式会社',
  '株式会社garden Tokyo',
  '株式会社塩川システム設計事務所',
  '竹澤税理士事務所',
  '株式会社O-line',
  '株式会社ゴールデンマスタード',
  'Private Training Gym Middle',
  '株式会社テトラシステムデザイン',
  '株式会社ビリケンコンサルタント',
  '株式会社Nen-Dea',
  '株式会社コネクトWP',
  '日本防災推進機構',
  '廣澤法律事務所P',

]

export default function Top(props) {
  const classes = useStyles();
  const location = useLocation();

  const [youtubeList, setYoutubeList] = useState([])
  const [forms, setForms] = useState({ title: '', url: '', description: '', deleted: false })

  /* ***********************************************
   *  Function 初期化
   * ************************************************/
  useEffect(() => {
    (async () => {
      const variables = { limit: 1000 }
      const res = await API.graphql({ query: queries.listYoutubes, variables: variables })
      const _youtubeList = res.data.listYoutubes.items
        .filter(y => y.deleted === false)
        .sort((a, b) => a.createdAt < b.createdAt ? 1 : a.createdAt > b.createdAt ? -1 : 0);
      setYoutubeList(_youtubeList)
    })().catch(error => console.log('fetch error:', error))
  }, [])

  useEffect(() => {
    console.log(location.hash)
    if (isEmpty(location.hash)) return
    const doc = document.querySelector(location.hash)
    if (doc != null) doc.scrollIntoView(true)
  }, [props.state.topPageId, location.hash])

  return (
    <div className={classes.main} >
      <Grid container spacing={0} justify="center" alignItems="center">
        <Grid item xs={12} sm={10} md={7} lg={7} >
          <div className={classes.section}>
            <h1 className={classes.sectionTitle} >- お知らせ -</h1>
            <Grid container spacing={0} justify="center" alignItems="center">
              <Grid item xs={11} sm={11} md={10} lg={7}>
                <div>
                  <CardContent className={classes.white}>
                    <Typography className={classes.pos2}>2020.12.28</Typography>
                    <Typography className={classes.pos4_url} onClick={() => props.dispatch({ type: "REPORT" })}>イベントレポートページを更新しました！</Typography>
                    <Typography className={classes.pos2} align='left'>「まちやを救エール」のイベントページを更新しました。みなさまご協力ありがとうございました。</Typography>

                    <Typography className={classes.pos2}>-------------------------------------------------------------------</Typography>

                    <Typography className={classes.pos2}>2020.11.8 9:00</Typography>
                    <Typography className={classes.pos4}>デリバリーにつきまして</Typography>
                    <Typography className={classes.pos2} align='left' color='error'>本日11/8(日)はボランティアスタッフ人数の関係により、デリバリー提供時間は11:00～14:00までとさせて頂きます。</Typography>

                    <Typography className={classes.pos2}>-------------------------------------------------------------------</Typography>

                    <Typography className={classes.pos2}>2020.11.7 10:00</Typography>
                    <Typography className={classes.pos4}>デリバリーにつきまして</Typography>
                    <Typography className={classes.pos2} align='left' color='error'>本日11/7(土)はデリバリー中止とお知らせしましたが、降水確率と皆様の利便性を考慮し、15:00までデリバリーを受け付ける運びとなりました。</Typography>
                    <Typography className={classes.pos2}>デリバリーに関して変更などございました場合は、随時お知らせいたします。</Typography>

                    <Typography className={classes.pos2}>-------------------------------------------------------------------</Typography>

                    <Typography className={classes.pos2}>2020.11.7 9:00</Typography>
                    <Typography className={classes.pos4}>デリバリー中止のお知らせ</Typography>
                    <Typography className={classes.pos2} align='left' color='error'>本日11/7(土)は午後から雨天となる可能性が高いためデリバリーは中止いたします。</Typography>
                    <Typography className={classes.pos2}>イートイン、テイクアウトはご利用いただけます。</Typography>

                    <Typography className={classes.pos2}>-------------------------------------------------------------------</Typography>

                    <Typography className={classes.pos2}>2020.10.8</Typography>
                    <Typography className={classes.pos4}>「地域情報コミュニティ番組あらまるNEXT」から取材を受けました。</Typography>
                    <Typography className={classes.pos2}>【放送日】10月26日～11月1日</Typography>
                    <Typography className={classes.pos2}>10ch　12:30 / 14:30 / 22:30</Typography>
                    <Typography className={classes.pos2}>11ch　06:00 / 08:30 / 13:30</Typography>
                    <Typography className={classes.pos2}> ※荒川区、文京区、千代田区限定配信</Typography>
                  </CardContent>
                </div>
              </Grid>
            </Grid>
          </div>

          <div className={classes.section}>
            <Grid container spacing={0} justify="center" alignItems="center">
              <Grid item xs={12}>
                <h1 className={classes.sectionTitle} >- イベントPR動画 -</h1>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" justifyContent="center" m={1} p={1} >
                  <Box p={1}>
                    <ReactPlayer url='https://youtu.be/ufsPtUOW3q0' playing muted
                      width='clamp(340px,35vw,640px)'
                      height='clamp(200px,20vw,360px)'
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>

          </div>

          <div className={classes.section}>
            <Grid container spacing={0} direction="row" justify="center" alignItems="center">
              <Grid item xs={12}>
                <h1 className={classes.sectionTitle} >- まちやLive配信 -</h1>
              </Grid>

              <Grid item xs={12}>
                <Typography color="textSecondary" variant="body1" align='center'>
                  {youtubeList.length === 0 ? '当日の町屋の風景をLive配信します！' : youtubeList[0].title}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" justifyContent="center" m={1} p={1} >
                  <Box p={1}>
                    {youtubeList.length === 0 &&
                      <img className={classes.dummy} alt=''
                        src="https://images.suku-yell.com/preparation.jpg"  ></img>
                    }
                    {youtubeList.length > 0 &&
                      <ReactPlayer url={youtubeList[0].url} playing muted
                        width='clamp(340px,35vw,640px)'
                        height='clamp(200px,20vw,360px)'
                      />
                    }
                  </Box>
                </Box>
              </Grid>
            </Grid>


            {(props.state.dynamoUser.authority === 'Staff' || props.state.dynamoUser.authority === 'Admin') && props.state.isSignedIn &&
              <div>
                <TextField size="small" name="title" value={forms.title} label="Title" onChange={(e) => setForms({ ...forms, title: e.target.value })} variant="outlined" />
                <TextField size="small" name="url" value={forms.url} label="Youtube URL" onChange={(e) => setForms({ ...forms, url: e.target.value })} variant="outlined" />
                <Button onClick={() => API.graphql({ query: mutations.createYoutube, variables: { input: forms } })} color="primary" variant="contained">登録</Button>
                <Typography color="textSecondary" variant="body1">
                  {'動画を追加します。「登録」押下後にブラウザ更新して反映されていることを確認してください。'}
                </Typography>
              </div>
            }

          </div>

          <div className={classes.section}>
            <Grid
              container spacing={0}
              direction="row"
              justify="center"
              alignItems="center">
              <h1 className={classes.sectionTitle} >- イベントについて -</h1>
            </Grid>
            <Grid container spacing={2} justify="center" alignItems="center">
              <Grid item xs={12} sm={8} md={8} lg={7} >
                <div>
                  <CardContent>
                    <Typography className={classes.pos7} color="textSecondary" variant="body1">
                      ー「自分たちがどうにかしなければいけない」荒川区町屋の飲食店からそんな声があがりました。
                    </Typography>
                    <Grid container spacing={0} justify="flex-start">
                      <Grid item xs={12} sm={12} md={12} lg={12} >
                        <Typography className={classes.markerText} color="textSecondary" variant="body1">
                          ✓飲食店に行きたいけど行けなくなってしまった
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography className={classes.markerText} color="textSecondary" variant="body1">
                          ✓飲食店に行く機会が減った
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography className={classes.markerText} color="textSecondary" variant="body1">
                          ✓困っている飲食店を盛り上げたい
                        </Typography>
                      </Grid>
                    </Grid>
                    <Typography className={classes.pos3} color="textSecondary" variant="body1">
                      こんな思いを持っている方々に「まちやを救エール」を通して、食べて飲んで楽しみながら飲食店を応援してもらいたい。
                      そして荒川区町屋から飲食業界全体を盛り上げたい。
                    </Typography>
                    <Grid container spacing={0} justify="flex-start">
                      <Grid item xs={8} sm={8} md={9} lg={9} >
                        <Typography className={classes.pos} color="textSecondary" variant="body1">
                          「オンライン×リアル」で、コロナ禍 だからこそ生まれた新しいカタチのイベントです。
                        </Typography>
                      </Grid>
                      <Grid item xs={4} sm={4} md={3} lg={3} >
                        <img className={classes.img} alt=''
                          src="https://images.suku-yell.com/icon_yell.png" >
                        </img>
                      </Grid>
                    </Grid>
                  </CardContent>
                </div>
              </Grid>
            </Grid>
          </div>

          <div className={classes.section}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center">
              <h1 className={classes.sectionTitle}>- イベントの楽しみ方 -</h1>
            </Grid>
            <Grid container spacing={0} justify="flex-start">
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <div>
                  <CardContent className={classes.padding5}>
                    <Grid container spacing={0} justify="flex-start">
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <h2 className={classes.textSubtitle}><img className={classes.img2} src="https://images.suku-yell.com/icon_yell.png" alt="" /> イベント限定メニューをご用意</h2>
                      </Grid>
                      <Grid item xs={5} sm={12} md={12} lg={12} >
                        <img className={classes.widthFull} alt=''
                          src="https://images.suku-yell.com/about_img1.png" ></img>
                      </Grid>
                      <Grid item xs={7} sm={12} md={12} lg={12} >
                        <Typography className={classes.aboutText} color="textSecondary" component='p' >
                          複数の飲食店の料理を一度にお召し上がりいただけるよう、各店舗ではイベント期間限定メニューを用意しています。この機会にたくさんの飲食店のお料理をお楽しみください。
                    </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <div>
                  <CardContent className={classes.padding5}>
                    <Grid container spacing={0} justify="flex-start">
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <h2 className={classes.textSubtitle}><img className={classes.img2} src="https://images.suku-yell.com/icon_yell.png" alt="" /> 安心のコロナ対策</h2>
                      </Grid>
                      <Grid item xs={5} sm={12} md={12} lg={12} >
                        <img className={classes.widthFull} alt=''
                          src="https://images.suku-yell.com/about_img2.png" ></img>
                      </Grid>
                      <Grid item xs={7} sm={12} md={12} lg={12} >
                        <Typography className={classes.aboutText} color="textSecondary" variant="body1">
                          イベント参加店舗では、三密対策を徹底して行います。 各飲食店ページでは、いつでも混雑状況を確認できるため、安心してご参加いただけます。
                    </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <div><CardContent className={classes.padding5}>
                  <Grid container spacing={0} justify="flex-start">
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <h2 className={classes.textSubtitle}><img className={classes.img2} src="https://images.suku-yell.com/icon_yell.png" alt="" /> オンラインチケット</h2>
                    </Grid>
                    <Grid item xs={5} sm={12} md={12} lg={12} >
                      <img className={classes.widthFull} alt=''
                        src="https://images.suku-yell.com/about_img4.png" ></img>
                    </Grid>
                    <Grid item xs={7} sm={12} md={12} lg={12} >
                      <Typography className={classes.aboutText} color="textSecondary" component='p' >
                        接触による新型コロナウイルス感染を防ぐため、オンラインチケットをご用意します。
                    </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <div><CardContent className={classes.padding5}>
                  <Grid container spacing={0} justify="flex-start">
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <h2 className={classes.textSubtitle}><img className={classes.img2} src="https://images.suku-yell.com/icon_yell.png" alt="" /> チケット一枚でご自宅にお届け</h2>
                    </Grid>
                    <Grid item xs={5} sm={12} md={12} lg={12} >
                      <img className={classes.widthFull} alt=''
                        src="https://images.suku-yell.com/about_img3.png" ></img>
                    </Grid>
                    <Grid item xs={7} sm={12} md={12} lg={12} >
                      <Typography className={classes.aboutText} color="textSecondary" component='p' >
                        期間・範囲限定でデリバリーします。複数店舗の組み合わせもOKです。 <br />
                  ※雨天の場合イベントは決行／デリバリーは中止。
                    </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <div><CardContent className={classes.padding5}>
                  <Grid container spacing={0} justify="flex-start">
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <h2 className={classes.textSubtitle}><img className={classes.img2} src="https://images.suku-yell.com/icon_yell.png" alt="" /> 協賛ブース</h2>
                    </Grid>
                    <Grid item xs={5} sm={12} md={12} lg={12} >
                      <img className={classes.widthFull} alt=''
                        src="https://images.suku-yell.com/about_img5.png" ></img>
                    </Grid>
                    <Grid item xs={7} sm={12} md={12} lg={12} >
                      <Typography className={classes.aboutText} color="textSecondary" component='p' >
                        協賛企業のブースなど、飲食店に足を運んで頂いた際に、お楽しみいただけるブースもご用意しています。
                    </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <div><CardContent className={classes.padding5}>
                  <Grid container spacing={0} justify="flex-start">
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <h2 className={classes.textSubtitle}><img className={classes.img2} src="https://images.suku-yell.com/icon_yell.png" alt="" /> コンシェルジュ</h2>
                    </Grid>
                    <Grid item xs={5} sm={12} md={12} lg={12} >
                      <img className={classes.widthFull} alt=''
                        src="https://images.suku-yell.com/about_img6.png" ></img>
                    </Grid>
                    <Grid item xs={7} sm={12} md={12} lg={12} >
                      <Typography className={classes.aboutText} color="textSecondary" component='p' >
                        イベントでは、スタッフがコンシェルジュとして周り店舗の案内やイベントの説明を行います。ご不明点などありましたら「STAFF」にお声がけください。
                    </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
                </div>
              </Grid>
            </Grid>
          </div>

          <div className={classes.section} id="corona">
            <Grid
              container spacing={0}
              direction="row"
              justify="center"
              alignItems="center">
              <h1 id="covid" className={classes.sectionTitle} >- コロナ対策 -</h1>
            </Grid>
            <Grid container
              direction="row"
              justify="center"
              alignItems="flexStart">
              <Grid item xs={4} sm={3} md={3} lg={3} >
                <div>
                  <CardContent className={classes.padding5}>
                    <Grid container
                      direction="row"
                      justify="center"
                      alignItems="center">
                      <img className={classes.coronaImage} alt=''
                        src="https://images.suku-yell.com/covid1.png" />
                    </Grid>
                    <h2 className={classes.textSubtitle2}>密集対策</h2>
                    <Typography className={classes.pos6} color="textSecondary" variant="body1">
                      サイト上で混雑状況を配信。デリバリーサービスにより店舗への密集を防ぎます。
                      </Typography>
                  </CardContent>
                </div>
              </Grid>
              <Grid item xs={4} sm={3} md={3} lg={3} >
                <div>
                  <CardContent className={classes.padding5}>
                    <Grid
                      container spacing={0}
                      direction="row"
                      justify="center"
                      alignItems="center">
                      <img className={classes.coronaImage} alt=''
                        src="https://images.suku-yell.com/covid2.png" ></img>
                    </Grid>
                    <h2 className={classes.textSubtitle2}>密閉対策</h2>
                    <Typography className={classes.pos6} color="textSecondary" variant="body1">
                      定期的に店舗の換気を行い、サイト上で各店舗の換気状況を配信します。
                      </Typography>
                  </CardContent>
                </div>
              </Grid>
              <Grid item xs={4} sm={3} md={3} lg={3} >
                <div>
                  <CardContent className={classes.padding5}>
                    <Grid
                      container spacing={0}
                      direction="row"
                      justify="center"
                      alignItems="center">
                      <img className={classes.coronaImage} alt=''
                        src="https://images.suku-yell.com/covid3.png" ></img>
                    </Grid>
                    <h2 className={classes.textSubtitle2}>密接対策</h2>
                    <Typography className={classes.pos6} color="textSecondary" variant="body1">
                      消毒液の配置、マスク着用の注意喚起、支払いのオンライン化により接触を防ぎます。
                      </Typography>
                  </CardContent>
                </div>
              </Grid>
            </Grid>
          </div>

          <Card className={classes.section2} id="kyosan">
            <span id='sponsor'></span>
            <Grid
              container spacing={0}
              direction="row"
              justify="center"
              alignItems="center">

              <h1 className={classes.sectionTitle} >- 協賛・協力 -</h1>
            </Grid>
            <Grid container spacing={0} justify="center" alignItems="center">
              <Grid item xs={10} lg={10} >
                <div className={classes.text} >
                  （2020年10月19日時点16社）
                </div>
              </Grid>
              <Grid item xs={10} lg={10} >
                <div className={classes.pos5}>
                  ご協力いただきありがとうございました。
                </div>
              </Grid>
              {/* <Grid item xs={0} lg={4} ></Grid> */}
              <Grid item xs={7} sm={6} md={6} lg={4} >
                <div>
                  <CardContent>
                    {kyosans.map((kyosan, index) => {
                      return (
                        <Grid key={index} >
                          <Typography className={classes.kyosanText} color="textSecondary" component='p' >
                            {kyosan}
                          </Typography>
                        </Grid>
                      )
                    })}
                  </CardContent>
                </div>
              </Grid>
              {/* <Grid item xs={0} lg={4} ></Grid> */}
              <Grid item xs={10} lg={10} >
                <div className={classes.kyosanText2}>
                  Special thanks<br />
              ご協力、ご協賛いただいた沢山の匿名の皆様
                </div>

              </Grid>

            </Grid>

          </Card>

          <Card className={classes.section2} >
            <Grid
              container spacing={0}
              direction="row"
              justify="center"
              alignItems="center">
              <span id='sponsor'></span>
              <h1 className={classes.sectionTitle} >- 後援 -</h1>
            </Grid>
            <Grid container spacing={1} justify="center" >
              <Grid item xs={12} lg={3}  >
                <Typography className={classes.support} color="textSecondary" component='p' >
                  荒川区
                    </Typography>
              </Grid>

            </Grid>
          </Card>

          <Card className={classes.section2} >
            <Grid
              container spacing={0}
              direction="row"
              justify="center"
              alignItems="center">
              <span id='sponsor'></span>
              <h1 className={classes.sectionTitle} >- 献血のお願い -</h1>
            </Grid>
            <Grid container spacing={1} justify="center" >
              <Grid item xs={10} lg={12}  >
                <Typography className={classes.support} color="textSecondary" component='p' >
                  イベント期間中、都電町屋駅横にて献血を行います。ぜひご協力ください。
                    </Typography>
              </Grid>
              <Grid item xs={4} md={4} lg={2} className={classes.center} >
                <p>【受付時間】</p>
                <p>10:00~11:45</p>
                <p>13:00~16:00</p>
              </Grid>

              <Grid item xs={6} sm={4} md={4} lg={3} className={classes.center} >
                <img className={classes.imgsizeKenketsu} alt=''
                  src="https://images.suku-yell.com/kenketsu-car.png" ></img>
              </Grid>

              <Grid container className={classes.bodyButton} alignItems="center" justify="center" spacing={0}>
                <Grid item xs={10} sm={5} md={7} lg={7}  >
                  <img className={classes.imgsizeKenketsuMap} alt=''
                    src="https://images.suku-yell.com/map.png" ></img>
                </Grid>
              </Grid>

            </Grid>
          </Card>
        </Grid>
      </Grid>
    </div>
  )
}

