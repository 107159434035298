import React from 'react';
import Header from '../utils/Header'
import Footer from '../utils/Footer'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Redirect } from 'react-router-dom';
import { Card, CardContent, Divider, Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { ConfirmationNumber } from '@material-ui/icons';
import HistoryIcon from '@material-ui/icons/History';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

const useStyles = makeStyles({
  sub: {
    width: '100%',
    height: '100%',
  },
  button: {
    backgroundColor: '#FF8C00',
    '&:hover': {
      backgroundColor: '#fad206',
    }
  },
  main: {
    backgroundColor: '#ff8a00',
  },
  white: {
    height: 0,
    width: 380,
    backgroundColor: '#ffffff',
  },
  root: {
  },
  media: {
    height: 0,
    width: 380,
  },
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  strong: {
    color: '#ff8a00',
  },
  fontStyle: {
    color: '#ff8a00',
  },
  iconSize: {
    fontSize: 100
  },
  space: {
    paddingTop: 60,
    backgroundColor: '#fff'
  }
});
const MyPage = (props) => {
  const classes = useStyles();
  const handleLink = (path) => history.push(path);
  const history = useHistory();
  if (props.state.isSignedIn) {
    // ログインしている
    return (
      <>
        <Header state={props.state} dispatch={props.dispatch} />
        <Grid container spacing={0} className={classes.space}>
          <Grid item md={3}>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} >
            <Card href="#" className={classes.button2} >
              <CardContent>
                <Grid container>

                  <Grid item xs={4} sm={4} md={4} lg={4} >


                    <AccountCircleIcon fontSize="large" className={classes.iconSize} />
                  </Grid>
                  <Grid item xs={8} sm={8} md={8} lg={8} >

                    <Typography variant="h5" component="h5" align="left">
                      {props.state.cognitoUser.username}さん
                    </Typography>
                    <Typography variant="h5" component="h5" align="left">
                      残り：<font size="10">{props.state.dynamoUser.purchasedTickets - props.state.dynamoUser.ticketPossession}</font>枚
                      <ConfirmationNumber className={classes.fontStyle} />
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <CardContent onClick={() => handleLink('/order')}>

                <Typography variant="h5" component="h5" align="left" >
                  <HistoryIcon />   注文履歴
                    </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid item sm={1} md={3}>
        </Grid>

        <Footer state={props.state} button></Footer>
      </>
    )
  } else {
    // ログインしていない
    return (
      <Redirect to={'/signin'} />
    )
  }
}

export default MyPage;