import React, { useCallback, useEffect, useState } from 'react';
import { API } from 'aws-amplify';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations'
import { makeStyles } from '@material-ui/core/styles';
import {
  Card, CardActions, CardContent,
  Container, Grid, Typography,
  IconButton, CardHeader, Avatar, Chip, Button
} from '@material-ui/core';
import Header from '../utils/Header'
import fetchOrderMenus from '../utils/fetchOrderMenus';
import red from '@material-ui/core/colors/red';
import findUser from '../utils/findUser';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#fff',
  },
  paper: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  cardTrue: {
    // backgroundColor: green[100],
  },
  cardFalse: {
    backgroundColor: red[100],
  },
  avatar: {
    backgroundColor: red[400],
  },
  space: {
    paddingTop: 80
  }
}));


export default function Takeout(props) {
  const classes = useStyles();

  /* ***********************************************
   * State
   * ************************************************/
  const [title, setTitle] = useState('注文状況')
  const [shops, setShops] = useState([])
  const [currentShop, setCurrentShop] = useState({})
  const [orderMenus, setOrderMenus] = useState([])
  const [takeoutOrders, setTakeoutOrders] = useState([])

  /* ***********************************************
   *  Function 初期化
   * ************************************************/
  useEffect(useCallback(() => {
    if (props.state.dynamoUser == null) return 0;
    (async () => {
      const variables = { limit: 1000 }
      const res = await API.graphql({ query: queries.listStores, variables: variables })
      setShops(res.data.listStores.items)
      if (props.state.dynamoUser.authority === 'Shop') {
        res.data.listStores.items.forEach(s => {
          if (s.name === props.state.dynamoUser.name) {
            setCurrentShop(s)
            setTitle(s.name + ' 様 注文状況')
          }
        })
      }
    })().catch(error => console.log('fetch error:', error))
  }, [props.state.dynamoUser]), [props.state.dynamoUser])

  /* ***********************************************
   *  Function 初期化
   * ************************************************/
  useEffect(useCallback(() => {
    if (Object.keys(currentShop).length > 0) {
      (async () => {
        const filter = { store: { eq: currentShop.name } }
        const res = await fetchOrderMenus(filter, 200)
        res.sort((a, b) => a.createdAt < b.createdAt ? 1 : a.createdAt > b.createdAt ? -1 : 0)
        setOrderMenus(res)
        const takeoutOrderMenus = res.filter(m => m.order.isTakeout)
        // 重複削除
        const takeoutOrderIDs = takeoutOrderMenus.map(m => m.order.id).filter((x, i, self) => self.indexOf(x) === i);
        const _orders = []
        for (const orderid of takeoutOrderIDs) {
          const menu = takeoutOrderMenus.find(m => m.order.id === orderid)
          if (!(menu === undefined)) {
            _orders.push(menu.order)
          }
        }
        _orders.sort((a, b) => a.createdAt < b.createdAt ? 1 : a.createdAt > b.createdAt ? -1 : 0)
        setTakeoutOrders(_orders)
      })().catch(error => console.log('fetch error:', error))
    }
  }, [currentShop]), [currentShop])

  /* ***********************************************
   *  Function ステータス更新
   * ************************************************/
  const updateStatus = async (order, status, index) => {
    if (order.status === '注文' || order.status === '準備中') {
      for (let menu of orderMenus.filter(m => m.orderID === order.id)) {
        await API.graphql({
          query: mutations.updateOrderMenu,
          variables: { input: { id: menu.id, status: status } }
        })
      }
      const res = await API.graphql({
        query: mutations.updateOrder,
        variables: { input: { id: order.id, status: status } }
      })
      const _orders = [...takeoutOrders]
      _orders.splice(index, 1, res.data.updateOrder)
      setTakeoutOrders(_orders)
    }
  }

  /* ***********************************************
   *  Function チケット支払い
   * ************************************************/
  const paymentTicket = async (order, index) => {

    const payCheck = await API.graphql({ query: queries.getOrder, variables: { id: order.id } })
    if (payCheck.data.getOrder?.consumed) return null

    const user = await findUser({ cognito: { eq: order.appuser } })
    if (user == null) return null

    const updateTicketPos = Number(user.ticketPossession) + Number(order.ticket)

    try {
      await API.graphql({
        query: mutations.updateUser,
        variables: { input: { id: user.id, ticketPossession: updateTicketPos } }
      })

      for (let menu of orderMenus.filter(m => m.orderID === order.id)) {
        await API.graphql({
          query: mutations.updateOrderMenu,
          variables: { input: { id: menu.id, status: '完了' } }
        })
      }
      const res = await API.graphql({
        query: mutations.updateOrder,
        variables: { input: { id: order.id, consumed: true, status: '完了' } }
      })
      const _orders = [...takeoutOrders]
      _orders.splice(index, 1, res.data.updateOrder)
      setTakeoutOrders(_orders)

    } catch (error) {
      alert('payment error');
      console.log(error)
    }

  }

  /* ***********************************************
   *  Function 日付フォーマット変換
   * ************************************************/
  const formatCreatedAt = (createdAt) => {
    const createdAtString = createdAt.substr(0, 19) + "+00:00"
    const at = new Date(createdAtString)
    return at.toLocaleString()
  }

  /* ***********************************************
   *  Render 専用 
   * ************************************************/
  const SwitchShop = () => {
    if (props.state.dynamoUser.authority === 'Admin' || props.state.dynamoUser.authority === 'Staff') {
      return (
        <>
          {shops.map((s, i) => {
            return (<button key={i} onClick={() => {
              setCurrentShop(s)
              setTitle(s.name + ' 様 注文状況')
            }}>{s.name}</button>)
          })}
        </>
      )
    }
    return null
  }

  /* ***********************************************
   *  Render スタッフ用 Main
   * ************************************************/
  return (
    <>
      <Header state={props.state} dispatch={props.dispatch} />
      <Typography variant="h4" component="h4" className={classes.space}> {title} </Typography>
      <Container maxWidth="lg">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <SwitchShop />
          </Grid>
          <Grid item xs={12}>
            {'件数(取引完了数/注文総数)：'}
            {takeoutOrders.filter(o => o.consumed).reduce((p, c) => Number(p) + 1, 0)}
            {'/'}
            {takeoutOrders.reduce((p, c) => Number(p) + 1, 0)}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" component="h5"> {'テイクアウト注文 一覧'} </Typography>
          </Grid>
          {takeoutOrders.map((order, index) => {
            return (
              <Grid item xs={12} md={6} key={index}>
                <Card className={order.consumed ? classes.cardTrue : classes.cardFalse}>
                  <CardHeader
                    avatar={
                      <Avatar aria-label="recipe" className={classes.avatar}>
                        {order.consumed ? '済' : '未'}
                      </Avatar>
                    }
                    action={
                      <IconButton aria-label="settings">
                        <Chip label={order.status} />
                      </IconButton>
                    }
                    title={order.appuser}
                    subheader={formatCreatedAt(order.createdAt)}
                  />
                  <CardContent>
                    {orderMenus.filter(m => m.orderID === order.id).map((menu, index) => {
                      return (
                        <Typography key={index} variant="body1" align="left">
                          {menu.menu}
                          <Typography children variant="body2" component='span'>
                            {' * '}{menu.orderCount}{'個'}
                          </Typography>
                        </Typography>
                      )
                    })}
                    <Typography key={index} variant="body1" align="right" color='textSecondary'>
                      {order.key}
                    </Typography>
                  </CardContent>
                  <CardActions >
                    <IconButton >
                      <Button variant="contained" onClick={() => updateStatus(order, '準備中', index)}>{'準備中'}</Button>
                    </IconButton>
                    <IconButton >
                      <Button variant="contained" onClick={() => updateStatus(order, '準備完了', index)}>{'準備完了'}</Button>
                    </IconButton>
                    {props.state.dynamoUser.authority === 'Admin' &&
                      <IconButton >
                        <Button variant="contained" color="secondary" onClick={() => paymentTicket(order, index)}>{'強制受領'}</Button>
                      </IconButton>
                    }
                  </CardActions>
                </Card>

              </Grid>
            )

          })}
        </Grid>
      </Container>
    </>
  )
}
