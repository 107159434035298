import React, { useCallback, useEffect, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations'
import Header from '../utils/Header'
import Footer from '../utils/Footer'
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Button, Chip, Container, Divider, Grid, makeStyles, Typography } from '@material-ui/core';
import { ConfirmationNumber, LocalShipping, Restaurant, ShoppingBasket } from '@material-ui/icons';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import fetchOrders from '../utils/fetchOrders';
const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: 'white',
    backgroundColor: '#fff6d2',
    color: '#ff8a00',
    paddingTop: 20,
    paddingBottom: 20,
  },
  title: {
    color: '#ff8a00',
    backgroundColor: '#fff6d2',
    paddingTop: 100
  },
  accordion: {

  },
  hoverOrange: {
    '&:hover': {
      color: '#FF8C00',
    },
  },
  colorOrange: {
    color: '#FF8C00',
  },
  bgWhite: {
    backgroundColor: '#FFF',
  },
  chip: {
    backgroundColor: '#fff6d2',
  },
  paperContainer: {
    width: '100%',
    maxWidth: 500,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
    margin: 'auto',
  },
  chipHeading: {
    fontSize: theme.typography.pxToRem(15),
    backgroundColor: '#fff6d2',
    flexBasis: '33.33%',
    flexShrink: 0,
    margin: 'auto',
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    margin: 'auto',
  },
}));

const CustomButton = (props) => {
  const customTheme = theme => ({
    root: {
      backgroundColor: '#fad206',
      color: '#4c1d00',
      fontWeight: 'bold',
      paddingLeft: '50px',
      paddingRight: '50px',
      minHeight: 40,
      minWidth: 150,
      '&:hover': {
        backgroundColor: '#ff8a00',
      },
    },
  })
  const ComponentName = withStyles(customTheme)(Button)
  return <ComponentName {...props} />
}

export default function Order(props) {
  const title = '注文履歴'
  const classes = useStyles();
  const history = useHistory();
  const handleLink = (path) => history.push(path);

  /* ***********************************************
   *  State
   * ************************************************/
  const [orders, setOrders] = useState([])
  const [expanded, setExpanded] = useState(false);

  /* ***********************************************
   *  Function 初期化 My注文履歴を取得
   * ************************************************/
  useEffect(useCallback(() => {
    if (Object.keys(props.state.cognitoUser).length) {
      (async () => {
        const filter = { appuser: { eq: props.state.cognitoUser.username } }
        const orders = await fetchOrders(filter, 200)
        orders.sort((a, b) => a.createdAt < b.createdAt ? 1 : a.createdAt > b.createdAt ? -1 : 0)
        setOrders(orders)
      })().catch(error => console.log('fetch orders error:', error))
    }
  }, [props.state.cognitoUser]), [props.state.cognitoUser])

  /* ***********************************************
   *  Function 日付フォーマット変換
   * ************************************************/
  const formatCreatedAt = (createdAt) => {
    const createdAtString = createdAt.substr(0, 19) + "+00:00"
    const at = new Date(createdAtString)
    return at.toLocaleString()
  }

  /* ***********************************************
   *  Function チケット支払い
   * ************************************************/
  const paymentTicket = async (order, index) => {
    const payTicket = Number(order.ticket)
    const consumedTicket = Number(props.state.dynamoUser.ticketPossession)

    const payCheck = await API.graphql({ query: queries.getOrder, variables: { id: order.id } })
    if (payCheck.data.getOrder.consumed) return null

    const userRes = await API.graphql(graphqlOperation(mutations.updateUser, {
      input: {
        id: props.state.dynamoUser.id,
        ticketPossession: consumedTicket + payTicket,
      }
    }))
    props.dispatch({ type: 'UPDATE', key: 'dynamoUser', value: userRes.data.updateUser })

    await order.menus.items.forEach(m => {
      API.graphql(graphqlOperation(mutations.updateOrderMenu, {
        input: {
          id: m.id,
          status: '受渡完了',
        }
      }))
    })
    const orderRes = await API.graphql(graphqlOperation(mutations.updateOrder, {
      input: {
        id: order.id,
        consumed: true,
        status: '受渡完了'
      }
    }))
    const _orders = [...orders]
    _orders.splice(index, 1, orderRes.data.updateOrder)
    setOrders(_orders)
  }
  /* ***********************************************
   *  Function 注文取り消し
   * ************************************************/
  const canselOrder = async (order, index) => {
    await order.menus.items.forEach(m => {
      API.graphql(graphqlOperation(mutations.updateOrderMenu, {
        input: { id: m.id, status: '取消', orderCount: 0, }
      }))
    })
    const res = await API.graphql(graphqlOperation(mutations.updateOrder, {
      input: { id: order.id, status: '取消', consumed: true, ticket: 0, }
    }))

    const _orders = [...orders]
    _orders.splice(index, 1, res.data.updateOrder)
    setOrders(_orders)
  }

  /* ***********************************************
   *  Function 
   * ************************************************/
  const handleChange = (order, index) => async (event, isExpanded) => {
    const res = await API.graphql({ query: queries.getOrder, variables: { id: order.id } })
    const _orders = [...orders]
    _orders.splice(index, 1, res.data.getOrder)
    setOrders(_orders)
    setExpanded(isExpanded ? order.id : false);
  };

  /* ***********************************************
   *  Render 注文サマリ
   * ************************************************/
  const OrderHeader = ({ order }) => {
    const orderType = order.isDelivery ? <LocalShipping /> : order.isTakeout ? <ShoppingBasket /> : order.isEatin ? <Restaurant /> : '';
    const consumedType = order.consumed ? '受領しました' : formatCreatedAt(order.createdAt);
    if (order.status === '取消') {
      return (
        <>
          <Chip className={classes.chipHeading} label={order.status} icon={<ConfirmationNumber />} variant="outlined" />
          <Typography className={classes.secondaryHeading}>{'注文をキャンセルしました'} </Typography>
        </>
      )
    }
    return (
      <>
        <Chip className={classes.chipHeading} label={order.status} icon={orderType} variant="outlined" />
        <Typography className={classes.secondaryHeading}>{consumedType}</Typography>
      </>
    )
  }
  /* ***********************************************
   *  Render 受領ボタン
   * ************************************************/
  const ReceiptButton = ({ order, index }) => {

    const payTicket = Number(order.ticket)
    const consumedTicket = Number(props.state.dynamoUser.ticketPossession)
    const purchasedTickets = Number(props.state.dynamoUser.purchasedTickets)
    if (order.consumed) {
      return (
        <CustomButton className={classes.button} disabled>{'受領済み'}</CustomButton>
      )
    }

    if (purchasedTickets - consumedTicket - payTicket < 0) {
      return (
        <CustomButton className={classes.button} onClick={() => handleLink('/ticket')}>{'チケット不足'}</CustomButton>
      )
    }

    if (order.status === '注文') {
      return (
        <CustomButton className={classes.button} onClick={() => canselOrder(order, index)}>{'キャンセル'}</CustomButton>
      )
    }

    if (order.status === '準備完了' || order.status === '配達中') {
      return (
        <CustomButton className={classes.button} onClick={() => paymentTicket(order, index)} >{'受け取る'}</CustomButton>
      )
    }
    return (
      <>
        <CustomButton className={classes.button} disabled >{'受け取る'}</CustomButton>
      </>
    )
  }

  /* ***********************************************
   *  Render 注文履歴
   * ************************************************/
  return (
    <>
      <Header state={props.state} dispatch={props.dispatch} />
      <Typography className={classes.title} variant='h4'> {title} </Typography>
      <div className={classes.root}>
        <Container maxWidth="md">
          <Grid container spacing={1}>
            {orders.map((order, index) => {
              return (
                <Grid item xs={12} key={index}>
                  <Accordion expanded={expanded === order.id} onChange={handleChange(order, index)} className={classes.accordion}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} id={order.id} >
                      <OrderHeader order={order} />
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography className={classes.heading}>{order.appuser}</Typography>
                    </AccordionDetails>
                    <Divider />
                    {order.menus?.items?.map((menu, index) => {
                      return (
                        <AccordionDetails key={index}>
                          <Typography className={classes.heading}>{menu.store}</Typography>
                          <Typography className={classes.secondaryHeading}>
                            {menu.menu}
                            {'('}{menu.ticket}{'枚)'}
                          </Typography>
                          <Typography className={classes.secondaryHeading}>{' × '}{menu.orderCount}{'個'}</Typography>
                        </AccordionDetails>
                      )
                    })}
                    <Divider />
                    <AccordionActions>
                      {/* <img className={classes.image} alt='' src="https://images.suku-yell.com/tiket.png" > </img> */}
                      <Typography>{'チケット合計：'}{order.ticket}{'枚'}</Typography>
                      <ReceiptButton order={order} index={index} />
                    </AccordionActions>
                    <AccordionActions>
                      <Typography variant='body2' color='textSecondary' align='right'>{'注文日時:'}{formatCreatedAt(order.createdAt)}</Typography>
                    </AccordionActions>
                    <AccordionActions>
                      <Typography variant='body2' color='textSecondary' align='right'>{'ID:'}{order.key}</Typography>
                    </AccordionActions>
                  </Accordion>
                </Grid>
              )
            })}

          </Grid>
        </Container>
      </div>
      <Footer simple logo />
    </>
  )
}
