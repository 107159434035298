
import React from 'react';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';

const images = {
  sp: {
    img: 'https://images.suku-yell.com/fv_sp.png',
    alt: ''
  },
  pc: {
    img: 'https://images.suku-yell.com/fv_pc.png',
    alt: ''
  },
}

function TopImage(props) {
  if (isWidthUp('sm', props.width)) {
    return (
      <>
        <img src={images.pc.img} alt={images.pc.alt} style={{ width: '100%' }} />
      </>
    )
  }

  return (
    <>
      <img src={images.sp.img} alt={images.sp.alt} style={{ width: '100%' }} />
    </>
  );
}

export default withWidth()(TopImage);