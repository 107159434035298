import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Header from '../utils/Header'
import Grid from '@material-ui/core/Grid';
import { useLocation } from 'react-router-dom';
import Footer from '../utils/Footer';

const useStyles = makeStyles({
    button: {
        backgroundColor: '#fad206',
        '&:hover': {
            backgroundColor: '#FF8C00',
        }
    },
    main: {
        backgroundColor: '#ff8a00',
    },
    menu: {
        Color: '#ff8a00',
    },
    main2: {
        backgroundColor: '#fff6d2',
        color: '#ff8a00',
        margin: 0,
        marginBottom: 0,
        paddingTop: 60
    },
    main3: {
        backgroundColor: '#fff6d2',
        margin: 0,
        marginBottom: 0,
    },
    main4: {
        backgroundColor: '#ffffff',
        margin: 0,
        paddingTop: 30,
        paddingBottom: 30,
    },
    strong: {
        color: '#ff8a00',
        fontSize: 20,
        display: "center",
    },
    text: {
        color: '#4c1d00',
        backgroundColor: '#ffffff',
        fontSize: 16,
        width: "100%",
        textAlign: "left",
    },
});

export default function Home(props) {
    const classes = useStyles();

    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    return (
        <>
            <Header state={props.state} dispatch={props.dispatch}></Header>
            <Typography className={classes.main3}>
                <Grid className={classes.main2} >
                    <h1 className={classes.strong}>特定商取引法に基づく表記</h1>
                </Grid>
                <Grid container spacing={0}
                    className={classes.main4}
                    direction="row"
                    justify="center"
                    alignItems="center">
                    <Container className={classes.main4}>
                        <Grid container spacing={1} justify="center" >
                            <Grid item xs={5} lg={3}  >
                                <Typography className={classes.text} color="textSecondary">販売社名</Typography>
                            </Grid>
                            <Grid item xs={7} lg={5}  >
                                <Typography className={classes.text} color="textSecondary">株式会社AndWe</Typography>
                            </Grid>
                        </Grid>

                        <Grid container spacing={1} justify="center" >
                            <Grid item xs={5} lg={3}  >
                                <Typography className={classes.text} color="textSecondary">運営統括責任者</Typography>
                            </Grid>
                            <Grid item xs={7} lg={5}  >
                                <Typography className={classes.text} color="textSecondary">小谷田　尚人</Typography>
                            </Grid>
                        </Grid>

                        <Grid container spacing={1} justify="center" >
                            <Grid item xs={5} lg={3}  >
                                <Typography className={classes.text} color="textSecondary">所在地</Typography>
                            </Grid>
                            <Grid item xs={7} lg={5}  >
                                <Typography className={classes.text} color="textSecondary">東京都台東区台東２丁目３１ー３</Typography>
                            </Grid>
                        </Grid>

                        <Grid container spacing={1} justify="center" >
                            <Grid item xs={5} lg={3}  >
                                <Typography className={classes.text} color="textSecondary">電話番号</Typography>
                            </Grid>
                            <Grid item xs={7} lg={5}  >
                                <Typography className={classes.text} color="textSecondary">03-5817-8306</Typography>
                            </Grid>
                        </Grid>

                        <Grid container spacing={1} justify="center" >
                            <Grid item xs={5} lg={3}  >
                                <Typography className={classes.text} color="textSecondary">メールアドレス</Typography>
                            </Grid>
                            <Grid item xs={7} lg={5}  >
                                <Typography className={classes.text} color="textSecondary">info@andwe.co.jp</Typography>
                            </Grid>
                        </Grid>

                        <Grid container spacing={1} justify="center" >
                            <Grid item xs={5} lg={3}  >
                                <Typography className={classes.text} color="textSecondary">販売URL</Typography>
                            </Grid>
                            <Grid item xs={7} lg={5}  >
                                <Typography className={classes.text} color="textSecondary">https://suku-yell.com/</Typography>
                            </Grid>
                        </Grid>

                        <Grid container spacing={1} justify="center" >
                            <Grid item xs={5} lg={3}  >
                                <Typography className={classes.text} color="textSecondary">お支払い方法 </Typography>
                            </Grid>
                            <Grid item xs={7} lg={5}  >
                                <Typography className={classes.text} color="textSecondary">クレジットカード／銀行振込</Typography>
                            </Grid>
                        </Grid>

                        <Grid container spacing={1} justify="center" >
                            <Grid item xs={5} lg={3}  >
                                <Typography className={classes.text} color="textSecondary">販売数量</Typography>
                            </Grid>
                            <Grid item xs={7} lg={5}  >
                                <Typography className={classes.text} color="textSecondary">一人1枚</Typography>
                            </Grid>
                        </Grid>

                        <Grid container spacing={1} justify="center" >
                            <Grid item xs={5} lg={3}  >
                                <Typography className={classes.text} color="textSecondary">お申込み有効期限</Typography>
                            </Grid>
                            <Grid item xs={7} lg={5}  >
                                <Typography className={classes.text} color="textSecondary">イベント当日までにお願いいたします。</Typography>
                            </Grid>
                        </Grid>

                        <Grid container spacing={1} justify="center" >
                            <Grid item xs={5} lg={3}  >
                                <Typography className={classes.text} color="textSecondary">商品引渡し時期</Typography>
                            </Grid>
                            <Grid item xs={7} lg={5}  >
                                <Typography className={classes.text} color="textSecondary">支払い完了後、イベント実施中の期間にご提供いたします。</Typography>
                            </Grid>
                        </Grid>

                        <Grid container spacing={1} justify="center" >
                            <Grid item xs={5} lg={3}  >
                                <Typography className={classes.text} color="textSecondary">返品・不良品について</Typography>
                            </Grid>
                            <Grid item xs={7} lg={5}  >
                                <Typography className={classes.text} color="textSecondary">返品・返却はございません。</Typography>
                            </Grid>
                        </Grid>

                        <Grid container spacing={1} justify="center" >
                            <Grid item xs={5} lg={3}  >
                                <Typography className={classes.text} color="textSecondary">表現、及び商品に関する注意書き</Typography>
                            </Grid>
                            <Grid item xs={7} lg={5}  >
                                <Typography className={classes.text} color="textSecondary">未成年者への販売は行なっておりません。</Typography>
                            </Grid>
                        </Grid>
                    </Container>
                </Grid>
            </Typography>

            <Footer logo simple />

        </>
    )
}