import React, { useReducer, useEffect, useCallback } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './App.css';

import * as queries from './graphql/queries';
import { Auth, Hub, API } from 'aws-amplify';

import { v4 as uuidv4 } from 'uuid';
import Home from './pages/Home';
import Stores from './pages/Stores';
import Menus from './pages/Menus';
import NotFound from './pages/Notfound';
import SignUp from './pages/SignUp';
import SignIn from './pages/SignIn';
import MyPage from './pages/MyPage';
import BuyTicket from './pages/BuyTicket';
import ForgotPassword from './pages/ForgotPassword';
import ShopTool from './pages/StoreTool'
import Cart from './pages/Cart';
import Order from './pages/Order';
import Delivery from './pages/Delivery';
import UserPolicy from './pages/UserPolicy';
import Takeout from './pages/Takeout';
import PrivacyPolisy from './pages/PrivacyPolisy';
import Howto from './pages/Howto';
import Eatin from './pages/Eatin';
import Map from './pages/Map';

import HowtoBuyTicket from './pages/HowtoBuyTicket';
import HowtoCharity from './pages/HowtoCharity';
import HowtoDelivery from './pages/HowtoDelivery';
import HowtoEatin from './pages/HowtoEatin';
import HowtoError from './pages/HowtoError';
import HowtoLogin from './pages/HowtoLogin';
import HowtoTakeout from './pages/HowtoTakeout';

import Tokusyoho from './pages/Tokusyoho';


const initalState = {
  title: '',
  type: 'INDEX',
  isSignedIn: false,
  cognitoUser: {},
  dynamoUser: {},
  cart: { id: uuidv4(), menus: [], isDelivery: false, isEatin: false, isTakeout: false, deliveryAddress: '', phone: '', description: '' },
  tabNumber: 0,
  topPageId: '',
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE':
      return { ...state, [action.key]: action.value }
    case 'SIGNIN':
      return { ...state, isSignedIn: true }
    case 'SIGNOUT':
      return { ...state, cognitoUser: {}, dynamoUser: {}, isSignedIn: false }
    case 'CART_UPDATE':
      return { ...state, cart: { ...state.cart, [action.key]: action.value } }
    case 'ABOUT':
      return { ...state, tabNumber: 0, topPageId: action.id }
    case 'REPORT':
      return { ...state, tabNumber: 1 }
    default:
      return state
  }
}

const findUser = async (filter) => {
  let user = null
  let next = null
  try {
    while (user == null) {
      const usersRes = await API.graphql({ query: queries.listUsers, variables: { filter: filter, limit: 1000, nextToken: next } })
      user = usersRes.data.listUsers.items[0]
      next = usersRes.data.listUsers.nextToken
      if (next == null) break
    }
  } catch (error) {
    console.log("find users error.", error)
  }
  return user
}


function App() {
  const [state, dispatch] = useReducer(reducer, initalState)

  /* ***********************************************
 *  Function 初期化
 * ************************************************/
  useEffect(useCallback(() => {
    (async () => {
      const authRes = await Auth.currentAuthenticatedUser()
      const userFilter = {
        cognito: { eq: authRes.username }
      }
      const user = await findUser(userFilter)
      dispatch({ type: 'UPDATE', key: 'cognitoUser', value: authRes })
      dispatch({ type: 'UPDATE', key: 'dynamoUser', value: user })
      dispatch({ type: 'SIGNIN' })
    })().catch(error => console.log('APP init error:', error))
  }, []), [])

  /* ***********************************************
 *  Function 初期化
 * ************************************************/
  useEffect(useCallback(() => {
    Hub.listen('auth', async (data) => {
      switch (data.payload.event) {
        case 'signIn':
          dispatch({ type: 'UPDATE', key: 'cognitoUser', value: data.payload.data })
          const userFilter = {
            cognito: { eq: data.payload.data.username }
          }
          const user = await findUser(userFilter)
          dispatch({ type: 'UPDATE', key: 'dynamoUser', value: user })
          dispatch({ type: 'SIGNIN' })
          break;
        case 'signUp':
          console.log('setAuthListener signUp:', data);
          break;
        case 'signOut':
          console.log('setAuthListener signOut:', data);
          dispatch({ type: 'SIGNOUT' })
          break;
        case 'signIn_failure':
          console.log('setAuthListener signIn_failure:', data);
          break;
        case 'configured':
          console.log('setAuthListener configured:', data);
          break;
        default:
          break;
      }
    });
  }, []), [])

  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route exact path='/' render={() => <Home state={state} dispatch={dispatch} />} />
          <Route exact path='/stores' render={() => <Stores state={state} dispatch={dispatch} />} />
          <Route exact path='/menus' render={() => <Menus state={state} dispatch={dispatch} />} />
          <Route exact path='/cart' render={() => <Cart state={state} dispatch={dispatch} />} />
          <Route exact path='/signup' render={() => <SignUp state={state} dispatch={dispatch} />} />
          <Route exact path='/signin' render={() => <SignIn state={state} dispatch={dispatch} />} />
          <Route exact path='/forgot-password' render={() => <ForgotPassword state={state} dispatch={dispatch} />} />
          <Route exact path='/mypage' render={() => <MyPage state={state} dispatch={dispatch} />} />
          <Route exact path='/ticket' render={() => <BuyTicket state={state} dispatch={dispatch} />} />
          <Route exact path='/order' render={() => <Order state={state} dispatch={dispatch} />} />
          <Route exact path='/userpolicy' render={() => <UserPolicy state={state} dispatch={dispatch} />} />
          <Route exact path='/privacypolicy' render={() => <PrivacyPolisy state={state} dispatch={dispatch} />} />
          <Route exact path='/howto' render={() => <Howto state={state} dispatch={dispatch} />} />
          <Route exact path='/tool/:key' render={() => <ShopTool state={state} dispatch={dispatch} />} />
          <Route exact path='/eatin' render={() => <Eatin state={state} dispatch={dispatch} />} />
          <Route exact path='/delivery' render={() => <Delivery state={state} dispatch={dispatch} />} />
          <Route exact path='/takeout' render={() => <Takeout state={state} dispatch={dispatch} />} />
          <Route exact path='/map' render={() => <Map state={state} dispatch={dispatch} />} />

          <Route exact path='/howtobuyticket' render={() => <HowtoBuyTicket state={state} dispatch={dispatch} />} />
          <Route exact path='/howtocharity' render={() => <HowtoCharity state={state} dispatch={dispatch} />} />
          <Route exact path='/howtoderivery' render={() => <HowtoDelivery state={state} dispatch={dispatch} />} />
          <Route exact path='/howtoeatin' render={() => <HowtoEatin state={state} dispatch={dispatch} />} />
          <Route exact path='/howtoerror' render={() => <HowtoError state={state} dispatch={dispatch} />} />
          <Route exact path='/howtologin' render={() => <HowtoLogin state={state} dispatch={dispatch} />} />
          <Route exact path='/howtotakeout' render={() => <HowtoTakeout state={state} dispatch={dispatch} />} />

          <Route exact path='/tokusyoho' render={() => <Tokusyoho state={state} dispatch={dispatch} />} />

          <Route component={NotFound} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
