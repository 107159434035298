import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CardContent from '@material-ui/core/CardContent';
import Header from '../utils/Header'
import Footer from '../utils/Footer'
import { Grid, Typography } from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
    main: {
        backgroundColor: '#FFF6D2',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'left',
        },
        [theme.breakpoints.up('md')]: {
            textAlign: 'center',
        },
        [theme.breakpoints.up('lg')]: {
            textAlign: 'center',
        },
    },
    section: {
        width: "100%",
        backgroundColor: '#fff',
        height: "100%",
        borderRadius: "30px",
        paddingTop: '20px',
        paddingBottom: '20px',
        marginTop: '20px',
        marginBottom: '20px'
    },

    pos2: {
        color: '#4C1D00',
        fontSize: 14,
        textAlign: 'left',
        // marginTop: 20,
        marginBottom: 20
    },
    pos3: {
        color: '#4C1D00',
        fontWeight: 'bold',
        fontSize: 16,
        textAlign: 'right',
        // marginTop: 20,
        marginBottom: 20
    },
    menuText2: {
        color: '#ff8a00',
        fontSize: 18,
        marginTop: 20,
        marginLeft: 20,
        fontWeight: 'bold',
        textAlign: 'left',
    },
    pos4: {
        color: '#4C1D00',
        fontSize: 14,
        fontWeight: 'bold',
        textDecoration: 'underline',
        paddingBottom: '20px',

    },
    menuText: {
        color: '#4C1D00',
        fontSize: 16,
        margin: 10,
        fontWeight: 'bold',
        textAlign: 'left',
    },
    imgSize: {
        width: '100%',
        backgroundColor: '#fff',
        display: 'block',
        textAlign: 'center',
        marginBottom: 20
    },
    main2: {
        backgroundColor: '#fff6d2',
        color: '#ff8a00',
        margin: 0,
        marginBottom: 0,
        paddingTop: 60
    },
    main3: {
        backgroundColor: '#fff6d2',
        margin: 0,
        marginBottom: 0,
    },
    paypalLink: {
        display: 'block',
        padding: 10,
        backgroundColor: '#FAD206',
        borderRadius: "50px",
        marginTop: 10,
        marginBottom: 30
    },
    attention: {
        display: 'block',
        padding: 10,
        backgroundColor: '#FAD206',
        borderRadius: "20px",
        // marginTop: 10,
        marginBottom: 30,
        fontWeight: 'bold',
        textAlign: 'left'
    },
    strong: {
        color: '#ff8a00'
    },
    linkColor: {
        color: '#ff8a00',
    },

}));




export default function Home(props) {
    const classes = useStyles();
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <>
            <Header state={props.state} className={classes.menu} dispatch={props.dispatch}></Header>
            <div className={classes.main3}>
                <Grid className={classes.main2} >
                    <h2>新規会員登録・ログイン</h2>
                </Grid>
                <Container className={classes.main} >


                    <div className={classes.section}>


                        <Grid container spacing={0} alignItems="center" justify="center">
                            <Grid item xs={11} lg={5} >
                                <h1 className={classes.menuText} >新規会員登録について</h1>
                            </Grid>
                        </Grid>
                        <Grid container spacing={0} alignItems="center" justify="center">
                            <Grid item xs={11} lg={5} >
                                <div>
                                    <CardContent>
                                        <Typography className={classes.pos2} >
                                            トップページ（イベントについて）の右上にあるメニューボタンを開き、新規登録を選択してください。
                                        </Typography>

                                        <img className={classes.imgSize} alt=''
                                            src="https://images.suku-yell.com/cap/cap_reg1.png" ></img>
                                        <Typography className={classes.pos2} >
                                            新規登録画面では、下記の順番に各項目を入力してください。<br /><br />
                                            ①救エールで利用するユーザ名（任意の文字列）<br />
                                            ②救エールで利用するユーザ名のパスワード（8文字以上かつ 英大文字・英小文字・数字それぞれを最低1文字ずつ含む必要があります）<br />
                                            ③新規登録時に認証コードをお送りするメールアドレス（※）<br />
                                            ※ご契約の携帯キャリアではメールが届かない場合があります。その場合は迷惑メールフィルターの設定にて
                                            「@verificationemail.com」を解除してください。<br />
                                            ④救エールサイトを利用するにあたっての利用規約となります。ご確認をお願いします。<br />
                                            ⑤「①~④」の入力と確認が終わりましたら、次へボタンを押下してください。（※）<br />
                                            ※「⑤」の次へボタンを押下すると「⑧」新規登録コード認証画面へ遷移します。

                                        </Typography>

                                        <img className={classes.imgSize} alt=''
                                            src="https://images.suku-yell.com/cap/cap_reg2.png" ></img>
                                        <img className={classes.imgSize} alt=''
                                            src="https://images.suku-yell.com/cap/cap_reg3.png" ></img>

                                        <Typography className={classes.pos2} >
                                            ⑥「⑤」の「次へ」ボタンを押下すると「④」指定したメールアドレスに認証コードが送付されてきます。<br />
                                            ⑦認証コードをメモしてください。<br />
                                        </Typography>
                                        <img className={classes.imgSize} alt=''
                                            src="https://images.suku-yell.com/cap/cap_reg4.png" ></img>

                                        <Typography className={classes.pos2} >
                                            ⑧「⑦」でメモした認証コードを「⑧」新規の認証コード欄に値を入力してください。<br />
                                            ⑨「⑧」の入力が終わりましたら、認証ボタンを押下してください。<br />
                                        </Typography>
                                        <img className={classes.imgSize} alt=''
                                            src="https://images.suku-yell.com/cap/cap_reg5.png" ></img>

                                        <Typography className={classes.pos2} >
                                            これで新規ユーザ登録が完了しました。ログイン画面へ遷移してログインしてください。
                                        </Typography>
                                        <img className={classes.imgSize} alt=''
                                            src="https://images.suku-yell.com/cap/cap_reg6.png" ></img>

                                    </CardContent>
                                </div>
                            </Grid>
                        </Grid>

                        <Grid container spacing={0} alignItems="center" justify="center">
                            <Grid item xs={11} lg={5} >
                                <h1 className={classes.menuText} >ログインについて</h1>
                            </Grid>
                        </Grid>
                        <Grid container spacing={0} alignItems="center" justify="center">
                            <Grid item xs={11} lg={5} >
                                <div>
                                    <CardContent>
                                        <Typography className={classes.pos2} >
                                            トップページ（イベントについて）の右上にあるメニューボタンを開き、ログインを選択してください。
                                        </Typography>

                                        <img className={classes.imgSize} alt=''
                                            src="https://images.suku-yell.com/cap/cap_log1.png" ></img>

                                        <Typography className={classes.pos2} >
                                            ログイン画面では、下記の順番に各項目を入力してください。<br />
                                            ①救エールで利用するユーザ名<br />
                                            ②救エールで利用するユーザ名のパスワード<br />
                                            ③「①~②」の入力が終わりましたら、ログインボタンを押下してください。
                                        </Typography>
                                        <img className={classes.imgSize} alt=''
                                            src="https://images.suku-yell.com/cap/cap_log2.png" ></img>

                                        <Typography className={classes.pos2} >
                                            これでログインが完了しました。
                                        </Typography>
                                        <img className={classes.imgSize} alt=''
                                            src="https://images.suku-yell.com/cap/cap_log3.png" ></img>
                                    </CardContent>
                                </div>
                            </Grid>
                        </Grid>


                    </div>



                </Container>

                <Typography className={classes.pos3} >
                    <Link className={classes.linkColor} to="/howto"> 利用方法もくじに戻る＞</Link>
                </Typography>


                <Grid>
                    <Footer state={props.state} ></Footer>
                </Grid>
            </div>
        </>
    )
}

