import { API } from 'aws-amplify';
import * as queries from '../graphql/queries';

export default async function fetchOrders(filter, limit) {
  const orders = []
  let next = null
  while (true) {
    const variables = { limit: limit }
    if (filter != null) variables["filter"] = filter
    if (next != null) variables["nextToken"] = next
    const res = await API.graphql({
      query: queries.listOrders,
      variables: variables
    })
    orders.push(...res.data.listOrders.items)
    next = res.data.listOrders.nextToken
    if (next == null) break
  }
  return orders
}
