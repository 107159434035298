import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import Header from '../utils/Header'
import Footer from '../utils/Footer'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
const initalFormState = {
  username: '',
  password: '',
  authCode: ''
}

const ForgotPassword = (props) => {

  const [formState, updateFormState] = useState(initalFormState)
  const [isAuthSend, setAuthSend] = useState(false)
  const [isSuccess, setSuccess] = useState(false)
  const [authError, setAuthError] = useState({})

  const onChange = (e) => {
    e.persist();
    updateFormState(() => ({ ...formState, [e.target.name]: e.target.value }))
  }

  const onSubmit = (e) => {
    e.preventDefault();
    console.log("onSubmit");
    console.log(formState);
    forgotPassword();
  }


  const forgotPassword = async () => {
    try {
      const { username } = formState;
      const data = await Auth.forgotPassword(username)
      console.log("forgot password :", data)
      setAuthSend(true)
    } catch (err) {
      setAuthError(err)
      console.log("forgot password error :", err)
    }
  };
  const fogotPasswordSubmit = async () => {

    try {
      const { username, authCode, password } = formState;
      const data = await Auth.forgotPasswordSubmit(username, authCode, password)
      console.log("fogotPasswordSubmit succese :", data)
      setSuccess(true)
    } catch (err) {
      setAuthError(err)
      console.log("fogotPasswordSubmit error :", err)
    }
  };

  const useStyles = makeStyles((theme) => ({
    paper: {
      paddingTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justify: "center",
      borderColor: '#fff6d2',
    },
    form: {
      margin: 4,
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    bgcolor: {
      backgroundColor: '#fff6d2',
      width: "100%",
      height: "100%",
    },
    textcolor: {
      color: '#FF8C00',
    },

    spacing: {
      padding: theme.spacing(2),
      color: '#FF8C00',
    },
    root: {
      backgroundColor: '#fff6d2',
      height: "100%",
      padding: theme.spacing(2),
      margin: 'auto',
      maxWidth: 500,
      justify: "center",
    },
    button: {
      width: "43%",
      margin: theme.spacing(3, 0, 2),
      backgroundColor: '#FF8C00',
      '&:hover': {
        backgroundColor: '#fad206',
        margin: theme.spacing(3, 0, 2),
      }
    },
  }));


  const classes = useStyles();

  if (props.state.isSignedIn) {
    return (
      <>
        <Header state={props.state} dispatch={props.dispatch}></Header>
        <div>すでにログインしています。</div>
        <div> <Link to="/">Homeへ</Link> </div>
        <Footer state={props.state}></Footer>
      </>
    )
  } else {
    if (isAuthSend && isSuccess) {
      return (
        <>
          <Header state={props.state} dispatch={props.dispatch}></Header>
          <Typography className={classes.textcolor} component="h4" variant="h4">
            {"パスワード変更 完了"}
          </Typography>
          <Footer state={props.state}></Footer>
        </>
      )
    } else if (isAuthSend) {
      return (
        <>

          <Header state={props.state} dispatch={props.dispatch}></Header>
          <CssBaseline />
          <Grid className={classes.paper} container direction="row" justify="center" alignItems="center" spacing={2}>
            <Grid item >
              <img alt=''
                src="https://images.suku-yell.com/logo_footer.png" width="100%" >
              </img>
            </Grid>
            <Grid item >
              <Typography className={classes.textcolor} component="h4" variant="h4">
                {"パスワード変更"}
              </Typography>
            </Grid>
            <Grid item >
              <Typography variant="body1" color="error">
                {authError.message}
              </Typography>
            </Grid>
            <form className={classes.form}>
              <Grid item>
                <TextField className={classes.form}
                  variant="outlined"
                  required
                  fullWidth
                  type="password"
                  id="password"
                  label="新しいパスワード"
                  name="password"
                  autoComplete="password"
                  autoFocus
                  placeholder='新しいパスワード'
                  onChange={onChange}
                />
              </Grid>
              <Grid item>
                <TextField className={classes.form}
                  variant="outlined"
                  required
                  fullWidth
                  id="authCode"
                  label="認証コード"
                  name="authCode"
                  autoComplete="authCode"
                  autoFocus
                  placeholder='認証コード'
                  onChange={onChange}
                />
              </Grid>
              <Grid item>
                <Button className={classes.button}
                  fullWidth
                  variant="contained"
                  placeholder='username'
                  onClick={fogotPasswordSubmit}
                >
                  変更</Button>
              </Grid>
            </form>
          </Grid>
          <Footer state={props.state}></Footer>
        </>
      )
    } else {
      return (
        <Grid className={classes.bgcolor}>
          <Header state={props.state} dispatch={props.dispatch}></Header>
          <div >
            <Grid className={classes.paper} >
              <CssBaseline />
              <div className={classes.paper} >
                <img alt=''
                  src="https://images.suku-yell.com/logo_footer.png" width="100%" ></img>
                <Box mt={8}>
                </Box>
                <h1 className={classes.textcolor}>パスワード変更</h1>
                <div>{authError.message}</div>
                <form className={classes.form} onSubmit={onSubmit}>
                  <TextField className={classes.form}
                    variant="outlined"
                    required
                    fullWidth
                    id="username"
                    label="ユーザー名"
                    name="username"
                    autoComplete="username"
                    autoFocus
                    placeholder='username'
                    onChange={onChange}
                  />
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                  >
                    <Button className={classes.button}
                      type="submit"
                      fullWidth
                      variant="contained"
                      placeholder='username'
                    >
                      変更</Button>

                  </Grid>
                </form>
              </div>
              <Box mt={8}>
              </Box>
            </Grid>
            <Footer state={props.state}></Footer>
          </div>
        </Grid>
      )
    }
  }
}

export default ForgotPassword;