import { API } from 'aws-amplify';
import * as queries from '../graphql/queries';

export default async function fetchOrderMenus(filter, limit) {
  const orderMenus = []
  let next = null
  while (true) {
    const variables = { limit: limit }
    if (filter != null) variables["filter"] = filter
    if (next != null) variables["nextToken"] = next
    const res = await API.graphql({
      query: queries.listOrderMenus,
      variables: variables
    })
    orderMenus.push(...res.data.listOrderMenus.items)
    next = res.data.listOrderMenus.nextToken
    if (next == null) break
  }
  return orderMenus
}
