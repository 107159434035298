import React from 'react';
import { Fab, Grid, Link as LinkM, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { yellow } from '@material-ui/core/colors';
import { Link } from 'react-router-dom';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
  },
  emptySpacing: {
    padding: 10,
    width: '100%',
  },
  footer: {
    minHeight: 160,
    backgroundColor: '#ff8a00',
  },
  imgsize: {
    height: '165px',
    backgroundColor: '#fff',
    paddingBottom: '20px',
    marginTop: 4,

  },
  absolute: {
    position: 'absolute',
    right: '5vw',
    backgroundColor: '#fad206'
  },

}));
const CustomButton = (props) => {
  const customTheme = theme => ({
    root: {
      backgroundColor: '#fad206',
      color: '#4c1d00',
      fontWeight: 'bold',
      minHeight: 80,
      minWidth: 270,
      '&:hover': {
        backgroundColor: '#ff8a00',
      },
    },
  })
  const ComponentName = withStyles(customTheme)(Button)
  return <ComponentName {...props} />
}

const Footer = ({ simple, logo, dispatch }) => {
  const classes = useStyles();
  return (
    <footer className={classes.root} id="contact">

      <Grid container alignItems="center" justify="center" spacing={1}>
        {(!simple || logo) &&
          <Grid item xs={12} >
            <img className={classes.imgsize} alt=''
              src="https://images.suku-yell.com/logo_footer.png" />
          </Grid>
        }
        {!simple &&
          <>
            <Grid item xs={12} md={4} lg={3}>
              <CustomButton themecolor={yellow} href="mailto:suku-yell-general@andwe.co.jp">一般の方のお問い合わせ</CustomButton>
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <CustomButton themecolor={yellow} href="mailto:suku-yell-sponsorship@andwe.co.jp">協賛についてのお問い合わせ</CustomButton>
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <CustomButton themecolor={yellow} href="mailto:suku-yell-media@andwe.co.jp">メディア関係のお問い合わせ</CustomButton>
            </Grid>
          </>
        }
        <div className={classes.emptySpacing}></div>
        <Grid item xs={12} >
          <Typography variant="h5" component="h2" style={{ fontWeight: 'bold' }}>
            {'まちやを救エール実行委員会'}
          </Typography>
        </Grid>
        <div className={classes.emptySpacing}></div>
      </Grid>

      <Grid container alignItems="center" justify="center" spacing={0} className={classes.footer}>
        {/* <Grid item xs={12} md={2}>
          <Typography variant="body2" component="div">
            <Link to="/privacypolicy" color='inherit'>個人情報の取り扱い ＞</Link>
          </Typography>
        </Grid>
        <Grid item xs={12} md={2}>
          <Typography variant="body2" component="div">
            <Link to="/userpolicy" color='inherit'>利用規約 ＞</Link>
          </Typography>
        </Grid>
        <Grid item xs={12} md={2}>
          <Typography variant="body2" component="div">
            <Link to="/tokusyoho" color='inherit'>特定商取引法に基づく表記 ＞</Link>
          </Typography>
        </Grid> */}




        <Grid item xs={12} md={2}>
          <Typography variant="body2" component="div">
            <Link to="" color='inherit' onClick={() => {
              window.scrollTo(100, 500);
              dispatch({ type: "ABOUT" })
            }
            }
            >イベントトップ　＞</Link>
          </Typography>
        </Grid>


        <Grid item xs={12} md={2}>
          <Typography variant="body2" component="div">
            <Link to="" color='inherit' onClick={() => {
              window.scrollTo(100, 500);
              dispatch({ type: "REPORT" })
            }
            }
            >イベントレポート　＞</Link>
          </Typography>
        </Grid>


        <Grid item xs={12} md={2}>
          <Typography variant="body2" component="div">
            <Link to="/stores" color='inherit' onClick={() => {
              window.scrollTo(0, 0);
            }}
            >参加店舗一覧　＞</Link>
          </Typography>
        </Grid>





        <Grid item xs={12} md={2}>
          <Typography variant="body2" component="div">
            <LinkM href="https://www.andwe.co.jp/" color='inherit'>運営会社 ＞</LinkM>
          </Typography>
        </Grid>
        <div className={classes.emptySpacing}>
          <Tooltip title="上に戻る" aria-label="toTop">
            <Fab size="small" className={classes.absolute}>
              <ExpandLessIcon onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} />
            </Fab>
          </Tooltip>
        </div>
        <Grid item xs={12} >
          <Typography variant="caption" component="p" align='center'>ⒸAndWe.Co.ltd.</Typography>
        </Grid>
      </Grid>

    </footer>
  )
}
export default Footer;