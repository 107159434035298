/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      cognito
      paypal
      gender
      age
      name
      authority
      purchasedTickets
      ticketPossession
      updatedAt
      createdAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cognito
        paypal
        gender
        age
        name
        authority
        purchasedTickets
        ticketPossession
        updatedAt
        createdAt
      }
      nextToken
    }
  }
`;
export const getPaypal = /* GraphQL */ `
  query GetPaypal($id: ID!) {
    getPaypal(id: $id) {
      id
      appuser
      cancelled
      email
      paid
      name
      payerid
      paymentid
      paymenttoken
      returnurl
      updatedAt
      createdAt
    }
  }
`;
export const listPaypals = /* GraphQL */ `
  query ListPaypals(
    $filter: ModelPaypalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPaypals(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        appuser
        cancelled
        email
        paid
        name
        payerid
        paymentid
        paymenttoken
        returnurl
        updatedAt
        createdAt
      }
      nextToken
    }
  }
`;
export const getStore = /* GraphQL */ `
  query GetStore($id: ID!) {
    getStore(id: $id) {
      id
      name
      description
      url
      image
      address
      tel
      googleMap
      businessHours
      ventilationType
      ventilationAt
      crowded
      updatedAt
      createdAt
      menus {
        items {
          id
          title
          description
          image
          ticket
          storeID
          soldOut
          delivery
          takeout
          eatin
          deleted
          updatedAt
          createdAt
        }
        nextToken
      }
    }
  }
`;
export const listStores = /* GraphQL */ `
  query ListStores(
    $filter: ModelStoreFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStores(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        url
        image
        address
        tel
        googleMap
        businessHours
        ventilationType
        ventilationAt
        crowded
        updatedAt
        createdAt
        menus {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getMenu = /* GraphQL */ `
  query GetMenu($id: ID!) {
    getMenu(id: $id) {
      id
      title
      description
      image
      ticket
      storeID
      soldOut
      delivery
      takeout
      eatin
      deleted
      updatedAt
      createdAt
      store {
        id
        name
        description
        url
        image
        address
        tel
        googleMap
        businessHours
        ventilationType
        ventilationAt
        crowded
        updatedAt
        createdAt
        menus {
          nextToken
        }
      }
    }
  }
`;
export const listMenus = /* GraphQL */ `
  query ListMenus(
    $filter: ModelMenuFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMenus(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        image
        ticket
        storeID
        soldOut
        delivery
        takeout
        eatin
        deleted
        updatedAt
        createdAt
        store {
          id
          name
          description
          url
          image
          address
          tel
          googleMap
          businessHours
          ventilationType
          ventilationAt
          crowded
          updatedAt
          createdAt
        }
      }
      nextToken
    }
  }
`;
export const getOrder = /* GraphQL */ `
  query GetOrder($id: ID!) {
    getOrder(id: $id) {
      id
      appuser
      status
      key
      ticket
      isDelivery
      isTakeout
      isEatin
      phone
      address
      consumed
      description
      updatedAt
      createdAt
      menus {
        items {
          id
          store
          menu
          ticket
          orderCount
          status
          description
          orderID
          updatedAt
          createdAt
        }
        nextToken
      }
    }
  }
`;
export const listOrders = /* GraphQL */ `
  query ListOrders(
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        appuser
        status
        key
        ticket
        isDelivery
        isTakeout
        isEatin
        phone
        address
        consumed
        description
        updatedAt
        createdAt
        menus {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getOrderMenu = /* GraphQL */ `
  query GetOrderMenu($id: ID!) {
    getOrderMenu(id: $id) {
      id
      store
      menu
      ticket
      orderCount
      status
      description
      orderID
      updatedAt
      createdAt
      order {
        id
        appuser
        status
        key
        ticket
        isDelivery
        isTakeout
        isEatin
        phone
        address
        consumed
        description
        updatedAt
        createdAt
        menus {
          nextToken
        }
      }
    }
  }
`;
export const listOrderMenus = /* GraphQL */ `
  query ListOrderMenus(
    $filter: ModelOrderMenuFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrderMenus(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        store
        menu
        ticket
        orderCount
        status
        description
        orderID
        updatedAt
        createdAt
        order {
          id
          appuser
          status
          key
          ticket
          isDelivery
          isTakeout
          isEatin
          phone
          address
          consumed
          description
          updatedAt
          createdAt
        }
      }
      nextToken
    }
  }
`;
export const getYoutube = /* GraphQL */ `
  query GetYoutube($id: ID!) {
    getYoutube(id: $id) {
      id
      title
      url
      description
      deleted
      updatedAt
      createdAt
    }
  }
`;
export const listYoutubes = /* GraphQL */ `
  query ListYoutubes(
    $filter: ModelYoutubeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listYoutubes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        url
        description
        deleted
        updatedAt
        createdAt
      }
      nextToken
    }
  }
`;
export const searchUsers = /* GraphQL */ `
  query SearchUsers(
    $filter: SearchableUserFilterInput
    $sort: SearchableUserSortInput
    $limit: Int
    $nextToken: String
  ) {
    searchUsers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cognito
        paypal
        gender
        age
        name
        authority
        purchasedTickets
        ticketPossession
        updatedAt
        createdAt
      }
      nextToken
      total
    }
  }
`;
export const searchPaypals = /* GraphQL */ `
  query SearchPaypals(
    $filter: SearchablePaypalFilterInput
    $sort: SearchablePaypalSortInput
    $limit: Int
    $nextToken: String
  ) {
    searchPaypals(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        appuser
        cancelled
        email
        paid
        name
        payerid
        paymentid
        paymenttoken
        returnurl
        updatedAt
        createdAt
      }
      nextToken
      total
    }
  }
`;
export const searchStores = /* GraphQL */ `
  query SearchStores(
    $filter: SearchableStoreFilterInput
    $sort: SearchableStoreSortInput
    $limit: Int
    $nextToken: String
  ) {
    searchStores(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        url
        image
        address
        tel
        googleMap
        businessHours
        ventilationType
        ventilationAt
        crowded
        updatedAt
        createdAt
        menus {
          nextToken
        }
      }
      nextToken
      total
    }
  }
`;
export const searchMenus = /* GraphQL */ `
  query SearchMenus(
    $filter: SearchableMenuFilterInput
    $sort: SearchableMenuSortInput
    $limit: Int
    $nextToken: String
  ) {
    searchMenus(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        image
        ticket
        storeID
        soldOut
        delivery
        takeout
        eatin
        deleted
        updatedAt
        createdAt
        store {
          id
          name
          description
          url
          image
          address
          tel
          googleMap
          businessHours
          ventilationType
          ventilationAt
          crowded
          updatedAt
          createdAt
        }
      }
      nextToken
      total
    }
  }
`;
export const searchOrders = /* GraphQL */ `
  query SearchOrders(
    $filter: SearchableOrderFilterInput
    $sort: SearchableOrderSortInput
    $limit: Int
    $nextToken: String
  ) {
    searchOrders(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        appuser
        status
        key
        ticket
        isDelivery
        isTakeout
        isEatin
        phone
        address
        consumed
        description
        updatedAt
        createdAt
        menus {
          nextToken
        }
      }
      nextToken
      total
    }
  }
`;
export const searchOrderMenus = /* GraphQL */ `
  query SearchOrderMenus(
    $filter: SearchableOrderMenuFilterInput
    $sort: SearchableOrderMenuSortInput
    $limit: Int
    $nextToken: String
  ) {
    searchOrderMenus(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        store
        menu
        ticket
        orderCount
        status
        description
        orderID
        updatedAt
        createdAt
        order {
          id
          appuser
          status
          key
          ticket
          isDelivery
          isTakeout
          isEatin
          phone
          address
          consumed
          description
          updatedAt
          createdAt
        }
      }
      nextToken
      total
    }
  }
`;
