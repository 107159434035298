import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Header from '../utils/Header'
import Grid from '@material-ui/core/Grid';
import { useLocation } from 'react-router-dom';
import Footer from '../utils/Footer';

const useStyles = makeStyles({
  button: {
    backgroundColor: '#fad206',
    '&:hover': {
      backgroundColor: '#FF8C00',
    }
  },
  main: {
    backgroundColor: '#ff8a00',
  },
  menu: {
    Color: '#ff8a00',
  },
  main2: {
    backgroundColor: '#fff6d2',
    color: '#ff8a00',
    margin: 0,
    marginBottom: 0,
    paddingTop: 60
  },
  main3: {
    backgroundColor: '#fff6d2',
    margin: 0,
    marginBottom: 0,
  },
  main4: {
    backgroundColor: '#ffffff',
    margin: 0,
    paddingTop: 30,
    paddingBottom: 30,
  },
  strong: {
    color: '#ff8a00',
    fontSize: 20,
    display: "center",
  },
  text: {
    color: '#4c1d00',
    backgroundColor: '#ffffff',
    fontSize: 16,
    width: "100%",
    textAlign: "left",
  },
});

export default function Home(props) {
  const classes = useStyles();

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <Header state={props.state} dispatch={props.dispatch}></Header>
      <Typography className={classes.main3}>
        <Grid className={classes.main2} >

          <h1 className={classes.strong}>利用規約</h1>
        </Grid>
        <Grid container spacing={0}
          className={classes.main4}
          direction="row"
          justify="center"
          alignItems="center">
          <Container className={classes.main4}>
            <Typography className={classes.text} color="textSecondary">

              <div className={classes.text}>この利用規約（以下，「本規約」といいます。）は，株式会社AndWe（以下，「当社」といいます。）がこのウェブサイト上で提供するサービス（以下，「本サービス」といいます。）の利用条件を定めるものです。登録ユーザーの皆さま（以下，「ユーザー」といいます。）には，本規約に従って，本サービスをご利用いただきます。<br /></div>
              <div className={classes.text}><br /><b> 第1条（適用）</b></div>
              <div className={classes.text}> 本規約は，ユーザーと当社との間の本サービスの利用に関わる一切の関係に適用されるものとします。<br />
              当社は本サービスに関し，本規約のほか，ご利用にあたってのルール等，各種の定め（以下，「個別規定」といいます。）をすることがあります。<br />
              これら個別規定はその名称のいかんに関わらず，本規約の一部を構成するものとします。<br />
              本規約の規定が前条の個別規定の規定と矛盾する場合には，個別規定において特段の定めなき限り，個別規定の規定が優先されるものとします。<br />
              </div>

              <div className={classes.text}><br /><b> 第2条（利用登録）</b></div>
              <div className={classes.text}> 本サービスにおいては，登録希望者が本規約に同意の上，当社の定める方法によって利用登録を申請し，当社がこれを承認することによって，利用登録が完了するものとします。<br />
              当社は，利用登録の申請者に以下の事由があると判断した場合，利用登録の申請を承認しないことがあり，その理由については一切の開示義務を負わないものとします。<br />
              ・利用登録の申請に際して虚偽の事項を届け出た場合<br />
              ・本規約に違反したことがある者からの申請である場合<br />
              ・その他，当社が利用登録を相当でないと判断した場合<br />
              </div>

              <div className={classes.text}><br /><b> 第3条（ユーザーIDおよびパスワードの管理）</b></div>
              <div className={classes.text}> ユーザーは，自己の責任において，本サービスのユーザーIDおよびパスワードを適切に管理するものとします。<br />
              ユーザーは，いかなる場合にも，ユーザーIDおよびパスワードを第三者に譲渡または貸与し，もしくは第三者と共用することはできません。<br />
              当社は，ユーザーIDとパスワードの組み合わせが登録情報と一致してログインされた場合には，そのユーザーIDを登録しているユーザー自身による利用とみなします。<br />
              ユーザーID及びパスワードが第三者によって使用されたことによって生じた損害は，当社に故意又は重大な過失がある場合を除き，当社は一切の責任を負わないものとします。<br />
              </div>

              <div className={classes.text}><br /><b> 第4条（利用料金および支払方法）</b></div>
              <div className={classes.text}> ユーザーは，本サービスの有料部分の対価として，当社が別途定め，本ウェブサイトに表示する利用料金を，当社が指定する方法により支払うものとします。<br />
              ユーザーが利用料金の支払を遅滞した場合には，ユーザーは年14．6％の割合による遅延損害金を支払うものとします。<br />
              なお、イベント最終日の11月8日22時を過ぎた段階で残チケットがあった場合は運営費として決済させていただきます。<br />
              </div>

              <div className={classes.text}><br /><b> 第5条（禁止事項）</b></div>
              <div className={classes.text}> ユーザーは，本サービスの利用にあたり，以下の行為をしてはなりません。<br />
              ・法令または公序良俗に違反する行為<br />
              ・犯罪行為に関連する行為<br />
              ・本サービスの内容等，本サービスに含まれる著作権，商標権ほか知的財産権を侵害する行為<br />
              ・当社，ほかのユーザー，またはその他第三者のサーバーまたはネットワークの機能を破壊したり，妨害したりする行為<br />
              ・本サービスによって得られた情報を商業的に利用する行為<br />
              ・当社のサービスの運営を妨害するおそれのある行為<br />
              ・不正アクセスをし，またはこれを試みる行為<br />
              ・他のユーザーに関する個人情報等を収集または蓄積する行為<br />
              ・不正な目的を持って本サービスを利用する行為<br />
              ・本サービスの他のユーザーまたはその他の第三者に不利益，損害，不快感を与える行為<br />
              ・他のユーザーに成りすます行為<br />
              ・当社が許諾しない本サービス上での宣伝，広告，勧誘，または営業行為<br />
              ・面識のない異性との出会いを目的とした行為<br />
              ・当社のサービスに関連して，反社会的勢力に対して直接または間接に利益を供与する行為<br />
              ・その他，当社が不適切と判断する行為<br /></div>

              <div className={classes.text}><br /><b>第6条（本サービスの提供の停止等）</b> </div>
              <div className={classes.text}>１．当社は，以下のいずれかの事由があると判断した場合，ユーザーに事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。<br />
              ・本サービスにかかるコンピュータシステムの保守点検または更新を行う場合<br />
              ・地震，落雷，火災，停電または天災などの不可抗力により，本サービスの提供が困難となった場合<br />
              ・コンピュータまたは通信回線等が事故により停止した場合<br />
              ・その他，当社が本サービスの提供が困難と判断した場合<br />
              </div>
              <div className={classes.text}>２．当社は，本サービスの提供の停止または中断により，ユーザーまたは第三者が被ったいかなる不利益または損害についても，一切の責任を負わないものとします。<br />
              </div>

              <div className={classes.text}><br /><b>第7条（利用制限および登録抹消）</b> </div>
              <div className={classes.text}> １．当社は，ユーザーが以下のいずれかに該当する場合には，事前の通知なく，ユーザーに対して，本サービスの全部もしくは一部の利用を制限し，またはユーザーとしての登録を抹消することができるものとします。<br />
              ・本規約のいずれかの条項に違反した場合<br />
              ・登録事項に虚偽の事実があることが判明した場合<br />
              ・料金等の支払債務の不履行があった場合<br />
              ・当社からの連絡に対し，一定期間返答がない場合<br />
              ・本サービスについて，最終の利用から一定期間利用がない場合<br />
              ・その他，当社が本サービスの利用を適当でないと判断した場合<br /></div>
              <div className={classes.text}>２．当社は，本条に基づき当社が行った行為によりユーザーに生じた損害について，一切の責任を負いません。<br />
              </div>

              <div className={classes.text}><br /><b> 第8条（退会）</b></div>
              <div className={classes.text}> ユーザーは，当社の定める退会手続により，本サービスから退会できるものとします。<br /></div>

              <div className={classes.text}><br /><b> 第9条（保証の否認および免責事項）</b></div>
              <div className={classes.text}>
                １．当社は，本サービスに事実上または法律上の瑕疵（安全性，信頼性，正確性，完全性，有効性，特定の目的への適合性，セキュリティなどに関する欠陥，エラーやバグ，権利侵害などを含みます。）がないことを明示的にも黙示的にも保証しておりません。<br />
              ２．当社は，本サービスに起因してユーザーに生じたあらゆる損害について一切の責任を負いません。ただし，本サービスに関する当社とユーザーとの間の契約（本規約を含みます。）が消費者契約法に定める消費者契約となる場合，この免責規定は適用されません。<br />
              ３．前項ただし書に定める場合であっても，当社は，当社の過失（重過失を除きます。）による債務不履行または不法行為によりユーザーに生じた損害のうち特別な事情から生じた損害（当社またはユーザーが損害発生につき予見し，または予見し得た場合を含みます。）について一切の責任を負いません。また，当社の過失（重過失を除きます。）による債務不履行または不法行為によりユーザーに生じた損害の賠償は，ユーザーから当該損害が発生した月に受領した利用料の額を上限とします。<br />
              ４．当社は，本サービスに関して，ユーザーと他のユーザーまたは第三者との間において生じた取引，連絡または紛争等について一切責任を負いません。<br /></div>

              <div className={classes.text}><br /><b> 第10条（サービス内容の変更等）</b></div>
              <div className={classes.text}> 当社は，ユーザーに通知することなく，本サービスの内容を変更しまたは本サービスの提供を中止することができるものとし，これによってユーザーに生じた損害について一切の責任を負いません。<br /></div>

              <div className={classes.text}><br /><b>第11条（利用規約の変更）</b></div>
              <div className={classes.text}> 当社は，必要と判断した場合には，ユーザーに通知することなくいつでも本規約を変更することができるものとします。なお，本規約の変更後，本サービスの利用を開始した場合には，当該ユーザーは変更後の規約に同意したものとみなします。<br /></div>

              <div className={classes.text}><br /><b> 第12条（個人情報の取扱い）</b></div>
              <div className={classes.text}> 当社は，本サービスの利用によって取得する個人情報については，当社「プライバシーポリシー」に従い適切に取り扱うものとします。<br /></div>

              <div className={classes.text}> <br /><b> 第13条（通知または連絡）</b></div>
              <div className={classes.text}> ユーザーと当社との間の通知または連絡は，当社の定める方法によって行うものとします。当社は,ユーザーから,当社が別途定める方式に従った変更届け出がない限り,現在登録されている連絡先が有効なものとみなして当該連絡先へ通知または連絡を行い,これらは,発信時にユーザーへ到達したものとみなします。<br /></div>

              <div className={classes.text}><br /><b> 第14条（権利義務の譲渡の禁止）</b></div>
              <div className={classes.text}> ユーザーは，当社の書面による事前の承諾なく，利用契約上の地位または本規約に基づく権利もしくは義務を第三者に譲渡し，または担保に供することはできません。<br /></div>

              <div className={classes.text}><br /><b> 第15条（準拠法・裁判管轄）</b></div>
              <div className={classes.text}> 本規約の解釈にあたっては，日本法を準拠法とします。<br />
              本サービスに関して紛争が生じた場合には，当社の本店所在地を管轄する裁判所を専属的合意管轄とします。<br /></div>
            </Typography>
          </Container>
        </Grid>
      </Typography>

      <Footer logo simple />

    </>
  )
}