import { API } from 'aws-amplify';
import * as queries from '../graphql/queries';

export default async function findUser(filter) {
  let user = null
  let next = null
  try {
    while (user == null) {
      const usersRes = await API.graphql({ query: queries.listUsers, variables: { filter: filter, limit: 1000, nextToken: next } })
      user = usersRes.data.listUsers.items[0]
      next = usersRes.data.listUsers.nextToken
      if (next == null) break
    }
  } catch (error) {
    console.log("find users error.", error)
  }
  return user
}