import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Header from '../utils/Header'
import Footer from '../utils/Footer'
import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useEffect } from "react";
import { useLocation } from "react-router-dom";


const useStyles = makeStyles((theme) => ({
    main: {
        backgroundColor: '#FFF6D2',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'left',
        },
        [theme.breakpoints.up('md')]: {
            textAlign: 'center',
        },
        [theme.breakpoints.up('lg')]: {
            textAlign: 'center',
        },
    },
    section: {
        width: "100%",
        backgroundColor: '#fff',
        height: "100%",
        borderRadius: "30px",
        paddingTop: '20px',
        paddingBottom: '20px',
        marginTop: '20px',
        marginBottom: '20px'
    },

    pos2: {
        color: '#4C1D00',
        fontSize: 14,
        textAlign: 'left',
        // marginTop: 20,
        marginBottom: 20
    },
    menuText2: {
        color: '#ff8a00',
        fontSize: 18,
        marginTop: 20,
        marginLeft: 20,
        fontWeight: 'bold',
        textAlign: 'left',
    },
    pos4: {
        color: '#4C1D00',
        fontSize: 14,
        fontWeight: 'bold',
        textDecoration: 'underline',
        paddingBottom: '20px',

    },
    menuText: {
        color: '#4C1D00',
        fontSize: 15,
        margin: 10,
        fontWeight: 'bold',
        textAlign: 'left',
    },
    imgSize: {
        width: '100%',
        backgroundColor: '#fff',
        display: 'block',
        textAlign: 'center',
        marginBottom: 20
    },
    main2: {
        backgroundColor: '#fff6d2',
        color: '#ff8a00',
        margin: 0,
        marginBottom: 0,
        paddingTop: 60
    },
    main3: {
        backgroundColor: '#fff6d2',
        margin: 0,
        marginBottom: 0,
    },
    paypalLink: {
        display: 'block',
        padding: 10,
        backgroundColor: '#FAD206',
        borderRadius: "50px",
        marginTop: 10,
        marginBottom: 30
    },
    attention: {
        display: 'block',
        padding: 10,
        backgroundColor: '#FAD206',
        borderRadius: "20px",
        // marginTop: 10,
        marginBottom: 30,
        fontWeight: 'bold',
        textAlign: 'left'
    },
    strong: {
        color: '#ff8a00'
    },
    linkColor: {
        color: '#ff8a00',
    },

}));




export default function Home(props) {
    const classes = useStyles();
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <>
            <Header state={props.state} className={classes.menu} dispatch={props.dispatch}></Header>
            <div className={classes.main3}>
                <Grid className={classes.main2} >
                    <h2>利用の流れ</h2>
                </Grid>
                <Container className={classes.main} >


                    <div className={classes.section}>
                        <Grid container spacing={0} alignItems="center" justify="center">
                            <Grid item xs={10} lg={5} >
                                <h3 className={classes.strong}>イベント当日までの流れ</h3>
                            </Grid>
                        </Grid>

                        <Grid container spacing={0} alignItems="center" justify="center">
                            <Grid item xs={11} lg={5} >
                                <Link className={classes.linkColor} to="/howtologin"> <p className={classes.menuText}>新規会員登録・ログインについて ＞</p></Link>
                            </Grid>
                        </Grid>

                        <Grid container spacing={0} alignItems="center" justify="center">
                            <Grid item xs={11} lg={5} >
                                <Link className={classes.linkColor} to="/howtobuyticket"> <p className={classes.menuText}>チケットの購入について ＞</p></Link>
                            </Grid>
                        </Grid>

                    </div>

                    <div className={classes.section}>
                        <Grid container spacing={0} alignItems="center" justify="center">
                            <Grid item xs={10} lg={5} >
                                <h3 className={classes.strong}>イベント当日からできること</h3>
                                <p>イベント当日から注文が可能になります。
                                イベント時間外の注文はお受けできません。</p>
                            </Grid>
                        </Grid>
                        <Grid container spacing={0} alignItems="center" justify="center">
                            <Grid item xs={11} lg={5} >
                                <Link className={classes.linkColor} to="/howtoeatin"> <p className={classes.menuText} >イートインについて ＞</p></Link>
                            </Grid>
                        </Grid>
                        <Grid container spacing={0} alignItems="center" justify="center">
                            <Grid item xs={11} lg={5} >
                                <Link className={classes.linkColor} to="/howtotakeout">  <p className={classes.menuText} >テイクアウトについて ＞</p></Link>
                            </Grid>
                        </Grid>
                        <Grid container spacing={0} alignItems="center" justify="center">
                            <Grid item xs={11} lg={5} >
                                <Link className={classes.linkColor} to="/howtoderivery"> <p className={classes.menuText} >デリバリーについて ＞</p></Link>
                            </Grid>
                        </Grid>
                        <Grid container spacing={0} alignItems="center" justify="center">
                            <Grid item xs={11} lg={5} >
                                <Link className={classes.linkColor} to="/howtocharity"> <p className={classes.menuText} >寄付について ＞</p></Link>
                            </Grid>
                        </Grid>
                        <Grid container spacing={0} alignItems="center" justify="center">
                            <Grid item xs={10} lg={5} >
                                <p>テイクアウト・デリバリーに関する問い合わせはこちら<br />
                                Tel：070-3864-4050<br />
                                Mail：suku-yell-general@andwe.co.jp </p>
                            </Grid>
                        </Grid>
                    </div>

                    <div className={classes.section}>
                        <Grid container spacing={0} alignItems="center" justify="center">
                            <Grid item xs={10} lg={5} >
                                <h3 className={classes.strong}>その他</h3>
                            </Grid>
                        </Grid>
                        <Grid container spacing={0} alignItems="center" justify="center">
                            <Grid item xs={11} lg={5} >
                                <Link className={classes.linkColor} to="/howtoerror"> <p className={classes.menuText} >うまくサイトが動かない ＞</p></Link>
                            </Grid>
                        </Grid>
                    </div>

                </Container>

                <Grid>
                    <Footer state={props.state} ></Footer>
                </Grid>
            </div>
        </>
    )
}

