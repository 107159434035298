import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Header from '../utils/Header'
import Footer from '../utils/Footer';
import { Grid, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { yellow } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  button: {
    backgroundColor: '#fad206',
    '&:hover': {
      backgroundColor: '#FF8C00',
    }
  },
  body2: {
    padding: '20px'
  },
  white: {
    width: "100%",
    height: "xs",
    backgroundColor: '#fff6d2',
    paddingTop: 60
  },
  root: {
    width: "100%",
    backgroundColor: '#fff6d2',
    height: "100%",
    borderRadius: "30px",
    paddingTop: '20px',
    paddingBottom: '20px',
    // marginTop: '20px',
    marginBottom: '20px'
  },
  bigtitle: {
    color: '#ff8a00',
    // marginTop: 60,
    fontSize: 40,
    display: "center",
    textAlign: 'center'
  },
  subtitle: {
    color: '#ff8a00',
    fontSize: 20,
    marginBottom: 30,
  },
  center: {
    textAlign: 'center',
    width: '100%',
    marginTop: 80,
    marginBottom: 100,
  },
});

const stateDammy = {
  title: '',
}
const CustomButton = (props) => {
  const customTheme = theme => ({
    root: {
      backgroundColor: '#fad206',
      color: '#4c1d00',
      fontWeight: 'bold',
      paddingLeft: '50px',
      paddingRight: '50px',
      minHeight: 80,
      '&:hover': {
        backgroundColor: '#ff8a00',
      },
    },
  })
  const ComponentName = withStyles(customTheme)(Button)
  return <ComponentName {...props} />
}
const NotFound = (props) => {
  const classes = useStyles();
  return (
    <>
      <Header state={stateDammy} dispatch={props.dispatch}></Header>
      <div className={classes.white}>
        <h1 className={classes.bigtitle}>404 Not Foud</h1>
        <h2 className={classes.subtitle}>お探しのページは見つかりませんでした</h2>
      </div>
      <div className={classes.center}>
        <Grid container className={classes.bodyButton} alignItems="center" justify="center" spacing={0}>

          <Grid item xs={9} md={2}>
            <Typography variant="body2" component="div" className={classes.button}>
              <CustomButton themecolor={yellow} href="/">トップページに戻る</CustomButton>
            </Typography>
          </Grid>
        </Grid>
      </div>
      <Footer simple={true}></Footer>
    </>
  )
}

export default NotFound;