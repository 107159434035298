import React, { useCallback, useEffect, useState } from 'react';
import * as queries from '../graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';
import Header from '../utils/Header'
import Footer from '../utils/Footer'
import { Button, Card, CardContent, CardMedia, Chip, Grid, makeStyles, TextField, Typography } from '@material-ui/core';
import { ConfirmationNumber } from '@material-ui/icons';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import StoreIcon from '@material-ui/icons/Store';
import deliveryMessage from '../utils/deliveryMessage';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    backgroundColor: '#fad206',
    '&:hover': {
      backgroundColor: '#FF8C00',
    },
    marginTop: 14,
  },
  inputwidth: {
    maxWidth: 70,
    justify: "flex-start",
    backgroundColor: "white",
    height: 22,
    margin: 10,
  },
  fontStyle: {
    color: '#ff8a00',
    backgroundColor: '#fff6d2',
    paddingTop: 65
  },
  chip: {
    backgroundColor: '#fff6d2',
  },
  media: {
    maxWidth: 200,
    maxHeight: 200,
  },
});

export default function Menus(props) {
  const classes = useStyles();

  /* ***********************************************
   * State
   * ************************************************/
  const [menus, setMenus] = useState([])
  const [fetchMenus, setFetchMenus] = useState([])

  /* ***********************************************
   *  Function 初期化
   * ************************************************/
  useEffect(useCallback(() => {
    API.graphql(graphqlOperation(queries.listMenus, {
      filter: { delivery: { eq: true } },
      limit: 9999
    }))
      .then(menus => {
        const orderMenus = menus.data.listMenus.items
          .sort((a, b) => {
            if (a.title < b.title) return -1;
            if (a.title > b.title) return 1;
            return 0;
          })
          .map(menu => {
            return { ...menu, orderCount: 0, message: '' }
          })
        setMenus(orderMenus.slice(0, 10))
        setFetchMenus(orderMenus)
      })
      .catch(err => {
        console.log('fetch menus error:', err);
      })
  }, []), [])

  /* ***********************************************
   *  Function 数量変更
   * ************************************************/
  const onChangeOrderCount = (e) => {
    e.persist();
    if (Number(e.target.value) >= 0) {
      const updateMenus = [...menus]
      const index = e.target.name
      const updateOrderCount = { ...menus[index], orderCount: Number(e.target.value) }
      updateMenus.splice(index, 1, updateOrderCount)
      setMenus(updateMenus)
    }
  }

  /* ***********************************************
   *  Function 読み込む
   * ************************************************/
  const concatMenus = (e) => {
    const menusCount = menus.length
    const updateMenus = menus.concat(fetchMenus.slice(menusCount, menusCount + 10))
    setMenus(updateMenus)
  }
  /* ***********************************************
   *  Function カートに追加
   * ************************************************/
  const addCart = (menu) => {
    if (menu.orderCount > 0) {
      const updateMenus = [...menus]
      const findMenu = updateMenus.find(item => item.id === menu.id)
      const index = updateMenus.indexOf(findMenu)
      const updateCart = { ...menus[index], message: 'カートに追加しました' }
      updateMenus.splice(index, 1, updateCart)

      setMenus(updateMenus)
      const cartMenus = [...props.state.cart.menus]
      cartMenus.push(menu)
      props.dispatch({ type: 'CART_UPDATE', key: 'menus', value: cartMenus })
      props.dispatch({ type: 'CART_UPDATE', key: 'isDelivery', value: false })
      props.dispatch({ type: 'CART_UPDATE', key: 'isTakeout', value: false })
      props.dispatch({ type: 'CART_UPDATE', key: 'isDelivery', value: false })
    }
  }

  return (
    <>
      <Header state={props.state} dispatch={props.dispatch} />
      <Typography className={classes.fontStyle} variant='h4'>
        {'デリバリーメニュー 一覧'}
      </Typography>
      <Grid container spacing={1}>

        <Grid item xs={12} >
          <Typography variant='body2' align='center' color='error'>
            {deliveryMessage.main}
          </Typography>
        </Grid>

        {menus.map((menu, index) => {
          return (
            <Grid item xs={12} md={6} key={index}>
              <Card variant="outlined" className={classes.root} id={menu.id}>
                <div className={classes.a}>
                  <CardContent >
                    <Typography variant="h5" component="h5" align="left">
                      <Chip
                        label={"チケット" + (menu.ticket) + "枚"}
                        className={classes.chip}
                        size="small"
                        icon={<ConfirmationNumber />}
                        variant="outlined" />
                    </Typography>
                    <Typography variant="h6" component="h6" align="left">
                      {menu.title}
                    </Typography>
                    <Typography variant="body2" align="left">
                      <StoreIcon />
                      {menu.store.name}
                    </Typography>
                    <Typography variant="body2" align="left">
                      {menu.description}
                    </Typography>
                  </CardContent>
                  {!menu.soldOut && !menu.deleted && props.state.isSignedIn &&
                    <div className={classes.root}>
                      <TextField
                        className={classes.inputwidth}
                        size="small"
                        name={String(index)}
                        value={menu.orderCount}
                        label="数量"
                        type="number"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={onChangeOrderCount}
                        variant="outlined"
                      />
                      <Button className={classes.button} startIcon={<AddShoppingCartIcon />}
                        onClick={() => addCart(menu)}>追加</Button>
                    </div>
                  }
                  {menu.soldOut && !menu.deleted &&
                    <Typography color="error" variant="h4" align="center">
                      {'SOLD OUT'}
                    </Typography>
                  }
                  {menu.deleted &&
                    <>
                      <Typography color="error" variant="body2" align="center">
                        {'イベント期間中のみ'}
                      </Typography>
                      <Typography color="error" variant="body2" align="center">
                        {'注文できます'}
                      </Typography>
                    </>
                  }
                  <Typography color="error">
                    {menu.message}
                  </Typography>
                </div>
                <CardMedia
                  className={classes.media}
                  component="img"
                  alt="Contemplative Reptile"
                  image={menu.image}
                  title="Contemplative Reptile"
                />
              </Card>
            </Grid>
          )
        })
        }
        <Grid item xs={12} key='more'>
          {menus.length === fetchMenus.length ?
            '' : <Button className={classes.button} onClick={concatMenus}>もっと見る</Button>
          }
        </Grid>
      </Grid >
      <Footer logo simple />
    </>
  )
}
