import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CardContent from '@material-ui/core/CardContent';
import Header from '../utils/Header'
import Footer from '../utils/Footer'
import { Grid, Typography } from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
    main: {
        backgroundColor: '#FFF6D2',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'left',
        },
        [theme.breakpoints.up('md')]: {
            textAlign: 'center',
        },
        [theme.breakpoints.up('lg')]: {
            textAlign: 'center',
        },
    },
    section: {
        width: "100%",
        backgroundColor: '#fff',
        height: "100%",
        borderRadius: "30px",
        paddingTop: '20px',
        paddingBottom: '20px',
        marginTop: '20px',
        marginBottom: '20px'
    },

    pos2: {
        color: '#4C1D00',
        fontSize: 14,
        textAlign: 'left',
        // marginTop: 20,
        marginBottom: 20
    },
    pos3: {
        color: '#4C1D00',
        fontWeight: 'bold',
        fontSize: 16,
        textAlign: 'right',
        // marginTop: 20,
        marginBottom: 20
    },
    menuText2: {
        color: '#ff8a00',
        fontSize: 18,
        marginTop: 20,
        marginLeft: 20,
        fontWeight: 'bold',
        textAlign: 'left',
    },
    pos4: {
        color: '#4C1D00',
        fontSize: 14,
        fontWeight: 'bold',
        textDecoration: 'underline',
        paddingBottom: '20px',

    },
    menuText: {
        color: '#4C1D00',
        fontSize: 16,
        margin: 10,
        fontWeight: 'bold',
        textAlign: 'left',
    },
    imgSize: {
        width: '100%',
        backgroundColor: '#fff',
        display: 'block',
        textAlign: 'center',
        marginBottom: 20
    },
    main2: {
        backgroundColor: '#fff6d2',
        color: '#ff8a00',
        margin: 0,
        marginBottom: 0,
        paddingTop: 60
    },
    main3: {
        backgroundColor: '#fff6d2',
        margin: 0,
        marginBottom: 0,
    },
    paypalLink: {
        display: 'block',
        padding: 10,
        backgroundColor: '#FAD206',
        borderRadius: "50px",
        marginTop: 10,
        marginBottom: 30
    },
    attention: {
        display: 'block',
        padding: 10,
        backgroundColor: '#FAD206',
        borderRadius: "20px",
        // marginTop: 10,
        marginBottom: 30,
        fontWeight: 'bold',
        textAlign: 'left'
    },
    strong: {
        color: '#ff8a00'
    },
    linkColor: {
        color: '#ff8a00',
    },

}));




export default function Home(props) {
    const classes = useStyles();
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <>
            <Header state={props.state} className={classes.menu} dispatch={props.dispatch}></Header>
            <div className={classes.main3}>
                <Grid className={classes.main2} >
                    <h3>うまく動作しない時の対処</h3>
                </Grid>
                {/* <Container className={classes.main} >
                    <div className={classes.section}>
                        <Grid container spacing={0} alignItems="center" justify="center">
                            <Grid item xs={11} lg={5} >
                                <h1 className={classes.menuText} >代表的なエラー</h1>
                            </Grid>
                        </Grid>
                        <Grid container spacing={0} alignItems="center" justify="center">
                            <Grid item xs={11} lg={5} >
                                <div>
                                    <CardContent>
                                        <Typography className={classes.pos2} >
                                            メニューの中に「寄付」という項目がありますので、応援したい店舗を選択していただき、ほかの注文方法と同様にご注文ください。
                                        </Typography>
                                    </CardContent>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </Container> */}
                <Container className={classes.main} >
                    <div className={classes.section}>
                        {/* <Grid container spacing={0} alignItems="center" justify="center">
                            <Grid item xs={11} lg={5} >
                                <h1 className={classes.menuText} >うまく動作しないときの対処</h1>
                            </Grid>
                        </Grid> */}
                        <Grid container spacing={0} alignItems="center" justify="center">
                            <Grid item xs={11} lg={5} >
                                <div>
                                    <CardContent>
                                        <Typography className={classes.pos2} >
                                            safari（iPhone）<br />
                                        1. 本体の「設定」をタップ(歯車のような絵のアイコン)<br />
                                        2. safariをタップ<br />
                                        3. プライバシーとセキュリティにあるCookieブロック→「訪問したWebサイトを許可」もしくは「常に許可」を選択<br />
                                        4. 詳細→JavaScriptをオン<br />
                                        5. 履歴とWebサイトデータを消去<br />
                                        6. スマートフォンを再起動
                                    </Typography>
                                        <Typography className={classes.pos2} >
                                            Google Chrome（Android）<br />
                                        1. ブラウザのツールバーにあるChromeメニュー（︙）をクリック<br />
                                        2. 設定をタップ<br />
                                        3. プライバシーをタップ<br />
                                        4. 閲覧履歴データを消去するをタップ<br />
                                        5. 【Cookieとサイトデータ】/【キャッシュされた画像とファイル】にチェックを入れ、データを消去をタップ<br />
                                        6. スマートフォンを再起動
                                    </Typography>
                                        <Typography className={classes.pos2} >
                                            Internet Explorer（PC）<br />
                                        1. 「ツール」(右上、歯車のような絵のアイコン)<br />
                                        2. インターネットオプション<br />
                                        3. 「全般」のタブ　閲覧の履歴を「削除」
                                    </Typography>
                                        <Typography className={classes.pos2} >
                                            Google Chrome（PC）<br />
                                        1. ブラウザのツールバーにあるChromeメニュー（Ξ）をクリック<br />
                                        2. ツールを選択<br />
                                        3. 【閲覧履歴を消去する】をクリック<br />
                                        4. 次の期間のアイテムを消去」を【すべて】を選択<br />
                                        5. 表示されるダイアログで【Cookieと他のサイトやプラグインのデータを削除する】/【キャッシュを選択】を選択<br />
                                        6. 【閲覧履歴データを削除する】をクリック
                                    </Typography>
                                    </CardContent>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </Container>

                <Typography className={classes.pos3} >
                    <Link className={classes.linkColor} to="/howto"> 利用方法もくじに戻る＞</Link>
                </Typography>


                <Grid>
                    <Footer state={props.state} ></Footer>
                </Grid>
            </div>
        </>
    )
}

