import React, { useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { Redirect, useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import * as mutations from '../graphql/mutations'
import Header from '../utils/Header'
import Footer from '../utils/Footer'
import { Button, Card, CardContent, CardMedia, Checkbox, Chip, Container, Grid, makeStyles, Paper, TextField, Typography } from '@material-ui/core';
import { ConfirmationNumber, LocalShipping, Restaurant, ShoppingBasket } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import StoreIcon from '@material-ui/icons/Store';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import isEmpty from '../utils/isEmpty';
import deliveryMessage from '../utils/deliveryMessage';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'white',
    color: '#ff8a00',
    marginTop: 10,
  },
  title: {
    color: '#ff8a00',
    backgroundColor: '#fff6d2',
    paddingTop: 80
  },
  cardItems: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
  },
  cover: {
    width: 150,
    height: 150,
    objectFit: 'cover',
    backgroundColor: 'white',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    padding: theme.spacing(1),
  },
  chipColor: {
    backgroundColor: '#fff6d2',
  },
  inputwidth: {
    maxWidth: 70,
    justify: "flex-start",
  },
  warning: {
    backgroundColor: '#FAD206',
    padding: theme.spacing(1),
  },
  linkColor: {
    color: '#FF8A00',
    fontWeight: 'bold',
  },
  noticeColor: {
    color: '#E8140C',
    fontWeight: 'bold',
  },
}));
const CustomButton = (props) => {
  const customTheme = theme => ({
    root: {
      backgroundColor: '#fad206',
      color: '#4c1d00',
      fontWeight: 'bold',
      paddingLeft: '50px',
      paddingRight: '50px',
      minHeight: 80,
      '&:hover': {
        backgroundColor: '#ff8a00',
      },
    },
  })
  const ComponentName = withStyles(customTheme)(Button)
  return <ComponentName {...props} />
}

const deliveryMenu = {
  id: '',
  title: 'デリバリー',
  description: '',
  image: 'https://images.suku-yell.com/delivery.jpg',
  ticket: 1,
  storeID: '',
  soldOut: false,
  delivery: true,
  takeout: false,
  eatin: false,
  deleted: false,
  updatedAt: '',
  createdAt: '',
  store: { name: 'まちやを救エール実行委員会' },
  orderCount: 1,
  message: ''
}

export default function Cart(props) {

  const [messages, setMessages] = useState(['カートに商品が入っていません'])
  const [checkboxMessages, setCheckboxMessages] = useState()
  const [attentions, setAttention] = useState([])
  const [orderMenus, setOrderMenus] = useState([])
  const [errorTextField, setErrorTextField] = useState({ phone: { error: false, text: '' }, deliveryAddress: { error: false, text: '' } })

  const classes = useStyles();
  const history = useHistory();
  const handleLink = (path) => history.push(path);

  /* ***********************************************
   *  Function 
   * ************************************************/
  const onChange = (e) => {
    e.persist();
    const cartMenusFilter = [...props.state.cart.menus.filter(m => m.title !== 'デリバリー')]
    props.dispatch({ type: 'CART_UPDATE', key: 'menus', value: cartMenusFilter })
    setCheckboxMessages('')
    const myCart = props.state.cart
    const enableMyCartMenus = cartMenusFilter.filter(m => m.orderCount > 0)
    if (enableMyCartMenus.length === 0) return null

    if (e.target.name === 'isDelivery') {
      if (enableMyCartMenus.length === enableMyCartMenus.filter(m => m.delivery).length) {
        if (!myCart.isDelivery) {
          const cartMenus = [...props.state.cart.menus.filter(m => m.title !== 'デリバリー')]
          cartMenus.push({ ...deliveryMenu, id: uuidv4() })
          props.dispatch({ type: 'CART_UPDATE', key: 'menus', value: cartMenus })
        }
        props.dispatch({ type: 'CART_UPDATE', key: [e.target.name], value: !myCart.isDelivery })
        props.dispatch({ type: 'CART_UPDATE', key: 'isTakeout', value: false })
        props.dispatch({ type: 'CART_UPDATE', key: 'isEatin', value: false })
      } else {
        props.dispatch({ type: 'CART_UPDATE', key: 'isDelivery', value: false })
        setCheckboxMessages('デリバリーできない商品が含まれています')
      }
    } else if (e.target.name === 'isTakeout') {
      if (enableMyCartMenus.length !== enableMyCartMenus.filter(m => m.takeout).length) {
        props.dispatch({ type: 'CART_UPDATE', key: 'isDelivery', value: false })
        setCheckboxMessages('テイクアウトできない商品が含まれています')
      } else if (enableMyCartMenus.length !== enableMyCartMenus.filter(m => m.store.name === enableMyCartMenus[0].store.name).length) {
        props.dispatch({ type: 'CART_UPDATE', key: 'isDelivery', value: false })
        setCheckboxMessages('テイクアウトでは同一店舗の商品のみ注文いただけます')
      } else {
        props.dispatch({ type: 'CART_UPDATE', key: [e.target.name], value: !myCart.isTakeout })
        props.dispatch({ type: 'CART_UPDATE', key: 'isDelivery', value: false })
        props.dispatch({ type: 'CART_UPDATE', key: 'isEatin', value: false })
      }
    } else if (e.target.name === 'isEatin') {
      if (enableMyCartMenus.length !== enableMyCartMenus.filter(m => m.eatin).length) {
        props.dispatch({ type: 'CART_UPDATE', key: 'isDelivery', value: false })
        setCheckboxMessages('イートインできない商品が含まれています')
      } else if (enableMyCartMenus.length !== enableMyCartMenus.filter(m => m.store.name === enableMyCartMenus[0].store.name).length) {
        props.dispatch({ type: 'CART_UPDATE', key: 'isDelivery', value: false })
        setCheckboxMessages('イートインでは同一店舗の商品のみ注文いただけます')
      } else {
        props.dispatch({ type: 'CART_UPDATE', key: [e.target.name], value: !myCart.isEatin })
        props.dispatch({ type: 'CART_UPDATE', key: 'isTakeout', value: false })
        props.dispatch({ type: 'CART_UPDATE', key: 'isDelivery', value: false })
      }
    }
  }

  /* ***********************************************
   *  Function 
   * ************************************************/
  const onChangeDeliveryInfo = (e) => {
    e.persist();
    props.dispatch({ type: 'CART_UPDATE', key: [e.target.name], value: e.target.value })
  }
  /* ***********************************************
   *  Function 
   * ************************************************/
  const onChangeOrderCount = (e) => {
    e.persist();
    setCheckboxMessages('')
    props.dispatch({ type: 'CART_UPDATE', key: 'isDelivery', value: false })
    props.dispatch({ type: 'CART_UPDATE', key: 'isTakeout', value: false })
    props.dispatch({ type: 'CART_UPDATE', key: 'isDelivery', value: false })
    if (Number(e.target.value) >= 0) {
      const updateMenus = [...props.state.cart.menus]
      const index = e.target.name
      const updateOrderCount = { ...props.state.cart.menus[index], orderCount: Number(e.target.value) }
      if (updateOrderCount.title === 'デリバリー') return null
      updateMenus.splice(index, 1, updateOrderCount)
      props.dispatch({ type: 'CART_UPDATE', key: 'menus', value: updateMenus })
    }
  }

  /* ***********************************************
   *  Function 
   * ************************************************/
  const sumTicket = (prev, current) => {
    return Number(prev) + Number(current.orderCount) * Number(current.ticket)
  }

  /* ***********************************************
   *  Function 
   * ************************************************/
  const order = async () => {
    const cart = props.state.cart
    if (cart.isDelivery) {
      const phoneEmpty = { error: false, text: '' }
      const deliveryAddressEmpty = { error: false, text: '' }
      if (isEmpty(cart.phone)) {
        phoneEmpty.error = true
        phoneEmpty.text = '電話番号を入力してください'
      }
      if (isEmpty(cart.deliveryAddress)) {
        deliveryAddressEmpty.error = true
        deliveryAddressEmpty.text = '配送先住所を入力してください'
      }

      if (phoneEmpty.error === true || deliveryAddressEmpty.error === true) {
        setErrorTextField({ phone: phoneEmpty, deliveryAddress: deliveryAddressEmpty })
        return null
      }
    }

    const afterTicket = cart.menus.reduce(sumTicket, 0)

    const orderData = {
      appuser: props.state.cognitoUser.username,
      status: '注文',
      key: cart.id,
      ticket: afterTicket,
      isDelivery: cart.isDelivery,
      isTakeout: cart.isTakeout,
      isEatin: cart.isEatin,
      consumed: false,
      phone: cart.phone,
      address: cart.deliveryAddress,
      description: cart.description,
    }

    if (cart.isEatin) {
      orderData.status = '準備完了'
    }

    try {
      const orderRes = await API.graphql(graphqlOperation(mutations.createOrder, { input: orderData }))
      for (const menu of cart.menus.filter(m => m.orderCount > 0)) {
        let orderMenuData = {
          store: menu.store.name,
          menu: menu.title,
          ticket: menu.ticket,
          orderCount: menu.orderCount,
          status: orderData.status,
          description: '',
          orderID: orderRes.data.createOrder.id,
        }
        const orderMenuRes = await API.graphql(graphqlOperation(mutations.createOrderMenu, { input: orderMenuData }))
        setOrderMenus([...orderMenus, orderMenuRes.data.createOrderMenu.title])
      }
      const msgs = [
        '注文を完了しました。',
        '注文履歴をスタッフに提示して、受取ボタンを押してください。',
      ]
      setMessages(msgs)
      const attention = [
        '※スタッフに画面を提示するまで、受取ボタンを押さないでください。',
        'ご利用いただけない場合があります。',
      ]
      setAttention(attention)
      const resetCart = { id: uuidv4(), menus: [], isDelivery: false, isTakeout: false, isEatin: false, deliveryAddress: '', phone: '', description: '' }
      props.dispatch({ type: 'UPDATE', key: 'cart', value: resetCart })
    } catch (error) {
      console.log("Order object input error:", error)
    }

  }

  /* ***********************************************
   *  Function 
   * ************************************************/
  const orderEatin = async () => {
    const cart = props.state.cart

    const payTicket = cart.menus.reduce(sumTicket, 0)
    const consumedTicket = Number(props.state.dynamoUser.ticketPossession)

    const userRes = await API.graphql(graphqlOperation(mutations.updateUser, {
      input: {
        id: props.state.dynamoUser.id,
        ticketPossession: consumedTicket + payTicket,
      }
    }))
    props.dispatch({ type: 'UPDATE', key: 'dynamoUser', value: userRes.data.updateUser })

    const orderData = {
      appuser: props.state.cognitoUser.username,
      status: '受渡完了',
      key: cart.id,
      ticket: payTicket,
      isDelivery: cart.isDelivery,
      isTakeout: cart.isTakeout,
      isEatin: cart.isEatin,
      consumed: true,
      phone: cart.phone,
      address: cart.deliveryAddress,
      description: cart.description,
    }

    try {
      const orderRes = await API.graphql(graphqlOperation(mutations.createOrder, { input: orderData }))
      for (const menu of cart.menus.filter(m => m.orderCount > 0)) {
        let orderMenuData = {
          store: menu.store.name,
          menu: menu.title,
          ticket: menu.ticket,
          orderCount: menu.orderCount,
          status: orderData.status,
          description: '',
          orderID: orderRes.data.createOrder.id,
        }
        const orderMenuRes = await API.graphql(graphqlOperation(mutations.createOrderMenu, { input: orderMenuData }))
        setOrderMenus([...orderMenus, orderMenuRes.data.createOrderMenu.title])
      }
      const msgs = [
        'ご注文が完了して、チケットを消費しました。',
        '',
      ]
      setMessages(msgs)
      const attention = [
        '※店員またはスタッフから注文したメニューをお受け取りください。',
      ]
      setAttention(attention)
      const resetCart = { id: uuidv4(), menus: [], isDelivery: false, isTakeout: false, isEatin: false, deliveryAddress: '', phone: '', description: '' }
      props.dispatch({ type: 'UPDATE', key: 'cart', value: resetCart })
    } catch (error) {
      console.log("Order object input error:", error)
    }

  }

  /* ***********************************************
   *  Function 
   * ************************************************/
  const OrderButton = () => {
    const haveTicket = props.state.dynamoUser.purchasedTickets - props.state.dynamoUser.ticketPossession
    const afterTicket = props.state.cart.menus.reduce(sumTicket, 0)

    if (afterTicket === 0) return null
    if (!props.state.cart.isDelivery && !props.state.cart.isTakeout && !props.state.cart.isEatin) return null
    if (afterTicket > haveTicket) {
      return (
        <>
          <CustomButton onClick={() => handleLink('/ticket')} >チケットを購入する　＞</CustomButton>
        </>
      )
    }
    if (props.state.cart.isEatin && props.state.cart.menus[0].store.ventilationType === 'eatin') {
      return (
        <CustomButton onClick={orderEatin}>チケットを消費する　＞</CustomButton>
      )
    }
    return (
      <>
        <CustomButton onClick={order}>注文する　＞</CustomButton>
      </>
    )
  }

  if (!props.state.isSignedIn) {
    return (
      <Redirect to={'/signin'} />
    )
  } else if (props.state.cart.menus.length === 0) {
    return (
      <>
        <Header state={props.state} dispatch={props.dispatch} />
        <Typography className={classes.title} variant='h4'> カート </Typography>
        <div className={classes.root}>
          <Container maxWidth="md">
            <Grid container spacing={2}>
              {messages.map((message, index) => {
                return (
                  <Grid item xs={12} key={index}>
                    <Typography variant="h6">{message}</Typography>
                  </Grid>
                )
              })}
              {attentions.map((attention, index) => {
                return (
                  <Grid item xs={12} key={index}>
                    <Typography variant="h6" color="error">{attention}</Typography>
                  </Grid>
                )
              })}
            </Grid>
          </Container>
        </div>
        <Footer />
      </>
    )
  } else {
    return (
      <>
        <Header state={props.state} dispatch={props.dispatch} />
        <Typography className={classes.title} variant='h4'> カート </Typography>
        <div className={classes.root}>
          <Container maxWidth="md">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6" color='textPrimary'>
                  {'チケット消費枚数：'}
                  <Typography variant="h3" component="span" children>
                    {props.state.cart.menus.reduce(sumTicket, 0)}
                    {' / '}
                  </Typography>
                  {props.state.dynamoUser.purchasedTickets - props.state.dynamoUser.ticketPossession}
                  <Typography variant="body1" component="span" children>
                    {'枚'}
                    <ConfirmationNumberIcon style={{ color: '#ff8a00' }} />
                  </Typography>
                </Typography>
              </Grid>
              {props.state.cart.menus.map((menu, index) => {
                return (
                  <Grid item xs={12} md={6} key={menu.id}>
                    <Card id={menu.id}>
                      <div className={classes.cardItems}>
                        <div className={classes.details}>
                          <CardContent className={classes.content}>
                            <Typography component="h6" variant="h6">
                              {menu.title}
                            </Typography>
                            <Typography variant="subtitle1" color="textSecondary">
                              <StoreIcon />
                              {menu.store.name}
                            </Typography>
                            <TextField id={menu.id} name={String(index)}
                              type="number" label="数量" size="small"
                              value={menu.orderCount}
                              onChange={onChangeOrderCount}
                              variant="outlined"
                            />
                          </CardContent>
                        </div>
                        <CardMedia className={classes.cover} image={menu.image} title={menu.title} />
                      </div>
                      <div className={classes.chips}>
                        <Chip className={classes.chipColor} label={"チケット" + (menu.ticket) + "枚"} size="small" icon={<ConfirmationNumber />} variant="outlined" />
                        {menu.delivery && <Chip className={classes.chipColor} label={'デリバリー可'} size="small" icon={<LocalShipping />} variant="outlined" />}
                        {menu.takeout && <Chip className={classes.chipColor} label={'テイクアウト可'} size="small" icon={<ShoppingBasket />} variant="outlined" />}
                        {menu.eatin && <Chip className={classes.chipColor} label={'イートイン可'} size="small" icon={<Restaurant />} variant="outlined" />}
                      </div>
                    </Card>
                  </Grid>
                )
              })
              }
              <Grid item xs={12}>
                <Typography variant="body2" color='error'>
                  {checkboxMessages}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="h5" color='textPrimary'>
                  <Checkbox name="isDelivery" checked={props.state.cart.isDelivery} onChange={onChange} /> {'デリバリー　'}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="h5" color='textPrimary'>
                  <Checkbox name="isTakeout" checked={props.state.cart.isTakeout} onChange={onChange} /> {'テイクアウト'}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="h5" color='textPrimary'>
                  <Checkbox name="isEatin" checked={props.state.cart.isEatin} onChange={onChange} /> {'イートイン　'}
                </Typography>
              </Grid>
              {props.state.cart.isDelivery &&
                <>
                  <Grid item xs={12} >
                    <Typography variant='body2' align='center' color='error'>
                      {deliveryMessage.main}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      error={errorTextField.phone.error}
                      helperText={errorTextField.phone.text}
                      variant="outlined"
                      id="phone"
                      label="ご連絡がとれる電話番号"
                      name="phone"
                      onChange={onChangeDeliveryInfo}
                      fullWidth
                      justify="center"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      error={errorTextField.deliveryAddress.error}
                      helperText={errorTextField.deliveryAddress.text}
                      variant="outlined"
                      id="deliveryAddress"
                      label="配送先住所(建物名まで入力ください)"
                      name="deliveryAddress"
                      fullWidth
                      onChange={onChangeDeliveryInfo}
                      justify="center"
                    />
                  </Grid>
                </>
              }
              <Grid item xs={12}>
                <Paper className={classes.warning}>
                  <Typography variant="h6" color='textPrimary'>
                    {'※注意※'}
                  </Typography>
                  <div style={{ margin: 8 }}>
                    <Typography variant="body1" color='textPrimary' align='left'>
                      {'■イートインについて'}
                    </Typography>
                    <Typography variant="body2" color='textPrimary' align='left' className={classes.noticeColor}>
                      {'ご注文は必ず、店内にて行ってください。※店内以外でのご注文は無効となります。'}
                    </Typography>
                    <Typography variant="body2" color='textPrimary' align='left'>
                      {'店内にて注文履歴にある該当の注文を店舗の店員に提示し、店員に提示した後に「注文する」ボタンを押して下さい。'}
                    </Typography>
                  </div>
                  <div style={{ margin: 8 }}>
                    <Typography variant="body1" color='textPrimary' align='left'>
                      {'■デリバリーについて'}
                    </Typography>
                    <Typography variant="body2" color='textPrimary' align='left'>
                      {'配達エリアは荒川区内の一部に限定されています。'}
                      <Link className={classes.linkColor} to="/map">コチラ</Link>
                      {'からご確認ください。'}
                    </Typography>
                  </div>
                  <div style={{ margin: 8 }}>
                    <Typography variant="body1" color='textPrimary' align='left'>
                      {'■テイクアウトについて'}
                    </Typography>
                    <Typography variant="body2" color='textPrimary' align='left' className={classes.noticeColor} >
                      {'ご注文後、30分後を目安にお店までお越しください。時間指定は出来ませんのでご注意ください。'}
                    </Typography>
                  </div>
                  <div style={{ margin: 8 }}>
                    <Typography variant="body1" color='textPrimary' align='left'>
                      {'■寄付について'}
                    </Typography>
                    <Typography variant="body2" color='textPrimary' align='left'>
                      {'上記の選択（デリバリー、テイクアウト、イートイン）で「イートイン」を選択し、注文すると寄付ができます。'}
                    </Typography>
                  </div>
                  <div style={{ margin: 8 }}>
                    <Typography variant="body1" color='textPrimary' align='left'>
                      {'■異なる店舗の商品が含まれている場合、'}
                    </Typography>
                    <Typography variant="body2" color='textPrimary' align='left'>
                      {'イートイン、テイクアウトを選択できません。'}
                      {'その際は利用しない店舗の商品の数量を「０」にして注文してください。'}
                      {'数量を「０」にした商品は注文されませんので、もう一度、新たにご注文をお願いいたします。'}
                    </Typography>
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <OrderButton />
              </Grid>
            </Grid>
          </Container>
        </div>
        <Footer simple logo />
      </>
    )
  }
}
