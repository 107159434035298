import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CardContent from '@material-ui/core/CardContent';
import Header from '../utils/Header'
import Footer from '../utils/Footer'
import { Grid, Typography } from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
    main: {
        backgroundColor: '#FFF6D2',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'left',
        },
        [theme.breakpoints.up('md')]: {
            textAlign: 'center',
        },
        [theme.breakpoints.up('lg')]: {
            textAlign: 'center',
        },
    },
    section: {
        width: "100%",
        backgroundColor: '#fff',
        height: "100%",
        borderRadius: "30px",
        paddingTop: '20px',
        paddingBottom: '20px',
        marginTop: '20px',
        marginBottom: '20px'
    },

    pos2: {
        color: '#4C1D00',
        fontSize: 14,
        textAlign: 'left',
        marginTop: 20,
        marginBottom: 20
    },
    pos3: {
        color: '#4C1D00',
        fontWeight: 'bold',
        fontSize: 16,
        textAlign: 'right',
        marginTop: 20,
        marginBottom: 20
    },
    menuText2: {
        color: '#ff8a00',
        fontSize: 18,
        marginTop: 20,
        marginLeft: 20,
        fontWeight: 'bold',
        textAlign: 'left',
    },
    pos4: {
        color: '#4C1D00',
        fontSize: 14,
        fontWeight: 'bold',
        textDecoration: 'underline',
        paddingBottom: '20px',

    },
    menuText: {
        color: '#4C1D00',
        fontSize: 16,
        margin: 10,
        fontWeight: 'bold',
        textAlign: 'left',
    },
    imgSize: {
        width: '100%',
        backgroundColor: '#fff',
        display: 'block',
        textAlign: 'center',
        marginBottom: 20
    },
    main2: {
        backgroundColor: '#fff6d2',
        color: '#ff8a00',
        margin: 0,
        marginBottom: 0,
        paddingTop: 60
    },
    main3: {
        backgroundColor: '#fff6d2',
        margin: 0,
        marginBottom: 0,
    },
    paypalLink: {
        display: 'block',
        padding: 10,
        backgroundColor: '#FAD206',
        borderRadius: "50px",
        marginTop: 10,
        marginBottom: 30
    },
    attention: {
        display: 'block',
        padding: 10,
        backgroundColor: '#FAD206',
        borderRadius: "20px",
        // marginTop: 10,
        marginBottom: 30,
        fontWeight: 'bold',
        textAlign: 'left'
    },
    strong: {
        color: '#ff8a00'
    },
    linkColor: {
        color: '#ff8a00',
    },

}));




export default function Home(props) {
    const classes = useStyles();
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <>
            <Header state={props.state} className={classes.menu} dispatch={props.dispatch}></Header>
            <div className={classes.main3}>
                <Grid className={classes.main2} >
                    <h2>チケット購入について</h2>
                </Grid>
                <Container className={classes.main} >


                    <div className={classes.section}>


                        {/* <Grid container spacing={0} alignItems="center" justify="center">
                            <Grid item xs={11} lg={5} >
                                <h1 className={classes.menuText} >チケットの購入について</h1>
                            </Grid>
                        </Grid> */}
                        <Grid container spacing={0} alignItems="center" justify="center">
                            <Grid item xs={11} lg={5} >
                                <div>
                                    <CardContent>

                                        <Typography className={classes.pos2} >
                                            下記からページを移動しご利用ください。
                                        </Typography>
                                        <img className={classes.imgSize} alt=''
                                            src="https://images.suku-yell.com/cap/cap_buy1.png" ></img>

                                        <Typography className={classes.pos2} >
                                            チケット購入画面で、購入希望枚数を入力し「PayPalで支払い」を選択すると、PayPal決済画面に移動します。
                                        </Typography>
                                        <img className={classes.imgSize} alt=''
                                            src="https://images.suku-yell.com/cap/cap_buy2.png" ></img>

                                        <Typography className={classes.pos2} >
                                            Paypalアカウントをお持ちの方はアドレスまたは電話番号を入力してください。
                                            </Typography>
                                        <img className={classes.imgSize} alt=''
                                            src="https://images.suku-yell.com/cap/cap_buy3.png" ></img>

                                        <Typography className={classes.pos2} >
                                            パスワードを入力し、支払方法選択画面に移動し、決済を完了してください。
                                            </Typography>
                                        <img className={classes.imgSize} alt=''
                                            src="https://images.suku-yell.com/cap/cap_buy4.png" ></img>
                                        <img className={classes.imgSize} alt=''
                                            src="https://images.suku-yell.com/cap/cap_buy5.png" ></img>

                                        <Typography className={classes.pos2} >
                                            まちやを救エールの画面に戻り、決済完了です。
                                            </Typography>
                                        <img className={classes.imgSize} alt=''
                                            src="https://images.suku-yell.com/cap/cap_buy6.png" ></img>

                                        <Typography className={classes.pos2} >
                                            Paypalのアカウントをお持ちでない方はアカウントを新規開設してください。
                                            </Typography>
                                        <img className={classes.imgSize} alt=''
                                            src="https://images.suku-yell.com/cap/cap_buy7.png" ></img>
                                        <img className={classes.imgSize} alt=''
                                            src="https://images.suku-yell.com/cap/cap_buy7-1.png" ></img>
                                        <img className={classes.imgSize} alt=''
                                            src="https://images.suku-yell.com/cap/cap_buy7-2.png" ></img>

                                        <Typography className={classes.pos2} >
                                            そのあと、銀行とクレジットカード情報を登録します。
                                            </Typography>
                                        <img className={classes.imgSize} alt=''
                                            src="https://images.suku-yell.com/cap/cap_buy8.png" ></img>
                                        <img className={classes.imgSize} alt=''
                                            src="https://images.suku-yell.com/cap/cap_buy8-1.png" ></img>

                                        <Typography className={classes.pos2} >
                                            これで、アカウント開設が完了し、決済方法選択画面に移動します。先ほど登録したクレジットカード情報を選択し、今すぐ支払うを選択してください。<br />
                                            まちやを救エールの画面に戻り、決済完了です。
                                            </Typography>
                                        <img className={classes.imgSize} alt=''
                                            src="https://images.suku-yell.com/cap/cap_buy9.png" ></img>
                                        <img className={classes.imgSize} alt=''
                                            src="https://images.suku-yell.com/cap/cap_buy6.png" ></img>



                                    </CardContent>
                                </div>

                            </Grid>
                        </Grid>


                    </div>



                </Container>

                <Typography className={classes.pos3} >
                    <Link className={classes.linkColor} to="/howto"> 利用方法もくじに戻る＞</Link>
                </Typography>


                <Grid>
                    <Footer state={props.state} ></Footer>
                </Grid>
            </div>
        </>
    )
}

