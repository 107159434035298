// Elasticsearch delete command
// curl -XDELETE https://search-amplify-elasti-32oe73iqngjy-3dc4ebgd2p75cxjzwy42ri5gbe.ap-northeast-1.es.amazonaws.com/store
import React, { useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
// import { Redirect } from 'react-router-dom';

import * as mutations from '../graphql/mutations'
import * as queries from '../graphql/queries';

import { useParams } from 'react-router-dom'

import Header from '../utils/Header'
import Footer from '../utils/Footer'

const initalShop = {
  name: '店舗名',
  description: '説明',
  url: 'url',
  image: 'image-url',
  address: '住所',
  tel: '電話番号',
  googleMap: 'map url',
  businessHours: '営業時間',
  ventilationType: '常時換気中など',
  ventilationAt: new Date(),
  crowded: '空いてる',
}

const initalMenu = {
  title: 'めにゅー',
  description: '',
  image: '',
  ticket: 5,
  soldOut: false,
  delivery: false,
  takeout: false,
  eatin: false,
  deleted: false,
  storeID: '',
}

export default function ShopTool(props) {

  const [shop, setShop] = useState(initalShop)
  const [menu, setMenu] = useState(initalMenu)

  let { key } = useParams()

  if (!Object.keys(props.state.dynamoUser).length) return null
  if (props.state.dynamoUser.authority === 'User') return null
  if (key !== 'shop') return null

  const onChangeShop = (e) => {
    e.persist();
    setShop(() => ({ ...shop, [e.target.name]: e.target.value }))
  }
  const onChangeMenu = (e) => {
    e.persist();
    setMenu(() => ({ ...menu, [e.target.name]: e.target.value }))
  }
  const onSubmit = async () => {
    console.log(shop)
    console.log(menu)
    try {
      const createShop = await API.graphql(graphqlOperation(mutations.createStore, { input: shop }))
      console.log(createShop.data.createStore)

      const createMenu = await API.graphql(graphqlOperation(mutations.createMenu, { input: { ...menu, storeID: createShop.data.createStore.id } }))
      await API.graphql(graphqlOperation(mutations.createMenu, { input: { ...menu, storeID: createShop.data.createStore.id } }))
      await API.graphql(graphqlOperation(mutations.createMenu, { input: { ...menu, storeID: createShop.data.createStore.id } }))
      await API.graphql(graphqlOperation(mutations.createMenu, { input: { ...menu, storeID: createShop.data.createStore.id } }))
      await API.graphql(graphqlOperation(mutations.createMenu, { input: { ...menu, storeID: createShop.data.createStore.id } }))
      await API.graphql(graphqlOperation(mutations.createMenu, { input: { ...menu, storeID: createShop.data.createStore.id } }))
      console.log(createMenu)

    } catch (error) {
      console.log("dyname error:", error)
    }
  }

  const onClickShop = async () => {
    const dynamo = await API.graphql(graphqlOperation(queries.listStores));
    console.log(dynamo)

  }

  const onClickMenu = async () => {
    const dynamo = await API.graphql(graphqlOperation(queries.listMenus));
    console.log(dynamo)

  }

  return (
    <>
      <Header state={props.state} dispatch={props.dispatch}></Header>
      <h3>店舗・メニュー作成</h3>
      <input name="name" type='text' onChange={onChangeShop} placeholder='店舗名' />
      <input name="description" type='text' onChange={onChangeShop} placeholder='店舗説明' />
      <input name="url" type='text' onChange={onChangeShop} placeholder='店舗URL' />
      <input name="image" type='text' onChange={onChangeShop} placeholder='店舗画像URL' />
      <input name="address" type='text' onChange={onChangeShop} placeholder='店舗住所' />
      <input name="tel" type='text' onChange={onChangeShop} placeholder='店舗電話番号' />
      <input name="googleMap" type='text' onChange={onChangeShop} placeholder='店舗地図URL' />
      <input name="businessHours" type='text' onChange={onChangeShop} placeholder='店舗営業時間' />
      <input name="ventilationType" type='text' onChange={onChangeShop} placeholder='換気タイプ' />
      <input name="crowded" type='text' onChange={onChangeShop} placeholder='混雑度' />

      <input name="title" type='text' onChange={onChangeMenu} placeholder='メニュータイトル' />
      <input name="description" type='text' onChange={onChangeMenu} placeholder='メニュー説明' />
      <input name="image" type='text' onChange={onChangeMenu} placeholder='メニュー画像URL' />
      <input name="ticket" type='number' onChange={onChangeMenu} placeholder='必要チケット枚数' />
      <button onClick={onSubmit}>create</button>

      <button onClick={onClickShop}>fetch shop</button>
      <button onClick={onClickMenu}>fetch menu</button>
      <p>{props.state.dynamoUser.authority}</p>
      <Footer state={props.state}></Footer>
    </>
  )
}
