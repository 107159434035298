import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ClearIcon from '@material-ui/icons/Clear';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Auth } from 'aws-amplify';
import SettingsIcon from '@material-ui/icons/Settings';
import HomeIcon from '@material-ui/icons/Home';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import ListAltIcon from '@material-ui/icons/ListAlt';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import { HashLink } from 'react-router-hash-link';

const useStyles = makeStyles({
  main: {
    backgroundColor: '#ff8a00',
  },
  root: {
    minWidth: 275,
    backgroundColor: '#ff8a00',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  color: {
    backgroundColor: '#999999',
    margin: 4,
  },
});



export default function DrawerMenu(props) {
  const classes = useStyles();
  const history = useHistory();
  const handleLink = (path) => history.push(path);


  return (
    <div className={classes.main}>
      <Button onClick={props.toggleDrawer(false)} >
        <ClearIcon />
      </Button>
      <List >
        <ListItem button key='about' onClick={() => {
          props.dispatch({ type: "ABOUT" });
          handleLink('/');
          window.scrollTo(100, 500);
        }}>
          <ListItemIcon><HomeIcon /></ListItemIcon>
          <ListItemText primary='イベントについて'></ListItemText>
        </ListItem>
        <ListItem button key='about' onClick={() => {
          props.dispatch({ type: "REPORT" });
          handleLink('/');
          window.scrollTo(100, 500);
        }}>
          <ListItemIcon><HomeIcon /></ListItemIcon>
          <ListItemText primary='イベントレポート'></ListItemText>
        </ListItem>
        <ListItem button key='stores' onClick={() => handleLink('/stores')}>
          <ListItemIcon><RestaurantMenuIcon /></ListItemIcon>
          <ListItemText primary='参加店舗一覧'></ListItemText>
        </ListItem>

        <ListItem button key='about' onClick={() => {
          handleLink('/#corona');
          props.dispatch({ type: "ABOUT", id: "corona" });
        }}>
          <ListItemIcon><HomeIcon /></ListItemIcon>
          <ListItemText primary='コロナ対策'></ListItemText>
        </ListItem>
        <ListItem button key='about' onClick={() => {
          handleLink('/#kyosan');
          props.dispatch({ type: "ABOUT", id: "kyosan" });
        }}>
          <ListItemIcon><HomeIcon /></ListItemIcon>
          <ListItemText primary='協賛・協力'></ListItemText>
        </ListItem>
        <ListItem button key='about' onClick={() => {
          handleLink('/#contact');
          props.dispatch({ type: "ABOUT", id: "contact" });
        }}>
          <ListItemIcon><HomeIcon /></ListItemIcon>
          <ListItemText primary='お問い合わせ'></ListItemText>
        </ListItem>
        {/* <ListItem button key='stores' onClick={() => handleLink('/stores')}>
          <ListItemIcon><RestaurantMenuIcon /></ListItemIcon>
          <ListItemText primary='参加飲食店'></ListItemText>
        </ListItem> */}
        {/* <ListItem button key='menus' onClick={() => handleLink('/menus')}>
          <ListItemIcon><RestaurantMenuIcon /></ListItemIcon>
          <ListItemText primary='デリバリーメニュー'></ListItemText>
        </ListItem> */}
        {/* {props.state.isSignedIn && (
          <ListItem button key='cart' onClick={() => handleLink('/cart')}>
            <ListItemIcon><ShoppingCartIcon /></ListItemIcon>
            <ListItemText primary='カート'></ListItemText>
          </ListItem>
        )} */}
        {/* {props.state.isSignedIn && (
          <ListItem button key='order' onClick={() => handleLink('/order')}>
            <ListItemIcon><ListAltIcon /></ListItemIcon>
            <ListItemText primary='注文履歴'></ListItemText>
          </ListItem>
        )} */}
        {/* {props.state.isSignedIn && (
          <ListItem button key='ticket' onClick={() => handleLink('/ticket')}>
            <ListItemIcon><ConfirmationNumberIcon /></ListItemIcon>
            <ListItemText primary='チケット購入'></ListItemText>
          </ListItem>
        )} */}
        {/* {!props.state.isSignedIn && (
          <ListItem button key='sinin' onClick={() => handleLink('/signin')}>
            <ListItemIcon><HowToRegIcon /></ListItemIcon>
            <ListItemText primary='ログイン'></ListItemText>
          </ListItem>
        )} */}
        {/* {props.state.isSignedIn && (
          <ListItem button key='sinin' onClick={() => handleLink('/signin')}>
            <ListItemIcon><AssignmentIndIcon /></ListItemIcon>
            <ListItemText primary='マイページ'></ListItemText>
          </ListItem>
        )} */}
        {/* {!props.state.isSignedIn && (
          <ListItem button key='sinup' onClick={() => handleLink('/signup')}>
            <ListItemIcon><HowToRegIcon /></ListItemIcon>
            <ListItemText primary='新規登録'></ListItemText>
          </ListItem>
        )} */}
        {/* {props.state.isSignedIn && (
          <ListItem button key='forgotpw' onClick={() => Auth.signOut()}>
            <ListItemIcon><ExitToAppIcon /></ListItemIcon>
            <ListItemText primary='サインアウト'></ListItemText>
          </ListItem>
        )} */}
        {/* {props.state.isSignedIn &&
          (props.state?.dynamoUser?.authority === 'Staff' || props.state?.dynamoUser?.authority === 'Admin') && (
            <ListItem button key='delivery' onClick={() => handleLink('/delivery')} className={classes.color}>
              <ListItemIcon><SettingsIcon /></ListItemIcon>
              <ListItemText primary='注文(デリバリー)'></ListItemText>
            </ListItem>
          )} */}
        {/* {props.state.isSignedIn &&
          props.state?.dynamoUser?.authority !== 'User' && (
            <ListItem button key='takeout' onClick={() => handleLink('/takeout')} className={classes.color}>
              <ListItemIcon><SettingsIcon /></ListItemIcon>
              <ListItemText primary='注文(テイクアウト)'></ListItemText>
            </ListItem>
          )} */}
        {/* {props.state.isSignedIn &&
          (props.state?.dynamoUser?.authority === 'Shop' || props.state?.dynamoUser?.authority === 'Admin') && (
            <ListItem button key='eatin' onClick={() => handleLink('/eatin')} className={classes.color}>
              <ListItemIcon><SettingsIcon /></ListItemIcon>
              <ListItemText primary='注文(イートイン)'></ListItemText>
            </ListItem>
          )} */}
        {/* {props.state.isSignedIn && props.state?.dynamoUser?.authority === 'Admin' && (
          <ListItem button key='tool' onClick={() => handleLink('/tool/shop')} className={classes.color}>
            <ListItemIcon><SettingsIcon /></ListItemIcon>
            <ListItemText primary='店舗登録'></ListItemText>
          </ListItem>
        )} */}
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
      </List>
    </div>
  )
}