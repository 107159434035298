import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Header from '../utils/Header'
import Footer from '../utils/Footer'

const initalFormState = {
  username: '',
  password: '',
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justify: "center",
    borderColor: '#fff6d2',
    paddingTop: 20
  },
  form: {
    borderColor: '#fff6d2',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bgcolor: {
    backgroundColor: '#fff6d2',
    width: "100%",
    height: "100%",
    marginTop: '-65px'
  },
  textcolor: {
    color: '#FF8C00',
  },

  spacing: {
    padding: theme.spacing(2),
    color: '#FF8C00',
  },
  root: {
    backgroundColor: '#fff6d2',
    height: "100%",
    padding: theme.spacing(2),
    margin: 'auto',
    maxWidth: 500,
    justify: "center",
  },
  button: {
    width: "43%",
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#FF8C00',
    '&:hover': {
      backgroundColor: '#fad206',
      margin: theme.spacing(3, 0, 2),
    }
  },
}));

const SignIn = (props) => {
  const classes = useStyles();

  const [formState, updateFormState] = useState(initalFormState)
  const [authError, setAuthError] = useState({})

  const onChange = (e) => {
    e.persist();
    updateFormState(() => ({ ...formState, [e.target.name]: e.target.value }))
  }

  const onSubmit = (e) => {
    e.preventDefault();
    cognitoSignIn();
  }

  const cognitoSignIn = async () => {
    const { username, password } = formState;
    try {
      await Auth.signIn(username, password)
    } catch (err) {
      setAuthError(err)
      console.log("sign in error:", err)

    }
  };

  if (props.state.isSignedIn) {
    // ログインしている
    return (
      <Redirect to={'/mypage'} />
    )
  } else {
    // ログインしていない
    return (
      <div className={classes.bgcolor}>
        <Header state={props.state} dispatch={props.dispatch}></Header>
        <Grid className={classes.paper} >
          <CssBaseline />
          <div className={classes.paper} >
            <img alt=''
              src="https://images.suku-yell.com/logo_footer.png" width="100%" ></img>
            <Box mt={8}>
            </Box>
            <Typography component="h1" variant="h5">
              {/* <h3 className={classes.textcolor}>ログイン</h3> */}
            </Typography>
            <Typography variant="body1" color="error">
              {authError.message}
            </Typography>
            <Grid container spacing={0} direction="row" justify="center" alignItems="center">
              <form className={classes.form} onSubmit={onSubmit}>
                <TextField className={classes.form}
                  variant="outlined"
                  required
                  fullWidth
                  id="username"
                  label="username"
                  name="username"
                  autoComplete="username"
                  autoFocus
                  onChange={onChange}
                  justify="center"
                />
                <TextField className={classes.form}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  onChange={onChange}
                />
                <Button className={classes.button}
                  type="submit"
                  fullWidth
                  variant="contained" >
                  {'ログイン'}
                </Button>
                <Grid item className={classes.spacing}>
                  <Link to="/forgot-password" variant="body2">
                    {'パスワードをお忘れですか?'}
                  </Link>
                </Grid>
                <Grid item >
                  <Link to="/signup" variant="body2">
                    {"新規アカウント作成"}
                  </Link>
                </Grid>
              </form>
            </Grid>
          </div>
          <Box mt={8}>
          </Box>
        </Grid>
        <Footer state={props.state}></Footer>
      </div>
    )
  }
}

export default SignIn;