import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CardContent from '@material-ui/core/CardContent';
import Header from '../utils/Header'
import Footer from '../utils/Footer'
import { Grid, Typography } from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
    main: {
        backgroundColor: '#FFF6D2',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'left',
        },
        [theme.breakpoints.up('md')]: {
            textAlign: 'center',
        },
        [theme.breakpoints.up('lg')]: {
            textAlign: 'center',
        },
    },
    section: {
        width: "100%",
        backgroundColor: '#fff',
        height: "100%",
        borderRadius: "30px",
        paddingTop: '20px',
        paddingBottom: '20px',
        marginTop: '20px',
        marginBottom: '20px'
    },

    pos2: {
        color: '#4C1D00',
        fontSize: 14,
        textAlign: 'left',
        // marginTop: 20,
        marginBottom: 20
    },
    pos3: {
        color: '#4C1D00',
        fontWeight: 'bold',
        fontSize: 16,
        textAlign: 'right',
        // marginTop: 20,
        marginBottom: 20
    },
    menuText2: {
        color: '#ff8a00',
        fontSize: 18,
        marginTop: 20,
        marginLeft: 20,
        fontWeight: 'bold',
        textAlign: 'left',
    },
    pos4: {
        color: '#4C1D00',
        fontSize: 14,
        fontWeight: 'bold',
        textDecoration: 'underline',
        paddingBottom: '20px',

    },
    menuText: {
        color: '#4C1D00',
        fontSize: 16,
        margin: 10,
        fontWeight: 'bold',
        textAlign: 'left',
    },
    imgSize: {
        width: '100%',
        backgroundColor: '#fff',
        display: 'block',
        textAlign: 'center',
        marginBottom: 20
    },
    main2: {
        backgroundColor: '#fff6d2',
        color: '#ff8a00',
        margin: 0,
        marginBottom: 0,
        paddingTop: 60
    },
    main3: {
        backgroundColor: '#fff6d2',
        margin: 0,
        marginBottom: 0,
    },
    paypalLink: {
        display: 'block',
        padding: 10,
        backgroundColor: '#FAD206',
        borderRadius: "50px",
        marginTop: 10,
        marginBottom: 30
    },
    attention: {
        display: 'block',
        padding: 10,
        backgroundColor: '#FAD206',
        borderRadius: "20px",
        // marginTop: 10,
        marginBottom: 30,
        fontWeight: 'bold',
        textAlign: 'left'
    },
    strong: {
        color: '#ff8a00'
    },
    linkColor: {
        color: '#ff8a00',
    },

}));




export default function Home(props) {
    const classes = useStyles();
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <>
            <Header state={props.state} className={classes.menu} dispatch={props.dispatch}></Header>
            <div className={classes.main3}>
                <Grid className={classes.main2} >
                    <h2>注文方法（イートイン）</h2>
                </Grid>
                <Container className={classes.main} >
                    <div className={classes.section}>
                        <Grid container spacing={0} alignItems="center" justify="center">
                            <Grid item xs={11} lg={5} >
                                <div>
                                    <CardContent>

                                        <Typography className={classes.pos2} >
                                            メニューの「参加飲食店」から、利用する店舗を選択してください。<br />
                                            イベント限定メニューを選択し、数量を決め、カートに入れるを選択してください。
                                        </Typography>
                                        <img className={classes.imgSize} alt=''
                                            src="https://images.suku-yell.com/cap/cap_eatin1.png" ></img>
                                        <Typography className={classes.pos2} >
                                            ヘッダーのカートマークまたはメニューのカートからカートページに移動してください。<br />
                                            カートページに移動したら、カートに遷移し、選択したメニューと必要チケット枚数が表示されます。<br />
                                            イートインにチェックをいれて「注文する」を選択してください。<br />
                                            ※チケットの枚数が足りない場合はチケット購入ページに移動します。チケット購入後、注文をやり直してください。
                                        </Typography>
                                        <img className={classes.imgSize} alt=''
                                            src="https://images.suku-yell.com/cap/cap_eatin2.png" ></img>
                                        <img className={classes.imgSize} alt=''
                                            src="https://images.suku-yell.com/cap/cap_eatin3.png" ></img>
                                        <Typography className={classes.pos2} >
                                            店舗に到着したら、店員さんに注文履歴を提示してください。<br />
                                            注文履歴はメニューの「注文履歴」または「マイページ」の注文履歴から移動できます。<br />
                                            来店した店舗の注文履歴を選択し、店員さん確認後「受け取る」を選択してください。
                                        </Typography>
                                        <img className={classes.imgSize} alt=''
                                            src="https://images.suku-yell.com/cap/cap_eatin4.png" ></img>
                                        <img className={classes.imgSize} alt=''
                                            src="https://images.suku-yell.com/cap/cap_eatin5.png" ></img>
                                        <Typography className={classes.pos2} >
                                            これにて注文は完了です。お食事をお楽しみください。
                                        </Typography>


                                    </CardContent>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </Container>

                <Typography className={classes.pos3} >
                    <Link className={classes.linkColor} to="/howto"> 利用方法もくじに戻る＞</Link>
                </Typography>


                <Grid>
                    <Footer state={props.state} ></Footer>
                </Grid>
            </div>
        </>
    )
}

