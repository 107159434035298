/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      cognito
      paypal
      gender
      age
      name
      authority
      purchasedTickets
      ticketPossession
      updatedAt
      createdAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      cognito
      paypal
      gender
      age
      name
      authority
      purchasedTickets
      ticketPossession
      updatedAt
      createdAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      cognito
      paypal
      gender
      age
      name
      authority
      purchasedTickets
      ticketPossession
      updatedAt
      createdAt
    }
  }
`;
export const createPaypal = /* GraphQL */ `
  mutation CreatePaypal(
    $input: CreatePaypalInput!
    $condition: ModelPaypalConditionInput
  ) {
    createPaypal(input: $input, condition: $condition) {
      id
      appuser
      cancelled
      email
      paid
      name
      payerid
      paymentid
      paymenttoken
      returnurl
      updatedAt
      createdAt
    }
  }
`;
export const updatePaypal = /* GraphQL */ `
  mutation UpdatePaypal(
    $input: UpdatePaypalInput!
    $condition: ModelPaypalConditionInput
  ) {
    updatePaypal(input: $input, condition: $condition) {
      id
      appuser
      cancelled
      email
      paid
      name
      payerid
      paymentid
      paymenttoken
      returnurl
      updatedAt
      createdAt
    }
  }
`;
export const deletePaypal = /* GraphQL */ `
  mutation DeletePaypal(
    $input: DeletePaypalInput!
    $condition: ModelPaypalConditionInput
  ) {
    deletePaypal(input: $input, condition: $condition) {
      id
      appuser
      cancelled
      email
      paid
      name
      payerid
      paymentid
      paymenttoken
      returnurl
      updatedAt
      createdAt
    }
  }
`;
export const createStore = /* GraphQL */ `
  mutation CreateStore(
    $input: CreateStoreInput!
    $condition: ModelStoreConditionInput
  ) {
    createStore(input: $input, condition: $condition) {
      id
      name
      description
      url
      image
      address
      tel
      googleMap
      businessHours
      ventilationType
      ventilationAt
      crowded
      updatedAt
      createdAt
      menus {
        items {
          id
          title
          description
          image
          ticket
          storeID
          soldOut
          delivery
          takeout
          eatin
          deleted
          updatedAt
          createdAt
        }
        nextToken
      }
    }
  }
`;
export const updateStore = /* GraphQL */ `
  mutation UpdateStore(
    $input: UpdateStoreInput!
    $condition: ModelStoreConditionInput
  ) {
    updateStore(input: $input, condition: $condition) {
      id
      name
      description
      url
      image
      address
      tel
      googleMap
      businessHours
      ventilationType
      ventilationAt
      crowded
      updatedAt
      createdAt
      menus {
        items {
          id
          title
          description
          image
          ticket
          storeID
          soldOut
          delivery
          takeout
          eatin
          deleted
          updatedAt
          createdAt
        }
        nextToken
      }
    }
  }
`;
export const deleteStore = /* GraphQL */ `
  mutation DeleteStore(
    $input: DeleteStoreInput!
    $condition: ModelStoreConditionInput
  ) {
    deleteStore(input: $input, condition: $condition) {
      id
      name
      description
      url
      image
      address
      tel
      googleMap
      businessHours
      ventilationType
      ventilationAt
      crowded
      updatedAt
      createdAt
      menus {
        items {
          id
          title
          description
          image
          ticket
          storeID
          soldOut
          delivery
          takeout
          eatin
          deleted
          updatedAt
          createdAt
        }
        nextToken
      }
    }
  }
`;
export const createMenu = /* GraphQL */ `
  mutation CreateMenu(
    $input: CreateMenuInput!
    $condition: ModelMenuConditionInput
  ) {
    createMenu(input: $input, condition: $condition) {
      id
      title
      description
      image
      ticket
      storeID
      soldOut
      delivery
      takeout
      eatin
      deleted
      updatedAt
      createdAt
      store {
        id
        name
        description
        url
        image
        address
        tel
        googleMap
        businessHours
        ventilationType
        ventilationAt
        crowded
        updatedAt
        createdAt
        menus {
          nextToken
        }
      }
    }
  }
`;
export const updateMenu = /* GraphQL */ `
  mutation UpdateMenu(
    $input: UpdateMenuInput!
    $condition: ModelMenuConditionInput
  ) {
    updateMenu(input: $input, condition: $condition) {
      id
      title
      description
      image
      ticket
      storeID
      soldOut
      delivery
      takeout
      eatin
      deleted
      updatedAt
      createdAt
      store {
        id
        name
        description
        url
        image
        address
        tel
        googleMap
        businessHours
        ventilationType
        ventilationAt
        crowded
        updatedAt
        createdAt
        menus {
          nextToken
        }
      }
    }
  }
`;
export const deleteMenu = /* GraphQL */ `
  mutation DeleteMenu(
    $input: DeleteMenuInput!
    $condition: ModelMenuConditionInput
  ) {
    deleteMenu(input: $input, condition: $condition) {
      id
      title
      description
      image
      ticket
      storeID
      soldOut
      delivery
      takeout
      eatin
      deleted
      updatedAt
      createdAt
      store {
        id
        name
        description
        url
        image
        address
        tel
        googleMap
        businessHours
        ventilationType
        ventilationAt
        crowded
        updatedAt
        createdAt
        menus {
          nextToken
        }
      }
    }
  }
`;
export const createOrder = /* GraphQL */ `
  mutation CreateOrder(
    $input: CreateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    createOrder(input: $input, condition: $condition) {
      id
      appuser
      status
      key
      ticket
      isDelivery
      isTakeout
      isEatin
      phone
      address
      consumed
      description
      updatedAt
      createdAt
      menus {
        items {
          id
          store
          menu
          ticket
          orderCount
          status
          description
          orderID
          updatedAt
          createdAt
        }
        nextToken
      }
    }
  }
`;
export const updateOrder = /* GraphQL */ `
  mutation UpdateOrder(
    $input: UpdateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    updateOrder(input: $input, condition: $condition) {
      id
      appuser
      status
      key
      ticket
      isDelivery
      isTakeout
      isEatin
      phone
      address
      consumed
      description
      updatedAt
      createdAt
      menus {
        items {
          id
          store
          menu
          ticket
          orderCount
          status
          description
          orderID
          updatedAt
          createdAt
        }
        nextToken
      }
    }
  }
`;
export const deleteOrder = /* GraphQL */ `
  mutation DeleteOrder(
    $input: DeleteOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    deleteOrder(input: $input, condition: $condition) {
      id
      appuser
      status
      key
      ticket
      isDelivery
      isTakeout
      isEatin
      phone
      address
      consumed
      description
      updatedAt
      createdAt
      menus {
        items {
          id
          store
          menu
          ticket
          orderCount
          status
          description
          orderID
          updatedAt
          createdAt
        }
        nextToken
      }
    }
  }
`;
export const createOrderMenu = /* GraphQL */ `
  mutation CreateOrderMenu(
    $input: CreateOrderMenuInput!
    $condition: ModelOrderMenuConditionInput
  ) {
    createOrderMenu(input: $input, condition: $condition) {
      id
      store
      menu
      ticket
      orderCount
      status
      description
      orderID
      updatedAt
      createdAt
      order {
        id
        appuser
        status
        key
        ticket
        isDelivery
        isTakeout
        isEatin
        phone
        address
        consumed
        description
        updatedAt
        createdAt
        menus {
          nextToken
        }
      }
    }
  }
`;
export const updateOrderMenu = /* GraphQL */ `
  mutation UpdateOrderMenu(
    $input: UpdateOrderMenuInput!
    $condition: ModelOrderMenuConditionInput
  ) {
    updateOrderMenu(input: $input, condition: $condition) {
      id
      store
      menu
      ticket
      orderCount
      status
      description
      orderID
      updatedAt
      createdAt
      order {
        id
        appuser
        status
        key
        ticket
        isDelivery
        isTakeout
        isEatin
        phone
        address
        consumed
        description
        updatedAt
        createdAt
        menus {
          nextToken
        }
      }
    }
  }
`;
export const deleteOrderMenu = /* GraphQL */ `
  mutation DeleteOrderMenu(
    $input: DeleteOrderMenuInput!
    $condition: ModelOrderMenuConditionInput
  ) {
    deleteOrderMenu(input: $input, condition: $condition) {
      id
      store
      menu
      ticket
      orderCount
      status
      description
      orderID
      updatedAt
      createdAt
      order {
        id
        appuser
        status
        key
        ticket
        isDelivery
        isTakeout
        isEatin
        phone
        address
        consumed
        description
        updatedAt
        createdAt
        menus {
          nextToken
        }
      }
    }
  }
`;
export const createYoutube = /* GraphQL */ `
  mutation CreateYoutube(
    $input: CreateYoutubeInput!
    $condition: ModelYoutubeConditionInput
  ) {
    createYoutube(input: $input, condition: $condition) {
      id
      title
      url
      description
      deleted
      updatedAt
      createdAt
    }
  }
`;
export const updateYoutube = /* GraphQL */ `
  mutation UpdateYoutube(
    $input: UpdateYoutubeInput!
    $condition: ModelYoutubeConditionInput
  ) {
    updateYoutube(input: $input, condition: $condition) {
      id
      title
      url
      description
      deleted
      updatedAt
      createdAt
    }
  }
`;
export const deleteYoutube = /* GraphQL */ `
  mutation DeleteYoutube(
    $input: DeleteYoutubeInput!
    $condition: ModelYoutubeConditionInput
  ) {
    deleteYoutube(input: $input, condition: $condition) {
      id
      title
      url
      description
      deleted
      updatedAt
      createdAt
    }
  }
`;
