import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CardContent from '@material-ui/core/CardContent';
import Header from '../utils/Header'
import Footer from '../utils/Footer'
import { Grid, Typography } from '@material-ui/core';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  main: {
    backgroundColor: '#FFF6D2',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
    },
    [theme.breakpoints.up('md')]: {
      textAlign: 'center',
    },
    [theme.breakpoints.up('lg')]: {
      textAlign: 'center',
    },
  },
  section: {
    width: "100%",
    backgroundColor: '#fff',
    height: "100%",
    borderRadius: "30px",
    paddingTop: '20px',
    paddingBottom: '20px',
    marginTop: '20px',
    marginBottom: '20px'
  },

  pos2: {
    color: '#4C1D00',
    fontSize: 14,
    textAlign: 'left',
    // marginTop: 20,
    marginBottom: 20
  },
  menuText2: {
    color: '#ff8a00',
    fontSize: 18,
    marginTop: 20,
    marginLeft: 20,
    fontWeight: 'bold',
    textAlign: 'left',
  },
  pos4: {
    color: '#4C1D00',
    fontSize: 14,
    fontWeight: 'bold',
    textDecoration: 'underline',
    paddingBottom: '20px',

  },
  menuText: {
    color: '#4C1D00',
    fontSize: 16,
    margin: 10,
    fontWeight: 'bold',
    textAlign: 'left',
  },
  imgSize: {
    width: '100%',
    backgroundColor: '#fff',
    display: 'block',
    textAlign: 'center',
    marginBottom: 20
  },
  main2: {
    backgroundColor: '#fff6d2',
    color: '#ff8a00',
    margin: 0,
    marginBottom: 0,
    paddingTop: 60
  },
  main3: {
    backgroundColor: '#fff6d2',
    margin: 0,
    marginBottom: 0,
  },
  paypalLink: {
    display: 'block',
    padding: 10,
    backgroundColor: '#FAD206',
    borderRadius: "50px",
    marginTop: 10,
    marginBottom: 30
  },
  attention: {
    display: 'block',
    padding: 10,
    backgroundColor: '#FAD206',
    borderRadius: "20px",
    // marginTop: 10,
    marginBottom: 30,
    fontWeight: 'bold',
    textAlign: 'left'
  },
  strong: {
    color: '#ff8a00'
  }

}));




export default function Home(props) {
  const classes = useStyles();
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Header state={props.state} className={classes.menu} dispatch={props.dispatch}></Header>
      <div className={classes.main3}>
        <Grid className={classes.main2} >
          <h1>デリバリーマップ</h1>
        </Grid>
        <Container className={classes.main} >
          <div className={classes.section}>
            <Grid container spacing={0} alignItems="center" justify="center">
              <Grid item xs={11} lg={5} >
                <CardContent>
                  <Typography className={classes.pos2} >
                    {'デリバリーができるエリアは、町屋1丁目、町屋2丁目、町屋3丁目、荒川4丁目、荒川5丁目、荒川6丁目、荒川7丁目です。よく確認してデリバリーを注文してください。'}
                  </Typography>
                  <img className={classes.imgSize} src="https://images.suku-yell.com/delivery_map.jpg" alt='' />
                </CardContent>
              </Grid>
            </Grid>
          </div>
        </Container>
        <Footer state={props.state} ></Footer>
      </div>
    </>
  )
}

