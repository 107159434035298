import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Auth, API, graphqlOperation } from 'aws-amplify';
import * as mutations from '../graphql/mutations';
import Header from '../utils/Header'
import Footer from '../utils/Footer'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import HomeIcon from '@material-ui/icons/Home';

const initalFormState = {
  username: '',
  password: '',
  email: '',
  authCode: ''
}

const initalDynamoUser = {
  cognito: '',
  paypal: '',
  gender: 'OTHER',
  age: 0,
  authority: 'User',
  purchasedTickets: 0,
  ticketPossession: 0,
}


const useStyles = makeStyles((theme) => ({
  paper: {
    paddingTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justify: "center",
    borderColor: '#fff6d2',
  },
  form: {
    borderColor: '#fff6d2',
    margin: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  bgcolor: {
    backgroundColor: '#fff6d2',
    width: "100%",
    height: "100%",
  },
  textcolor: {
    color: '#FF8C00',
  },

  spacing: {
    padding: theme.spacing(2),
    color: '#FF8C00',
  },
  root: {
    backgroundColor: '#fff6d2',
    height: "100%",
    padding: theme.spacing(2),
    margin: 'auto',
    maxWidth: 500,
    justify: "center",
  },
  button: {
    width: "43%",
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#FF8C00',
    '&:hover': {
      backgroundColor: '#fad206',
      margin: theme.spacing(3, 0, 2),
    }
  },
  fontStyle: {
    color: '#ff8a00',
    backgroundColor: '#fff6d2',
  },
  fontKome: {
    fontSize: 13,
    marginBottom: 10,
  }
}));




export default function SignUp(props) {

  const [formState, updateFormState] = useState(initalFormState)
  const [dynamoUser, updateDynamoUser] = useState(initalDynamoUser)
  const [isAuthSend, setAuthSend] = useState(false)
  const [isSuccess, setSuccess] = useState(false)
  const [authError, setAuthError] = useState({})
  const [errorTextField, setErrorTextField] = useState({ pass: { error: false, text: '' } })

  const classes = useStyles();


  const passEmpty = { error: false, text: '' }



  const onChange = (e) => {
    e.persist();
    updateFormState(() => ({ ...formState, [e.target.name]: e.target.value }))
    const ratz = /[a-z]/, rAtZ = /[A-Z]/, r0t9 = /[0-9]/;
    if (e.target.name === 'password' && ((e.target.value).length < 8 || !ratz.test(e.target.value) || !rAtZ.test(e.target.value) || !r0t9.test(e.target.value))) {
      passEmpty.error = true
      passEmpty.text = 'error'
      setErrorTextField({ pass: passEmpty })
    } else {
      passEmpty.error = false
      passEmpty.text = ''
      setErrorTextField({ pass: passEmpty })
    }
  }
  const onSubmit = (e) => {
    e.preventDefault();
    console.log("onSubmit");
    signUp();
  }

  const signUp = async () => {
    try {
      const { username, email, password } = formState;
      const { user } = await Auth.signUp({ username, password, attributes: { email } })
      const createUserData = { ...dynamoUser, cognito: user.username }
      const res = await API.graphql(graphqlOperation(mutations.createUser, { input: createUserData }));
      updateDynamoUser(createUserData)
      setAuthSend(true)
      console.log('sign up success:', res)
    } catch (err) {
      setAuthError(err)
      console.log("sign up error", err)
    }
  };
  const confirmSignUp = async () => {
    try {
      const { username, authCode } = formState;
      await Auth.confirmSignUp(username, authCode)
      setSuccess(true)
    } catch (err) {
      setAuthError(err)
      console.log("sign up confirm error", err)
    }
  };

  // レンダリング
  if (props.state.isSignedIn) {
    // ログインしている
    return (
      <>
        <Header state={props.state} dispatch={props.dispatch}></Header>
        <Typography className={classes.fontStyle} variant='h4'>
          新規登録
          </Typography>
        <div>すでにログインしています。</div>
        <div> <Link to="/" icon={<HomeIcon />}>Homeへ</Link> </div>

        <Footer state={props.state}></Footer>
      </>
    )
  } else {
    // ログインしていない
    if (isAuthSend && isSuccess) {
      // 認証完了画面
      return (
        <>
          <Header state={props.state} dispatch={props.dispatch}></Header>
          <Grid className={classes.paper} >
            <CssBaseline />
            <div className={classes.paper} >
              <img alt=''
                src="https://images.suku-yell.com/logo_footer.png" width="100%" ></img>
              <Box mt={8}>
              </Box>
              <Typography variant='body1' className={classes.textcolor}>
                {'新規登録 完了'}
              </Typography>
              <Typography variant='body1' className={classes.textcolor}>
                {'新規登録が完了しました。'}<Link to='/signin'>{'こちら'}</Link>{'からログインをしてください。'}
              </Typography>
              <div>{authError.message}</div>
            </div>
            <Box mt={8}>
            </Box>
          </Grid>

          <Footer state={props.state}></Footer>
        </>
      )
    } else if (isAuthSend) {
      // 認証画面
      return (
        <>
          <Header state={props.state} dispatch={props.dispatch}></Header>
          <Grid className={classes.paper} >
            <CssBaseline />
            <div className={classes.paper} >
              <img alt=''
                src="https://images.suku-yell.com/logo_footer.png" width="100%" ></img>
              <Box mt={8}>
              </Box>

              <h1 className={classes.textcolor}>新規登録 コード認証</h1>

              <div>{authError.message}</div>
              <Grid container direction="row" justify="center" alignItems="center" >
                <form className={classes.form} >
                  <TextField className={classes.form}
                    variant="outlined"
                    required
                    fullWidth
                    id="authCode"
                    label="authCode"
                    name="authCode"
                    autoComplete="authCode"
                    autoFocus
                    placeholder='authCode'
                    onChange={onChange}
                  />
                  <Button className={classes.button}
                    onClick={confirmSignUp}
                    fullWidth
                    variant="contained"
                    placeholder='username'
                  >
                    {'認証'}
                  </Button>

                </form>
              </Grid>
            </div>
            <Box mt={8}>
            </Box>
          </Grid>
          <Footer state={props.state}></Footer>
        </>
      )
    } else {
      // 新規作成画面
      return (
        <Grid className={classes.bgcolor}>
          <Header state={props.state} dispatch={props.dispatch}></Header>
          <Grid className={classes.paper} >
            <CssBaseline />
            <div className={classes.paper} >
              <img alt=''
                src="https://images.suku-yell.com/logo_footer.png" width="100%" ></img>
              <Box mt={8}>
              </Box>
              <Typography className={classes.fontStyle} variant='h4'>
                {"新規登録"}
              </Typography>

              <Typography variant='body1'>
                {authError.message}
              </Typography>
              <form className={classes.form} onSubmit={onSubmit}>
                <TextField className={classes.form}
                  variant="outlined"
                  required
                  fullWidth
                  id="username"
                  label="username"
                  name="username"
                  autoComplete="username"
                  autoFocus
                  placeholder='例）タロウ'
                  onChange={onChange}
                />
                <TextField className={classes.form}
                  variant="outlined"
                  required
                  fullWidth
                  type="password"
                  id="password"
                  label="password"
                  name="password"
                  autoComplete="password"
                  autoFocus
                  placeholder='password'
                  onChange={onChange}
                  error={errorTextField.pass.error}
                />
                <Typography className={classes.fontKome}
                  color={errorTextField.pass.text}>
                  ※8文字以上かつ 英大文字・英小文字・数字それぞれを最低1文字ずつ含む必要があります
              </Typography>
                <TextField className={classes.form}
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="email"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  placeholder='email'
                  onChange={onChange}
                />
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <Typography >
                    ※<Link to="/userpolicy">利用規約</Link>にご同意いただける場合は、次へお進みください。
              </Typography>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <Button className={classes.button}
                    type="submit"
                    fullWidth
                    variant="contained"
                    placeholder='username'
                  >
                    次へ</Button>

                </Grid>
              </form>
            </div>
            <Box mt={8}>
            </Box>
          </Grid>
          <Footer state={props.state}></Footer>
        </Grid>
      )
    }
  }
}
