/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_appsync_graphqlEndpoint": "https://rujau7s7vrg67idkrlyuj2c7oe.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-hqnbzwqjk5fyfmubgaibyhbvpa",
    "aws_cognito_identity_pool_id": "ap-northeast-1:a1eefcbe-025e-4954-bd13-5e2c41e68a28",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_wFIrhvoWX",
    "aws_user_pools_web_client_id": "fmuijdd2j9mvfvmbd0qufg07j",
    "oauth": {}
};


export default awsmobile;
