import React from 'react';
// import { API, graphqlOperation } from 'aws-amplify';
import { Redirect } from 'react-router-dom';

// import PaypalExpressBtn from 'react-paypal-express-checkout';
// import * as mutations from '../graphql/mutations'
// import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import Header from '../utils/Header'
import Footer from '../utils/Footer'
import { Grid } from '@material-ui/core';
// import { Grid, TextField } from '@material-ui/core';

// const useStyles = makeStyles({
//   sub: {
//     width: '100%',
//     height: '100%',
//   },
//   main: {
//     backgroundColor: '#ff8a00',
//     width: '100%',
//     height: '100%',
//   },
//   white: {
//     height: 0,
//     width: 380,
//     backgroundColor: '#ffffff',
//   },
//   media: {
//     height: 0,
//     width: 380,
//   },
//   list: {
//     width: 250,
//   },
//   bottom: {
//     position: 'relative',
//   },
//   fullList: {
//     width: 'auto',
//   },
//   strong: {
//     color: '#ff8a00',
//   },
//   title: {
//     fontSize: 14,
//   },
//   paperContainer: {
//     width: '100%',
//     color: '#ff8a00',
//   },
//   fontStyle: {
//     color: '#ff8a00',
//     width: '50%',
//     backgroundColor: '#fff6d2',
//   },
//   inputwidth: {
//     width: 70,
//     backgroundColor: '"ffffff'
//   }
// });

// const client = {
//   sandbox: 'Ae2atd5lcq7D3_TS-ErV7JlrZnobtx1r086vPKlXzY0nJmEGizAXrY2HMROAybLOERw-vhpu3tjV3yZT',
//   production: 'AZfDlkZjKmjL4EreKpgIP1846RUofGkB6e6jRdIz65x2TnXuj9sMfCvzf8lBBLY5bEOlWq00oP47LmKZ',
// };

// const initalFormState = {
//   tiketNumber: 0,
//   total: 0,
//   tax: 0,
//   shippingType: 1,
//   baseAmountOfMoney: 500,
//   baseTaxCoefficient: 11,
//   message: '',
// }

// const initalTransaction = {
//   transactions: [
//     {
//       amount: {
//         total: 0,
//         currency: 'JPY',
//         details:
//         {
//           subtotal: 0,
//           tax: 0,
//           shipping: 0,
//           handling_fee: 0,
//           shipping_discount: 0,
//           insurance: 0
//         }
//       },
//       description: '',
//     }
//   ]

// }
export default function BuyTicket(props) {

  // const classes = useStyles();
  // const [formState, updateFormState] = useState(initalFormState)
  // const [transactions, setTransactions] = useState(initalTransaction)
  // const [paypalSuccess, setPaypalSuccess] = useState(null)
  // const [paypalCancel, setPaypalCancel] = useState(null)
  // const [paypalError, setPaypalError] = useState(null)

  // const calcPayment = (e) => {
  //   e.persist();
  //   console.log(e.target.name)
  //   if (Number(e.target.value) >= 0) {
  //     const total = formState.baseAmountOfMoney * e.target.value
  //     const tax = Math.floor(total / formState.baseTaxCoefficient)
  //     const description = 'まちやを救エール チケット ' + e.target.value + '枚'

  //     updateFormState({ ...formState, [e.target.name]: e.target.value, total: total, tax: tax })
  //     setTransactions(() => ({
  //       transactions: [
  //         {
  //           amount: {
  //             total: total,
  //             currency: 'JPY',
  //             details:
  //             {
  //               subtotal: total - tax,
  //               tax: tax,
  //               shipping: 0,
  //               handling_fee: 0,
  //               shipping_discount: 0,
  //               insurance: 0
  //             }
  //           },
  //           description: description,
  //         }
  //       ]
  //     })
  //     )
  //   }
  // }

  // const onSuccess = (payment) => {
  //   console.log("Payment successful!");
  //   console.log(props.state.cognitoUser.username)
  //   setPaypalSuccess(payment)
  //   const inputPaypal = {
  //     input: {
  //       appuser: props.state.cognitoUser.username,
  //       cancelled: payment.cancelled,
  //       email: payment.email,
  //       paid: payment.paid,
  //       name: transactions.transactions[0].description,
  //       payerid: payment.payerID,
  //       paymentid: payment.paymentID,
  //       paymenttoken: payment.paymentToken,
  //       returnurl: payment.returnUrl,
  //     }
  //   }
  //   const inputUser = {
  //     input: {
  //       id: props.state.dynamoUser.id,
  //       paypal: payment.payerID,
  //       purchasedTickets:
  //         Number(props.state.dynamoUser.purchasedTickets) + Number(formState.tiketNumber),
  //     }
  //   }
  //   API.graphql(graphqlOperation(mutations.createPaypal, inputPaypal))
  //     .catch(err => {
  //       console.log("Paypay object input error:", err);
  //       updateFormState({ ...formState, message: '支払い後の処理に失敗しました。お手数ですが運営にご連絡ください。', tiketNumber: 0, total: 0, tax: 0 })
  //     });
  //   API.graphql(graphqlOperation(mutations.updateUser, inputUser))
  //     .then(res => {
  //       props.dispatch({ type: 'UPDATE', key: 'dynamoUser', value: res.data.updateUser })
  //       updateFormState({ ...formState, message: '支払い完了しました。', tiketNumber: 0, total: 0, tax: 0 })
  //     })
  //     .catch(err => {
  //       console.log("User object input error:", err);
  //       updateFormState({ ...formState, message: '支払い後の処理に失敗しました。お手数ですが運営にご連絡ください。', tiketNumber: 0, total: 0, tax: 0 })
  //     });
  // }

  // const onCancel = (data) => {
  //   console.log('Payment cancelled!');
  //   setPaypalCancel(data)
  //   updateFormState({ ...formState, message: '支払いをキャンセルしました。', tiketNumber: 0, total: 0, tax: 0 })
  // }

  // const onError = (err) => {
  //   console.log("Payment Error!", err);
  //   setPaypalError(err)
  //   updateFormState({ ...formState, message: '支払いに失敗しました。', tiketNumber: 0, total: 0, tax: 0 })
  // }

  if (props.state.isSignedIn) {
    return (
      <>
        <Header state={props.state} dispatch={props.dispatch} />
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <img alt='' src="https://images.suku-yell.com/tiket.png" />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" align="center">
              {'イベントのご参加ありがとうございました。'}
            </Typography>
            <Typography variant="h6" align="center">
              {'イベント終了に伴い、チケットの購入は終了しました。'}
            </Typography>
          </Grid>
        </Grid>
        <Footer logo simple></Footer>
      </>
    )
  } else {
    // ログインしていない
    return (
      <Redirect to={'/signin'} />
    )
  }


  // return (
  //   <div className={classes.sub}>
  //     <Header state={props.state} className={classes.menu}></Header>
  //     <Grid container spacing={0}>
  //       <Grid item lg={3}></Grid>
  //       <Grid className={classes.main2} item sm={12} lg={6}>
  //         <img className={classes.paperContainer} alt=''
  //           src="https://images.suku-yell.com/tiket.png" >
  //         </img>
  //       </Grid>
  //       <Grid item lg={3}></Grid>
  //     </Grid>
  //     <Grid container spacing={0}>
  //       <Grid item xs={1} sm={1} md={4} lg={4}></Grid>
  //       <Grid className={classes.fontStyle} item xs={10} sm={10} md={4} lg={4}>
  //         <Grid align="left">
  //           <Typography variant="h6"  >購入済チケット数 : {props.state.dynamoUser.purchasedTickets - props.state.dynamoUser.ticketPossession}
  //           </Typography>

  //           <Typography variant="h6"  >購入希望 :
  //             <TextField
  //               className={classes.inputwidth}
  //               name='tiketNumber'
  //               value={formState.tiketNumber}
  //               size="small"
  //               type="number"
  //               inputProps={{ 'aria-label': 'description' }}
  //               onChange={calcPayment}
  //               variant="outlined"
  //             />
  //             枚</Typography>
  //           <Typography variant="h6" align="right">金額 : {formState.total} 円</Typography>
  //           <Grid
  //             container
  //             direction="row"
  //             justify="center"
  //             alignItems="center">

  //             <PaypalExpressBtn env='sandbox' client={client}
  //               total={transactions.transactions[0].amount.total}
  //               currency={transactions.transactions[0].amount.currency}
  //               paymentOptions={transactions}
  //               shipping={formState.shippingType}
  //               onError={onError} onSuccess={onSuccess} onCancel={onCancel} />
  //           </Grid>
  //         </Grid>
  //       </Grid>
  //       <Grid item xs={1} sm={1} md={4} lg={4}></Grid>
  //       <Grid item xs={12}>
  //         <Typography variant="h5" align="center" color="error">{formState.message}</Typography>
  //       </Grid>
  //     </Grid>
  //     <Footer className={classes.bottom} state={props.state}></Footer>
  //   </div >
  // )
}
