import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Box, Container, Typography, Grid } from '@material-ui/core';
import ReactPlayer from 'react-player'

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const useStyles = makeStyles((theme) => ({
  main: {
    backgroundColor: '#ff8a00',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
    },
    [theme.breakpoints.up('md')]: {
      textAlign: 'center',
    },
    [theme.breakpoints.up('lg')]: {
      textAlign: 'center',
    },
    padding: '20px 10px'
  },
  main2: {
    backgroundColor: '#fff',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
    },
    [theme.breakpoints.up('md')]: {
      textAlign: 'center',
    },
    [theme.breakpoints.up('lg')]: {
      textAlign: 'center',
    },
    padding: '20px 10px'
  },
  section: {
    width: "100%",
    backgroundColor: '#fff6d2',
    height: "100%",
    borderRadius: "30px",
    paddingTop: '20px',
    paddingBottom: '20px',
    marginTop: '20px',
    marginBottom: '20px'
  },
  sectionTitle: {
    color: '#ff8a00',
    fontSize: 20,
    display: "center",
    textAlign: "center"
  },
  table: {
    borderCollapse: 'collapse',
    margin: '10px auto',
  },
  tableTdLeft: {
    border: 'solid 1px #4C1D00',
    backgroundColor: '#FF8A00',
    color: '#fff',
    textAlign: 'center',
    lineHeight: 3,
    padding: '0 10px'
  },
  tableTdRight: {
    border: 'solid 1px #4C1D00',
    backgroundColor: '#fff',
    color: '#4C1D00',
    textAlign: 'left',
    lineHeight: 3,
    padding: '0 10px'
  },
  images: {
    width: '90%',
    margin: '0 20px'
  },
  images2: {
    width: '100%',
  },
  slideImage: {
    width: '260px',
    height: '200px',
    objectFit: 'cover'
  },
  slideboxSizs: {
    height: '200px',
    margin: '0 5px',
    paddingBottom: 50,
    backgroundColor: '#FF8A00'
  },
  ulStyle: {
    listStyle: 'none',
    paddingLeft: 0,
    margin: 0
  },
  liStyle: {
    lineHeight: 2,
  },
  hidden: {
    overflow: 'hidden'
  }

}));

const settings = {
  autoplay: true,
  autoplaySpeed: 1500,
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: false,
  centerMode: true,
  variableWidth: true,
};

export default function Report(props) {
  const classes = useStyles();

  return (
    <>
      <div className={classes.hidden} >
        <div id="reportpage" className={classes.main} >
          <Grid container spacing={0} justify="center" alignItems="center">
            <Grid item xs={12} sm={10} md={7} lg={7} >
              {/* 開催概要 */}
              <div className={classes.section}>
                <h1 className={classes.sectionTitle} >- 開催概要 -</h1>

                <table className={classes.table}>
                  <tr>
                    <td className={classes.tableTdLeft}>日付</td>
                    <td className={classes.tableTdRight}>2020年11月7日(土)／8日(日)</td>
                  </tr>
                  <tr>
                    <td className={classes.tableTdLeft}>会場</td>
                    <td className={classes.tableTdRight}>荒川区町屋駅周辺</td>
                  </tr>
                  <tr>
                    <td className={classes.tableTdLeft}>参加店舗数</td>
                    <td className={classes.tableTdRight}>
                      <ul className={classes.ulStyle}>
                        <li className={classes.liStyle}>全34店舗 </li>
                        <li className={classes.liStyle}>飲食店33店舗、雑貨店1店舗</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td className={classes.tableTdLeft}>天候</td>
                    <td className={classes.tableTdRight}>7日(土)曇り時々雨／8日(日)晴れ</td>
                  </tr>
                </table>
              </div>

              {/* 参加店舗レポ */}
              <div className={classes.section}>
                <Grid container spacing={0} justify="center" alignItems="center">
                  <Grid item xs={12}>
                    <h1 className={classes.sectionTitle} >- 参加店舗レポ -</h1>
                  </Grid>
                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="center" m={1} p={1} >
                      <Box p={1}>
                        <ReactPlayer url='https://youtu.be/I_n7ISmYzhc' playing muted
                          width='clamp(340px,35vw,640px)'
                          height='clamp(200px,20vw,360px)'
                        />
                      </Box>

                    </Box>
                  </Grid>
                </Grid>
              </div>

              {/* 参加店舗の声 */}
              <div className={classes.section}>
                <h1 className={classes.sectionTitle} >- 参加店舗の声 -</h1>
                <div className={classes.widthFull}>
                  <Grid container spacing={1} justify="center" alignItems="center">
                    <Grid item xs={10} sm={8} md={5} lg={5} >
                      <img className={classes
                        .images} alt=''
                        src="https://images.suku-yell.com/bubbles1.png" ></img>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} justify="center" alignItems="center">
                    <Grid item xs={10} sm={8} md={5} lg={5} >
                      <img className={classes
                        .images} alt=''
                        src="https://images.suku-yell.com/bubbles2.png" ></img>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} justify="center" alignItems="center">
                    <Grid item xs={10} sm={8} md={5} lg={5} >
                      <img className={classes
                        .images} alt=''
                        src="https://images.suku-yell.com/bubbles3.png" ></img>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} justify="center" alignItems="center">
                    <Grid item xs={10} sm={8} md={5} lg={5} >
                      <img className={classes
                        .images} alt=''
                        src="https://images.suku-yell.com/bubbles4.png" ></img>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <div>
          <Slider {...settings}>
            <div className={classes.slideboxSizs}>
              <img className={classes
                .slideImage} alt=''
                src="https://images.suku-yell.com/img1.jpg" ></img>
            </div>
            <div className={classes.slideboxSizs}>
              <img className={classes
                .slideImage} alt=''
                src="https://images.suku-yell.com/img2.jpg" ></img>
            </div>
            <div className={classes.slideboxSizs}>
              <img className={classes
                .slideImage} alt=''
                src="https://images.suku-yell.com/img3.jpg" ></img>
            </div>
            <div className={classes.slideboxSizs}>
              <img className={classes
                .slideImage} alt=''
                src="https://images.suku-yell.com/img4.jpg" ></img>
            </div>
            <div className={classes.slideboxSizs}>
              <img className={classes
                .slideImage} alt=''
                src="https://images.suku-yell.com/img5.jpg" ></img>
            </div>
            <div className={classes.slideboxSizs}>
              <img className={classes
                .slideImage} alt=''
                src="https://images.suku-yell.com/img6.jpg" ></img>
            </div>
            <div className={classes.slideboxSizs}>
              <img className={classes
                .slideImage} alt=''
                src="https://images.suku-yell.com/img7.jpg" ></img>
            </div>
            <div className={classes.slideboxSizs}>
              <img className={classes
                .slideImage} alt=''
                src="https://images.suku-yell.com/img8.jpg" ></img>
            </div>
            <div className={classes.slideboxSizs}>
              <img className={classes
                .slideImage} alt=''
                src="https://images.suku-yell.com/img9.jpg" ></img>
            </div>
            <div className={classes.slideboxSizs}>
              <img className={classes
                .slideImage} alt=''
                src="https://images.suku-yell.com/img10.jpg" ></img>
            </div>
          </Slider>
        </div>
        {/* 募集 */}
        <div className={classes.main2} >
          <div className={classes.widthFull}>
            <Grid container spacing={1} justify="center" alignItems="center">
              <Grid item xs={10} sm={8} md={5} lg={5} >
                <img className={classes
                  .images2} alt=''
                  src="https://images.suku-yell.com/recruit.png" ></img>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </>



  )
}

