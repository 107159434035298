import React, { useEffect, useState } from 'react';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import { API } from 'aws-amplify';
import RoomIcon from '@material-ui/icons/Room';
import Link from '@material-ui/core/Link';
import Header from '../utils/Header'
import Footer from '../utils/Footer'
import { Avatar, Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Chip, Grid, List, ListItem, ListItemIcon, ListItemText, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { ConfirmationNumber, LocalShipping, Restaurant, ShoppingBasket } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { yellow } from '@material-ui/core/colors';
import deliveryMessage from '../utils/deliveryMessage';

const useStyles = makeStyles({
  root: {
    backgroundColor: 'white',
    color: '#ff8a00',
    marginTop: 10,
  },
  title: {
    color: '#ff8a00',
    backgroundColor: '#fff6d2',
    paddingTop: 75
  },
  borderOrange: {
    borderBottom: '10px solid #ff8a00'
  },
  storeImage: {
    width: '100%',
    // maxHeight: 250,
  },
  cartButton: {
    minWidth: 200,
    backgroundColor: '#fad206',
    '&:hover': {
      backgroundColor: '#FF8C00',
    },
  },
  media: {
    height: 0,
    paddingTop: '56.25%',
  },
  chip: {
    backgroundColor: '#fff6d2',
  },
  gmap: {
    maxWidth: 700,
    height: 1000
  },
});
const CustomButton = (props) => {
  const customTheme = theme => ({
    root: {
      backgroundColor: '#fad206',
      color: '#4c1d00',
      fontWeight: 'bold',
      paddingLeft: '50px',
      paddingRight: '50px',
      minHeight: 80,
      '&:hover': {
        backgroundColor: '#ff8a00',
      },
    },
  })
  const ComponentName = withStyles(customTheme)(Button)
  return <ComponentName {...props} />
}

export default function Stores(props) {

  const classes = useStyles();
  const [title, setTitle] = useState('参加店舗一覧')
  const [type, setType] = useState('list')
  const [stores, setStores] = useState([])
  const [currentStore, setCurrentStore] = useState({})
  const [menus, setMenus] = useState([])

  useEffect(() => fetchStores(), [])
  const fetchStores = () => {
    API.graphql({ query: queries.listStores, variables: { limit: 1000 } })
      .then(stores => {
        setStores(stores.data.listStores.items)
      })
      .catch(err => {
        console.log('fetch stores error:', err);
      })
  }

  const onChangeOrderCount = (e) => {
    e.persist();
    if (Number(e.target.value) >= 0) {
      const updateMenus = [...menus]
      const index = e.target.name
      const updateOrderCount = { ...menus[index], orderCount: Number(e.target.value) }
      updateMenus.splice(index, 1, updateOrderCount)
      setMenus(updateMenus)
    }
  }

  const changeType = async (store) => {
    if (store === null) {
      setCurrentStore({})
      setMenus([])
      setType('list')
      setTitle('参加店舗一覧')
      window.scrollTo(0, 0);
    } else {
      const res = await API.graphql({ query: queries.getStore, variables: { id: store.id } })
      const datel = new Date()
      const datev = new Date(res.data.getStore.ventilationAt.substr(0, 19) + "+00:00")
      const diffMinutes = Math.floor((datel.getTime() - datev.getTime()) / (1000 * 60))

      let image = 'https://images.suku-yell.com/'
      if (store.crowded === '混雑なし') image += 'density1.png';
      if (store.crowded === 'やや混雑') image += 'density2.png';
      if (store.crowded === '混雑') image += 'density3.png';
      const _currentStore = { ...res.data.getStore, diffMinutes: diffMinutes, crowdedImg: image }

      const _orderMenus = res.data.getStore.menus.items
        .sort((a, b) => a.createdAt < b.createdAt ? -1 : a.createdAt > b.createdAt ? 1 : 0)
        .map(menu => { return { ...menu, orderCount: 0 } })

      setCurrentStore(_currentStore)
      setMenus(_orderMenus)
      setType('item')
      setTitle('店舗詳細')
      window.scrollTo(0, 0);
    }
  }

  const updateCrowded = async (status) => {
    const res = await API.graphql({
      query: mutations.updateStore, variables: {
        input: {
          id: currentStore.id,
          crowded: status,
        }
      }
    })
    const datel = new Date()
    const datev = new Date(res.data.updateStore.ventilationAt.substr(0, 19) + "+00:00")
    const diffMinutes = Math.floor((datel.getTime() - datev.getTime()) / (1000 * 60))
    let image = 'https://images.suku-yell.com/'
    if (res.data.updateStore.crowded === '混雑なし') image += 'density1.png';
    if (res.data.updateStore.crowded === 'やや混雑') image += 'density2.png';
    if (res.data.updateStore.crowded === '混雑') image += 'density3.png';
    const _currentStore = { ...res.data.updateStore, diffMinutes: diffMinutes, crowdedImg: image }

    const _orderMenus = res.data.updateStore.menus.items
      .sort((a, b) => a.createdAt < b.createdAt ? -1 : a.createdAt > b.createdAt ? 1 : 0)
      .map(menu => { return { ...menu, orderCount: 0 } })

    setCurrentStore(_currentStore)
    setMenus(_orderMenus)
  }

  const updateVentilation = async () => {
    const res = await API.graphql({
      query: mutations.updateStore, variables: {
        input: {
          id: currentStore.id,
          ventilationAt: new Date(),
        }
      }
    })
    const datel = new Date()
    const datev = new Date(res.data.updateStore.ventilationAt.substr(0, 19) + "+00:00")
    const diffMinutes = Math.floor((datel.getTime() - datev.getTime()) / (1000 * 60))
    let image = 'https://images.suku-yell.com/'
    if (res.data.updateStore.crowded === '混雑なし') image += 'density1.png';
    if (res.data.updateStore.crowded === 'やや混雑') image += 'density2.png';
    if (res.data.updateStore.crowded === '混雑') image += 'density3.png';
    const _currentStore = { ...res.data.updateStore, diffMinutes: diffMinutes, crowdedImg: image }

    const _orderMenus = res.data.updateStore.menus.items
      .sort((a, b) => a.createdAt < b.createdAt ? -1 : a.createdAt > b.createdAt ? 1 : 0)
      .map(menu => { return { ...menu, orderCount: 0 } })

    setCurrentStore(_currentStore)
    setMenus(_orderMenus)
  }

  const addMenu = () => {
    API.graphql({
      query: mutations.createMenu, variables: {
        input:
        {
          title: '寄付',
          description: '',
          image: 'https://images.suku-yell.com/donate.png',
          ticket: 1,
          soldOut: false,
          delivery: true,
          takeout: true,
          eatin: true,
          deleted: false,
          storeID: currentStore.id,
        }

      }
    })
  }

  const updateSoldOut = (menu, index) => {
    API.graphql({
      query: mutations.updateMenu, variables: {
        input: {
          id: menu.id,
          soldOut: !menu.soldOut,
        }
      }
    })
      .then(res => {
        console.log('menu update: ', res)
        const updateMenus = [...menus]
        const updateItem = { ...menus[index], soldOut: res.data.updateMenu.soldOut }
        updateMenus.splice(index, 1, updateItem)
        setMenus(updateMenus)
      })
      .catch(error => { console.log('menu update: error: ', error) })
  }

  const updateDeleted = (menu, index) => {
    API.graphql({
      query: mutations.updateMenu, variables: {
        input: {
          id: menu.id,
          deleted: !menu.deleted,
        }
      }
    })
      .then(res => {
        console.log('menu update: ', res)
        const updateMenus = [...menus]
        const updateItem = { ...menus[index], deleted: res.data.updateMenu.deleted }
        updateMenus.splice(index, 1, updateItem)
        setMenus(updateMenus)
      })
      .catch(error => { console.log('menu update: error: ', error) })
  }


  const addCart = (menu) => {
    if (menu.orderCount > 0) {
      const updateMenus = [...menus]
      const index = updateMenus.findIndex(item => item.id === menu.id)
      const updateItem = { ...menus[index], message: 'カートに追加しました' }
      updateMenus.splice(index, 1, updateItem)
      setMenus(updateMenus)

      const cartMenus = [...props.state.cart.menus]
      cartMenus.push({ ...menu, store: currentStore })
      props.dispatch({ type: 'CART_UPDATE', key: 'menus', value: cartMenus })
      props.dispatch({ type: 'CART_UPDATE', key: 'isDelivery', value: false })
      props.dispatch({ type: 'CART_UPDATE', key: 'isTakeout', value: false })
      props.dispatch({ type: 'CART_UPDATE', key: 'isDelivery', value: false })
    }
  }


  return (
    <>
      <Header state={props.state} dispatch={props.dispatch} />
      <Typography className={classes.title} variant='h4'> {title} </Typography>
      <div className={classes.root}>
        <Container maxWidth="md">
          {/* 店舗一覧  */}
          {type === 'list' &&
            <Grid container spacing={0} >
              <Grid item xs={12}>
                <iframe className="gmap" src="https://www.google.com/maps/d/embed?mid=1cF0PlHbtJD7uQwwYNvdzbpxfoPbq0CT5" title="map" width="100%" height="400"></iframe>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='body2' align='center' color='error'>
                  {deliveryMessage.main}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {stores.map((store, index) => {
                  const isCrowdedInfo = props.state.dynamoUser.authority !== 'User' && props.state.isSignedIn
                  let image = 'https://images.suku-yell.com/'
                  if (store.crowded === '混雑なし') image += 'density1.png';
                  if (store.crowded === 'やや混雑') image += 'density2.png';
                  if (store.crowded === '混雑') image += 'density3.png';
                  return (
                    <List component="nav" key={store.id}>
                      <ListItem button onClick={() => changeType(store)}>
                        <ListItemIcon>
                          {isCrowdedInfo && <Avatar variant='square' alt="" src={image} />}
                        </ListItemIcon>
                        <ListItemText primary={Number(index + 1) + '. ' + store.name} />
                      </ListItem>
                    </List>
                  )
                })
                }
              </Grid>
            </Grid>
          }

          {/* 店舗詳細  */}
          {type === 'item' &&
            <Grid container spacing={1} >

              {props.state.dynamoUser.authority !== 'User' && props.state.isSignedIn &&
                <Grid item xs={12} >
                  <Button color="primary" variant="contained" onClick={() => updateCrowded('混雑なし')}>混雑なし</Button>
                  <Button color="primary" variant="contained" onClick={() => updateCrowded('やや混雑')}>やや混雑</Button>
                  <Button color="primary" variant="contained" onClick={() => updateCrowded('混雑')}>混雑</Button>
                  <Button color="primary" variant="contained" onClick={() => updateVentilation()}>換気</Button>
                </Grid>
              }
              {props.state.dynamoUser.authority === 'Admin' &&
                <Grid item xs={12} >
                  <Button color="primary" variant="contained" onClick={() => addMenu()}>寄付メニュー追加</Button>
                </Grid>
              }
              <Grid item xs={12} className={classes.borderOrange}>
                <Typography variant='h5' align='left'> {currentStore.name} </Typography>
              </Grid>
              <Grid item xs={12} >
                <img className={classes.storeImage} alt='' src={currentStore.image} />
                <Typography variant='body1' align="left" color='textPrimary'> {currentStore.description} </Typography>
                <Typography variant='body1' align="left" color='textSecondary'> イベント参加時間：{currentStore.businessHours} </Typography>
                <Typography variant='body1' align="left" color='textSecondary'> 電話番号：{currentStore.tel} </Typography>
                <Typography variant='body1' align="left" color='textSecondary'> 住所：{currentStore.address} </Typography>
                <Typography variant="body2" align="right" color='textPrimary' >
                  <RoomIcon /><Link href={currentStore.googleMap} target="_blank">マップ</Link>
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.borderOrange}>
                <Typography variant='h5' align='left'> 混雑状況・換気状況 </Typography>
              </Grid>
              <Grid item xs={6} >
                <img alt='' src={currentStore.crowdedImg} />
              </Grid>
              <Grid item xs={6} >
                <img alt='' src="https://images.suku-yell.com/airing1.png" />
              </Grid>
              <Grid item xs={12} >
                <Typography variant='body1' align="center" color='textSecondary' >換気状況：{currentStore.diffMinutes}分前</Typography>
              </Grid>
              <Grid item xs={12} className={classes.borderOrange}>
                <Typography variant='h5' align='left'> イベント限定メニュー </Typography>
              </Grid>
              <Grid item xs={12}>
                <Card variant='outlined' className={classes.root} elevation={9}>
                  <CardActionArea>
                    <CardContent>
                      <Typography gutterBottom variant="h6" color='textPrimary'> {'タグの説明'} </Typography>
                      <Chip label={"チケット枚数"} className={classes.chip} icon={<ConfirmationNumber />} variant="outlined" />
                      <Chip label={'デリバリー'} className={classes.chip} icon={<LocalShipping />} variant="outlined" />
                      <Chip label={'テイクアウト'} className={classes.chip} icon={<ShoppingBasket />} variant="outlined" />
                      <Chip label={'イートイン'} className={classes.chip} icon={<Restaurant />} variant="outlined" />
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
              {menus.map((menu, index) => {
                return (
                  <Grid item xs={12} md={6} key={index}>
                    <Card variant='outlined' className={classes.root} elevation={9} key={menu.id} id={menu.id}>
                      <CardActionArea>
                        <CardMedia className={classes.media} image={menu.image} title={menu.name} />
                        <CardContent>
                          <Typography gutterBottom variant="h5" color='textPrimary'> {menu.title} </Typography>
                          <Chip label={(menu.ticket) + "枚"} className={classes.chip} icon={<ConfirmationNumber />} variant="outlined" />
                          <Chip label={menu.delivery ? '可' : '不可'} className={classes.chip} icon={<LocalShipping />} variant="outlined" />
                          <Chip label={menu.takeout ? '可' : '不可'} className={classes.chip} icon={<ShoppingBasket />} variant="outlined" />
                          <Chip label={menu.eatin ? '可' : '不可'} className={classes.chip} icon={<Restaurant />} variant="outlined" />
                          <Typography variant="body2"> {menu.description} </Typography>
                          {menu.soldOut && !menu.deleted && <Typography align="center" variant="h4" color="error"> SOLD OUT </Typography>}
                          {menu.deleted && <Typography align="center" variant="h6" color="error"> {'イベント期間中のみ注文できます'} </Typography>}
                          <Typography variant="body1" color="error"> {menu.message} </Typography>
                        </CardContent>
                      </CardActionArea>
                      <CardActions style={{ justifyContent: 'center' }}>
                        {!menu.soldOut && !menu.deleted && props.state.isSignedIn &&
                          <TextField
                            size="small"
                            name={String(index)}
                            value={menu.orderCount}
                            label="数量"
                            type="number"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onChange={onChangeOrderCount}
                            variant="outlined"
                          />
                        }
                        {!menu.soldOut && !menu.deleted && props.state.isSignedIn &&
                          <Button className={classes.cartButton} onClick={() => addCart(menu)}>カートに入れる</Button>
                        }
                      </CardActions>
                      <CardActions>
                        {props.state.dynamoUser.authority !== 'User' && props.state.isSignedIn &&
                          <Button onClick={() => updateSoldOut(menu, index)} color="primary" variant="contained">スタッフ用：売り切れ更新</Button>
                        }
                        {props.state.dynamoUser.authority !== 'User' && props.state.isSignedIn &&
                          <Button onClick={() => updateDeleted(menu, index)} color="primary" variant="contained">スタッフ用：時間外更新</Button>
                        }
                      </CardActions>
                    </Card>
                  </Grid>
                )
              })
              }
              <Grid item xs={12}>
                <CustomButton themecolor={yellow} onClick={() => changeType(null)}>店舗一覧へ</CustomButton>
              </Grid>
            </Grid>
          }

        </Container>
      </div>
      <Footer logo simple dispatch={props.dispatch} />
    </>
  )
}