import React, { useCallback, useEffect, useState } from 'react';
import { API } from 'aws-amplify';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations'
import { makeStyles } from '@material-ui/core/styles';
import {
  Card, CardActions, CardContent,
  Container, Grid, Typography,
  IconButton, CardHeader, Avatar, Chip, Button, InputBase, Divider
} from '@material-ui/core';
import Header from '../utils/Header'
import fetchOrderMenus from '../utils/fetchOrderMenus';
import red from '@material-ui/core/colors/red';
import findUser from '../utils/findUser';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#fff',
  },
  paper: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  cardTrue: {
    // backgroundColor: green[100],
  },
  cardFalse: {
    backgroundColor: red[100],
  },
  avatar: {
    backgroundColor: red[400],
  },
  space: {
    paddingTop: 80
  }
}));


export default function Delivery(props) {
  const classes = useStyles();
  const title = 'デリバリー注文 一覧'
  /* ***********************************************
   * State
   * ************************************************/
  const [orderMenus, setOrderMenus] = useState([])
  const [deliveryOrders, setDeliveryOrders] = useState([])
  const [descriptions, setDescriptions] = useState({})

  /* ***********************************************
   *  Function 初期化
   * ************************************************/
  useEffect(useCallback(() => {
    (async () => {
      const res = await fetchOrderMenus(null, 500)
      res.sort((a, b) => a.createdAt < b.createdAt ? 1 : a.createdAt > b.createdAt ? -1 : 0)
      const deliveryOrderMenus = res.filter(m => m.order.isDelivery)
      setOrderMenus(deliveryOrderMenus)
      // 重複削除
      const deliveryOrderIDs = deliveryOrderMenus.map(m => m.order.id).filter((x, i, self) => self.indexOf(x) === i);
      const _orders = []
      for (const orderid of deliveryOrderIDs) {
        const menu = deliveryOrderMenus.find(m => m.order.id === orderid)
        if (!(menu === undefined)) {
          menu.order.hide = false
          _orders.push(menu.order)
        }
      }
      _orders.sort((a, b) => a.createdAt < b.createdAt ? 1 : a.createdAt > b.createdAt ? -1 : 0)
      setDeliveryOrders(_orders)
    })().catch(error => console.log('fetch error:', error))
  }, []), [])

  /* ***********************************************
   *  Function ステータス更新
   * ************************************************/
  const updateStatus = async (order, status, index) => {
    if (order.status === '注文' || order.status === '準備中') {
      for (let menu of orderMenus.filter(m => m.orderID === order.id)) {
        await API.graphql({
          query: mutations.updateOrderMenu,
          variables: { input: { id: menu.id, status: status } }
        })
      }
      const res = await API.graphql({
        query: mutations.updateOrder,
        variables: { input: { id: order.id, status: status } }
      })
      const _orders = [...deliveryOrders]
      _orders.splice(index, 1, res.data.updateOrder)
      setDeliveryOrders(_orders)
    }
  }

  /* ***********************************************
   *  Function メモ更新
   * ************************************************/
  const updateDescription = async (order, index) => {
    const res = await API.graphql({
      query: mutations.updateOrder,
      variables: { input: { id: order.id, description: descriptions[order.id] } }
    })
    const _orders = [...deliveryOrders]
    _orders.splice(index, 1, res.data.updateOrder)
    setDeliveryOrders(_orders)
  }

  /* ***********************************************
   *  Function フィルタ
   * ************************************************/
  // const statusFilter = (status) => {
  //   const deliveryOrderIDs = orderMenus.map(m => m.order.id).filter((x, i, self) => self.indexOf(x) === i);
  //   const _orders = []
  //   for (const orderid of deliveryOrderIDs) {
  //     const menu = orderMenus.find(m => m.order.id === orderid)
  //     if (!(menu === undefined)) {
  //       if (status === menu.order.status) {
  //         menu.order.hide = false
  //       } else {
  //         menu.order.hide = true
  //       }
  //       _orders.push(menu.order)
  //     }
  //   }
  //   _orders.sort((a, b) => a.createdAt < b.createdAt ? 1 : a.createdAt > b.createdAt ? -1 : 0)
  //   setDeliveryOrders(_orders)
  // }

  /* ***********************************************
   *  Function 日付フォーマット変換
   * ************************************************/
  const formatCreatedAt = (createdAt) => {
    const createdAtString = createdAt.substr(0, 19) + "+00:00"
    const at = new Date(createdAtString)
    return at.toLocaleString()
  }

  /* ***********************************************
   *  Function チケット支払い
   * ************************************************/
  const paymentTicket = async (order, index) => {

    const payCheck = await API.graphql({ query: queries.getOrder, variables: { id: order.id } })
    if (payCheck.data.getOrder?.consumed) return null

    const user = await findUser({ cognito: { eq: order.appuser } })
    if (user == null) return null

    const updateTicketPos = Number(user.ticketPossession) + Number(order.ticket)

    try {
      await API.graphql({
        query: mutations.updateUser,
        variables: { input: { id: user.id, ticketPossession: updateTicketPos } }
      })

      for (let menu of orderMenus.filter(m => m.orderID === order.id)) {
        await API.graphql({
          query: mutations.updateOrderMenu,
          variables: { input: { id: menu.id, status: '完了' } }
        })
      }
      const res = await API.graphql({
        query: mutations.updateOrder,
        variables: { input: { id: order.id, consumed: true, status: '完了' } }
      })
      const _orders = [...deliveryOrders]
      _orders.splice(index, 1, res.data.updateOrder)
      setDeliveryOrders(_orders)

    } catch (error) {
      alert('payment error');
      console.log(error)
    }

  }

  /* ***********************************************
   *  Render スタッフ用 Main
   * ************************************************/
  return (
    <>
      <Header state={props.state} dispatch={props.dispatch} />
      <Typography variant="h4" component="h4" className={classes.space}> {title} </Typography>
      <Container maxWidth="lg">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            {'件数(取引完了数/注文総数)：'}
            {deliveryOrders.filter(o => o.consumed).reduce((p, c) => Number(p) + 1, 0)}
            {'/'}
            {deliveryOrders.reduce((p, c) => Number(p) + 1, 0)}
          </Grid>
          <Grid item xs={12}>
            {'デリバリー数(取引完了数/注文総数)：'}
            {deliveryOrders.filter(o => o.consumed && o.status !== '取消').reduce((p, c) => Number(p) + 1, 0)}
            {'/'}
            {deliveryOrders.filter(o => o.status !== '取消').reduce((p, c) => Number(p) + 1, 0)}
          </Grid>
          <Grid item xs={12}>
            {/* <Button variant="contained" color="primary" onClick={() => statusFilter('注文')}>{'注文'}</Button>
            <Button variant="contained" color="primary" onClick={() => statusFilter('準備中')}>{'準備中'}</Button>
            <Button variant="contained" color="primary" onClick={() => statusFilter('配達中')}>{'配達中'}</Button>
            <Button variant="contained" color="primary" onClick={() => statusFilter('受渡完了')}>{'受渡完了'}</Button> */}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" component="h5"> {'デリバリー注文 一覧'} </Typography>
          </Grid>
          {deliveryOrders.map((order, index) => {
            if (order.hide) return null
            return (
              <Grid item xs={12} md={6} key={index}>
                <Card className={order.consumed ? classes.cardTrue : classes.cardFalse}>
                  <CardHeader
                    avatar={
                      <Avatar aria-label="recipe" className={classes.avatar}>
                        {order.consumed ? '済' : '未'}
                      </Avatar>
                    }
                    action={
                      <IconButton aria-label="settings">
                        <Chip label={order.status} />
                      </IconButton>
                    }
                    title={order.appuser}
                    subheader={formatCreatedAt(order.createdAt)}
                  />
                  <CardContent>
                    <Typography key={index} variant="body1" align="center" color='textPrimary'>
                      {order.address}
                    </Typography>
                    <Typography key={index} variant="body1" align="center" color='textPrimary'>
                      {'tel:'}{order.phone}
                    </Typography>
                    <Divider />
                    {orderMenus.filter(m => m.orderID === order.id).map((menu, index) => {
                      return (
                        <Typography key={index} variant="body1" align="left">
                          {'<'}{menu.store}{'>'}{menu.menu}
                          <Typography children variant="body2" component='span'>
                            {' * '}{menu.orderCount}{'個'}
                          </Typography>
                        </Typography>
                      )
                    })}
                    <Divider />
                    <Typography key={index} variant="body1" align="right" color='textSecondary'>
                      {order.key}
                    </Typography>
                    <Typography key={index} variant="body1" align="center" color='error'>
                      {order.description}
                    </Typography>
                  </CardContent>
                  <CardActions >
                    <IconButton >
                      <Button variant="contained" onClick={() => updateStatus(order, '準備中', index)}>{'準備中'}</Button>
                    </IconButton>
                    <IconButton >
                      <Button variant="contained" onClick={() => updateStatus(order, '配達中', index)}>{'配達中'}</Button>
                    </IconButton>
                    {props.state.dynamoUser.authority === 'Admin' &&
                      <IconButton >
                        <Button variant="contained" color="secondary" onClick={() => paymentTicket(order, index)}>{'強制受領'}</Button>
                      </IconButton>
                    }
                  </CardActions>
                  <CardActions >
                    <InputBase
                      className={classes.input}
                      name={order.id}
                      placeholder="メモ更新"
                      inputProps={{ 'aria-label': 'change description' }}
                      type='text'
                      onChange={(e) => setDescriptions({ ...descriptions, [order.id]: e.target.value })}
                    />
                    <Button variant="contained" onClick={() => updateDescription(order, index)}>{'更新'}</Button>
                  </CardActions>
                </Card>

              </Grid>
            )

          })}
        </Grid>
      </Container>
    </>
  )
}
