import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Header from '../utils/Header'
import Footer from '../utils/Footer'
import { Grid, Tab, Tabs, withStyles } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import Top from '../utils/Top';
import Report from '../utils/Report';
import TopImage from '../utils/TopImage';

const useStyles = makeStyles((theme) => ({
  main: {
    backgroundColor: '#ff8a00',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
    },
    [theme.breakpoints.up('md')]: {
      textAlign: 'center',
    },
    [theme.breakpoints.up('lg')]: {
      textAlign: 'center',
    },
  },
  main2: {
    backgroundColor: '#fff',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
    },
    [theme.breakpoints.up('md')]: {
      textAlign: 'center',
    },
    [theme.breakpoints.up('lg')]: {
      textAlign: 'center',
    },
  },
  paperContainer: {
    width: '100%',
    color: '#ff8a00',
  },
  indicator: {
    backgroundColor: '#FF8A00',
  },
  customTwo: {
    padding: '0rem',
    color: '#484848',
    backgroundColor: '#BBBBBB',
    fontFamily: 'Open Sans',
    fontSize: '1rem',
    borderRadius: '20px 20px 0 0',
    marginTop: '1rem',
  },
  tubCustom: {
    borderRadius: '20px 20px 0 0',
  },



}));

const TabStyle = withStyles((theme) => ({
  root: {
    margin: '0',
    padding: '1rem 0',
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: '1.2rem',
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      'Roboto',
    ].join(','),
    // '&:hover': {
    //   backgroundColor: '#004C9B',
    //   color: 'white',
    //   opacity: 1,
    // },
    '&$selected': {
      backgroundColor: '#FF8A00',
      color: 'white',
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
  tab: {
    padding: '0.5rem',
    fontFamily: 'Open Sans',
    fontSize: '16px',
    backgroundColor: 'grey',
    color: 'black',
    // '&:hover': {
    //   backgroundColor: 'red',
    //   color: 'white',
    //   opacity: 1,
    // },
  },
  selected: {},
}))((props) => <Tab {...props} />)

export default function Home(props) {
  const classes = useStyles();
  const { pathname } = useLocation();

  /* ***********************************************
   *  Function 初期化
   * ************************************************/
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  /* ***********************************************
   *  Function 
   * ************************************************/
  const handleChange = (event, newValue) => {
    props.dispatch({ type: "UPDATE", key: "tabNumber", value: newValue });
  };

  return (
    <>
      <Grid>
        <Header state={props.state} dispatch={props.dispatch}></Header>
        {/* <img className={classes.paperContainer} alt=''
          src="https://images.suku-yell.com/fv_sp.png" >
        </img> */}
        <TopImage />
      </Grid>
      <div className={classes.root} >
        <Tabs value={props.state.tabNumber}
          onChange={handleChange}
          indicatorColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
          classes={{ indicator: classes.indicator, }}
          className={classes.customTwo}
        >
          <TabStyle label="トップページ" id="toppage" className={classes.tubCustom} />
          <TabStyle label="イベントレポート" id="eventreport" className={classes.tubCustom} />
        </Tabs>

        <div>
          {props.state.tabNumber === 0 && <Top state={props.state} dispatch={props.dispatch} />}
          {props.state.tabNumber === 1 && <Report state={props.state} dispatch={props.dispatch} />}
        </div>

      </div>
      <Footer state={props.state} dispatch={props.dispatch}></Footer>

    </>
  )
}

