import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Typography from '@material-ui/core/Typography';
import DrawerManu from './DrawerMenu';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    opacity: 0.8,
    color: '#ff8a00',
    position: "fixed",
    width: "100vw",
    zIndex: 1000,
    height: 60
  },
  bgc: {
    backgroundColor: '#ff8a00',
  },
  title: {
    flexGrow: 1,
  },
  text: {
    fontSize: 5,
    fontWeight: "bold",
    color: '#4C1D00',
    margin: 0,
    padding: 0
  },
  img: {
    width: 112,
    height: 53,
    display: "block",
    textAlign: "left"
  }
}));

export default function Header(props) {
  const classes = useStyles();
  const history = useHistory();

  /* ***********************************************
   *  State
   * ************************************************/
  const [toggle, setToggle] = useState(false);

  /* ***********************************************
   *  Function
   * ************************************************/
  const handleLink = (path) => history.push(path);
  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setToggle(open);
  };

  /* ***********************************************
   *  Render
   * ************************************************/
  return (
    <>
      <div className={classes.root}>
        <Toolbar component='div' className={classes.toolbar}>
          <Typography variant="h4" className={classes.title}>
            {props.state.title}
          </Typography>
          <Grid item xs={12} >
            <img className={classes.img} alt=''
              src="https://images.suku-yell.com/logo_footer.png" >
            </img>
          </Grid>
          {/* <IconButton edge="start" color="inherit" aria-label="menu" onClick={() => handleLink('/howto')} >
            <Grid>
              <HelpOutlineIcon />
              <p className={classes.text}>使い方</p>
            </Grid>
          </IconButton>
          <IconButton edge="start" color="inherit" aria-label="menu" onClick={() => handleLink('/cart')}>

            <Grid>
              <ShoppingCartIcon />
              <p className={classes.text}>カート</p>
            </Grid>
          </IconButton> */}
          <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
            <MenuIcon fontSize='large' />
          </IconButton>
        </Toolbar>
      </div>

      <Drawer anchor='right' open={toggle} onClose={toggleDrawer(false)} >
        <DrawerManu state={props.state} dispatch={props.dispatch} toggleDrawer={toggleDrawer} className={classes.bgc}></DrawerManu>
      </Drawer>
    </>
  )
}